import isEqual from 'lodash/isEqual';
import { getPathFromState } from './utils';
import { getGridStorageData } from '../../components/grid/utils';

export class PageUtils {
    static update = <P extends object>(
        prevParams: P,
        params: P,
        loadData: boolean,
        fromFilters: boolean,
        setNewParamsFunction,
        loadDataFunction,
        filters,
        cb
    ): void => {
        if (loadData || !isEqual(prevParams, params)) {
            if (loadData) {
                loadDataFunction();
            } else {
                if (!fromFilters) {
                    PageUtils.setPageParamsOrLoadData(
                        params,
                        filters,
                        () => {
                            setNewParamsFunction('');
                        },
                        loadDataFunction
                    );
                    return;
                } else {
                    // Данные поменялись через фильтры
                    loadDataFunction();
                }
            }
            cb();
        }
    };

    // Нужно узнать что это ссылка или нет
    static setPageParamsOrLoadData = <P extends object>(params: P , filters: string[], setNewParamsFunction: () => void, loadDataFunction: () => void, newParams?: P|undefined) => {
        // Проверяем, нужно ли устанавливать новые параметры
        let setParams =
            filters.every(
                (filter) =>
                    params[filter] === undefined ||
                    (Array.isArray(params[filter]) && params[filter][0] === undefined && params[filter][1] === undefined)
            ) &&
            (params['page'] === 1 || params['page'] === '1' || params['page'] === undefined);

        if(setParams && newParams && Object.keys(newParams).filter((key)=> key!=='tab' && params[key] !== undefined).length === 0){
            loadDataFunction();
            return;
        }

        if (setParams) {
            setNewParamsFunction();
        } else {
            loadDataFunction();
        }
    };

    static getPathForPushOnPageTab = <MainPath extends string, Tab extends string>(
        urlMainPath: MainPath,
        tabKey: Tab,
        params?: { [p: string]: any },
        options?: {
            withCachedData?: boolean;
        }
    ) => {
        const storageData = getGridStorageData(tabKey);

        const cachedData =
            options == null || options.withCachedData
                ? {
                      ...storageData.filters,
                      ...storageData.params,
                  }
                : {};

        return getPathFromState(`${urlMainPath}/${tabKey}/`, '', {
            ...cachedData,
            ...params,
        });
    };
}
