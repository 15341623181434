import React from 'react';
import { Alert, Button, Icon, Switch, Tabs, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { push, replace } from 'connected-react-router';
import { loadEntity, reset, setStatusAndArchive } from './reducers/subrent.reducer';
import { IRootState } from '../../../../shared/reducers';
import { RoundButton, Spin } from '../../../../components';
import {
    IconArchive,
    IconBorderStyleSolid,
    IconCalculatorSolid,
    IconControlPointDuplicateBlack,
    IconEdit,
    IconEraser,
    IconFlagCheckered,
    IconLevelUp,
    IconReply,
    IconRocket,
    IconSyncSolid,
} from '../../../../components/icons';
import { SubrentShippingCreateModal } from './subrentShippingCreateModal';
import { SubrentShippingPageDescriptionTabPane } from './subrentShippingPageDescriptionTabPane';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { getPathFromState, getShiftCountFromDates, getStateFromPath, isStringInEnum, joinClasses } from '../../../../shared/util/utils';
import { BasePage, IBasePageProps } from '../../../../components/page/BasePage';
import { IPageHeaderProps } from '../../../../components/pageHeader/pageHeader';
import { OperationsList } from '../../operations/operationsList';
import { ElementsListWrapper } from '../../elements/elementsListWrapper';
import {
    fullResetOperation,
    removeConcurrentOperation,
    resetOperationForm,
    setRenterAndProject,
    startNewOperation,
} from '../../operationForm/reducers/operationForm.reducer';
import { logEvent } from '../../../../analytics/analytics';
import {
    OperationTypeCodeEnum,
    RentActivityFrameTypeCodeEnum,
    RenterStateCodeEnum,
    RentStateCodeEnum,
    SubrentStateCodeEnum,
    SubrentTransitionCodeEnum,
} from '../../../../server/api';
import { LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import AdditionalEntityActions from '../../../../components/additionalEntityActions/additionalEntityActions';
import { createNewDraftOperation } from '../../elements/create-operation-util';
import { showNotification } from '../../../../components/notification/showNotification';
import { linkToCalendar } from '../../../../shared/util/createLinkPath';
import { AdditionalEntityActionsButton } from '../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { serverApi } from '../../../../server';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { Moment } from 'moment/moment';
import moment from 'moment';
import { getCurrentServerTime } from '../../../../shared/reducers/system.reducer';
import { showActualizeDatesConfirm } from '../../../../shared/util/showActualizeDatesConfirm';
import { canAddNewProductsToOperation } from '../../operationForm/utils/utils';
import { OperationTypeSelectButton } from '../../projects/production/page/OperationTypeSelectButton';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { PaymentCreateModal } from '../../payments/components/modal/paymentCreateModal';
import { listConcurrentOperationsAndShowConfirm } from '../../operationForm/listConcurrentOperationsAndShowConfirm';
import { createSubrentShippingSchemeModalFilters, editSubrentShippingSchemeModalFilters } from './subrentShippingCreateModalData';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../../shared/reducers/userSettings/userSettings.reducer';
import {
    statisticsModuleEnabledSelector,
    subrentModuleEnabledSelector,
} from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { ReportReferenceTypeEnum } from '../../../../components/pageHeader/ReportSelectDropdown';
import { LinkToCalendar } from '../../../../components/link/toCalendar/LinkToCalendar';
import { SubrentShippingPageTabsEnum } from '../../../../shared/constants/tabEnums';
import { SubrentPageHeaderNavigationBlock } from './components/HeaderNavigationBlock/SubrentPageHeaderNavigationBlock';
import { EditOperationHeaderResetButton } from '../../../../components/buttons/editOperationResetButton/editOperationHeaderResetButton';
import { ElementsListRef } from '../../elements/elements-list';

interface IState {
    operationIsLoading: boolean;
}

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

const defaultTab = SubrentShippingPageTabsEnum.DESCRIPTION;

class _SubrentShippingPage extends BasePage<IProps, IState> {
    private selectedTab: SubrentShippingPageTabsEnum = SubrentShippingPageTabsEnum.ELEMENTS;
    private tabs = {};
    private elementsListRef: ElementsListRef | undefined;

    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();

        this.state = { operationIsLoading: false };
    }

    logViewEvent = () => {
        logEvent({ type: 'view project', data: { 'entity id': this.props.entityId, tab: this.selectedTab } });
    };

    async componentDidMount() {
        super.componentDidMount();

        await this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
    }

    async componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.currentTab) saveCurrentTabToStore('subrentShipping', this.currentTab);

        if (prevProps.entityId !== this.props.entityId) {
            this.props.reset();
            await this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
    }

    onEditButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/subrent/shippings/${this.props.entityId}/edit?tab=description`);
    };

    onRecalculateClick = async () => {
        const actionAuthorized = await listConcurrentOperationsAndShowConfirm(
            this.props.intl,
            this.props.businessAccountId,
            this.props.entityId,
            RentActivityFrameTypeCodeEnum.SUBRENT,
            false
        );
        if (!actionAuthorized) return;

        let _checked = window.localStorage.getItem('updateShiftsWhenRecalculate') === '1';
        let yes = await showConfirm(
            this.props.intl,
            'Пересчитать отработанные в аренде смены?',
            <>
                Пересчет смен может привести к разделению обязательств данной поставки на более мелкие составные части для верного
                представления отработанных смен
                <div style={{ marginTop: 34 }}>
                    <span>
                        <Icon
                            style={{
                                marginRight: 10,
                            }}
                            className={joinClasses(
                                'rr-RecalculateShiftsCountButton__icon',
                                'rr-RecalculateShiftsCountButton__icon__without-hover'
                            )}
                            component={IconSyncSolid}
                        />
                        Установить число смен по отработанным
                        <Switch
                            style={{ marginLeft: 12, verticalAlign: 'middle' }}
                            defaultChecked={_checked}
                            onChange={(checked: boolean) => {
                                _checked = checked;
                                window.localStorage.setItem('updateShiftsWhenRecalculate', checked ? '1' : '0');
                            }}
                        />
                    </span>
                </div>
            </>
        );
        if (yes) {
            try {
                await this.setState({ operationIsLoading: true });
                let res = await serverApi.calculateWorkedShifts(this.props.businessAccountId, this.props.entityId, _checked);
                if (res.status === 200) {
                    showNotification('success', 'Отработанные в аренде смены успешно пересчитаны');
                    if (this.elementsListRef && this.elementsListRef.loadDataAndSetFilterValues) {
                        this.elementsListRef.loadDataAndSetFilterValues();
                    }
                    this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
                }
            } catch (err) {
                showNotification('error', 'Пересчет отработанных смен произвести не удалось');
            }
            await this.setState({ operationIsLoading: false });
        }
    };

    onArchiveButtonClick = () => {
        let { entity } = this.props;
        if (entity) {
            if (!entity.archive) {
                //TODO Нормальный текст предупреждения придумать
                showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__SHIPPINGS__MODAL__QUESTIONS__SEND_TO_ARCHIVE),
                    undefined,
                    () => {
                        if (entity)
                            this.props.setStatusAndArchive(
                                this.props.intl,
                                this.props.businessAccountId,
                                this.props.entityId,
                                entity.businessVersion,
                                undefined,
                                true
                            );
                    }
                );
            } else {
                this.props.setStatusAndArchive(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entityId,
                    entity.businessVersion,
                    undefined,
                    false
                );
            }
        }
    };

    changeStatus = async (transitionCode: SubrentTransitionCodeEnum) => {
        let question: string | undefined;

        if (transitionCode === SubrentTransitionCodeEnum.CANCEL) {
            question = 'Отменить поставку?';
        }

        let yes = true;
        if (question) yes = await showConfirm(this.props.intl, question);

        if (yes) {
            this.props.setStatusAndArchive(
                this.props.intl,
                this.props.businessAccountId,
                this.props.entityId,
                this.props.entity?.businessVersion,
                transitionCode
            );
        }
    };

    onChangeStatusClick = async () => {
        let { entity } = this.props,
            transitionCode,
            title,
            businessVersion;

        if (entity) {
            businessVersion = entity.businessVersion;

            if (entity.stateCode === SubrentStateCodeEnum.DRAFT) {
                transitionCode = 'ACTIVATE';
                title = localizeIntl(this.props.intl, LocalizationEnum.PAGE__SHIPPINGS__MODAL__QUESTIONS__START);
            } else if (entity.stateCode === SubrentStateCodeEnum.INPROGRESS) {
                transitionCode = 'FINISH';
                title = localizeIntl(this.props.intl, LocalizationEnum.PAGE__SHIPPINGS__MODAL__QUESTIONS__FINISH);
            } else if (entity.stateCode === SubrentStateCodeEnum.FINISHED) {
                transitionCode = 'ACTIVATE';
                title = localizeIntl(this.props.intl, LocalizationEnum.PAGE__SHIPPINGS__MODAL__QUESTIONS__RESUME);
            }

            let yes = true;
            if (transitionCode !== 'ACTIVATE') yes = await showConfirm(this.props.intl, title);

            if (yes) {
                this.props.setStatusAndArchive(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entityId,
                    businessVersion,
                    transitionCode
                );
            }
        }
    };

    onCopyButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/subrent/shippings/${this.props.entityId}/copy?tab=description`);
    };

    createNewOperation = async (code: OperationTypeCodeEnum, correctionCode?: RentStateCodeEnum | undefined) => {
        if (this.props.entity) {
            let canCreate = true;
            if (this.props.operationFormMnemoKey) {
                canCreate = await showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
                );
            }

            if (canCreate) {
                try {
                    canCreate = await listConcurrentOperationsAndShowConfirm(
                        this.props.intl,
                        this.props.businessAccountId,
                        this.props.entity.id,
                        RentActivityFrameTypeCodeEnum.SUBRENT
                    );
                } catch (e) {
                    return;
                }
            }

            if (canCreate) {
                let defaultStartDate: Moment = moment(getCurrentServerTime()) /*.seconds(0)*/
                    .milliseconds(0);
                let setActualDateRes: { setActualDate: boolean; recalculateShiftCount: boolean } = {
                    setActualDate: false,
                    recalculateShiftCount: false,
                };
                let startD: Moment | undefined;
                let endD: Moment | undefined;
                let discount = this.props.entity.supplierDefaultDiscount;
                let shiftCount: number | undefined = undefined;

                if (this.props.entity.startDate && this.props.entity.targetFinishDate) {
                    startD = moment(this.props.entity.startDate).seconds(0).milliseconds(0);
                    endD = moment(this.props.entity.targetFinishDate).seconds(0).milliseconds(0);
                } else {
                    startD = moment(defaultStartDate);
                    endD = moment(defaultStartDate).add(7, 'd');
                }

                if (code === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT) {
                    setActualDateRes = await showActualizeDatesConfirm(
                        this.props.intl,
                        'Установить текущую дату выдачи?',
                        'subrentAcceptShipment'
                    );
                    if (setActualDateRes.setActualDate) {
                        startD = moment(defaultStartDate);
                        shiftCount = setActualDateRes.recalculateShiftCount
                            ? getShiftCountFromDates(
                                  undefined,
                                  startD,
                                  endD,
                                  this.props.defaultShiftLengthInMinutes,
                                  this.props.shiftCountRoundingType
                              )
                            : shiftCount;
                    }
                } else if (code === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER) {
                    setActualDateRes = await showActualizeDatesConfirm(
                        this.props.intl,
                        'Установить текущую дату возврата?',
                        'subrentReturnToShipper'
                    );
                    if (setActualDateRes.setActualDate) {
                        endD = moment(defaultStartDate);
                        shiftCount = setActualDateRes.recalculateShiftCount
                            ? getShiftCountFromDates(
                                  undefined,
                                  startD,
                                  endD,
                                  this.props.defaultShiftLengthInMinutes,
                                  this.props.shiftCountRoundingType
                              )
                            : shiftCount;
                    }
                }

                await this.props.fullResetOperation();

                this.props.startNewOperation(
                    undefined,
                    undefined,
                    undefined,
                    false,
                    this.props.entity.supplierId,
                    this.props.entity.supplierShortName,
                    this.props.entity.id,
                    this.props.entity.shortName,
                    undefined,
                    code,
                    [],
                    'mnemoKey',
                    !true,
                    discount,
                    correctionCode,
                    undefined,
                    undefined,
                    [],
                    {},
                    setActualDateRes.setActualDate,
                    setActualDateRes.recalculateShiftCount,
                    shiftCount,
                    startD?.toDate(),
                    endD?.toDate(),
                    defaultStartDate.toDate()
                );
                if (canAddNewProductsToOperation(code)) this.props.push(`/${this.props.businessAccountId}/operation?tab=products`);
                else this.props.push(`/${this.props.businessAccountId}/operation?tab=equipment`); // ?
            }
        }
    };

    onCreateDraftButtonClick = async () => {
        let canCreate = true;

        if (this.props.operationFormMnemoKey) {
            canCreate = await showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
            );
        }
        if (canCreate && this.props.entity) {
            this.setState({ operationIsLoading: true });
            try {
                await createNewDraftOperation(
                    OperationTypeCodeEnum.SUBRENTDRAFT,
                    false,
                    this.props.startNewOperation,
                    this.props.businessAccountId,
                    this.props.entity.id,
                    this.props.entity.shortName,
                    this.props.entity.supplierId,
                    this.props.entity.supplierShortName
                );
                this.props.push(`/${this.props.businessAccountId}/operation?tab=${'products'}`);
                this.setState({ operationIsLoading: false });
            } catch (e) {
                showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
                this.setState({ operationIsLoading: false });
            }
        } else {
            this.setState({ operationIsLoading: false });
        }
    };

    onPay = () => {
        this.props.push(`/${this.props.businessAccountId}/subrent/shippings/${this.props.entityId}/pay/?tab=description`);
    };

    onTabsChange = (key: string, replace: boolean = false) => {
        const urlMethod = replace ? this.props.replace : this.props.push;

        this.tabs[this.selectedTab] = this.props.location.search;
        this.selectedTab = key as SubrentShippingPageTabsEnum;
        let searchString =
            key === SubrentShippingPageTabsEnum.ELEMENTS ||
            key === SubrentShippingPageTabsEnum.OPERATIONS ||
            key === SubrentShippingPageTabsEnum.STATISTICS
                ? this.tabs[this.selectedTab] || ''
                : '';

        saveCurrentTabToStore('subrentShipping', key);

        urlMethod(getPathFromState(this.props.location.pathname, searchString, { tab: key }));
        this.logViewEvent();
    };

    getPageHeaderProps(): IPageHeaderProps {
        let { entity, entityId, subrentModuleEnabled, statisticsModuleEnabled, tab } = this.props;

        return {
            title: this.props.intl.formatMessage({ id: LocalizationEnum.ASPECT__MAIN_MENU__SUBRENT__RENT }),
            archive: entity && entity.archive ? true : false,
            name: entity && entity.fullName ? entity.fullName : entity?.shortName,
            shortName: entity && entity.fullName ? false : true,
            businessAccountId: this.props.businessAccountId,
            rightBlock: (
                <>
                    {subrentModuleEnabled && entity ? (
                        <LinkToCalendar
                            calendarPath={linkToCalendar({
                                group: 'project',
                                capture: 'all',
                                renterId: entity.supplierId,
                                subrentId: entity.id,
                            })}
                        />
                    ) : undefined}
                    {tab === SubrentShippingPageTabsEnum.ELEMENTS && <EditOperationHeaderResetButton entityId={entity?.id} />}
                </>
            ),
            parentPagePath: subrentModuleEnabled && entity ? `/${this.props.businessAccountId}/subrent/shippings` : undefined,
            reportReferenceType: statisticsModuleEnabled ? ReportReferenceTypeEnum.SUBRENT : undefined,
            reportReferenceEntityId: statisticsModuleEnabled ? entityId : undefined,
            NavigationBlock: <SubrentPageHeaderNavigationBlock subrent={entity || undefined} />,
        };
    }

    getCurrentUrl(tab: SubrentShippingPageTabsEnum) {
        return getPathFromState(this.props.location.pathname, '', {
            tab,
        });
    }

    get currentTab() {
        return new URLSearchParams(this.props.location.search).get('tab') || undefined;
    }

    renderContent() {
        const { entity, loading, updating, canViewFinancialData, canUpdateFinancialData, subrentModuleEnabled } = this.props;
        const { currentTab } = this;

        if (currentTab == null) {
            return <Redirect to={this.getCurrentUrl(isStringInEnum(this.props.tabFromStore, SubrentShippingPageTabsEnum, defaultTab))} />;
        }

        const isSupplierInactive =
            entity?.supplierStateCode === RenterStateCodeEnum.NEW || entity?.supplierStateCode === RenterStateCodeEnum.BLOCKED;

        const isActivateActionAvailable =
            entity?.availableTransitionCodes?.includes(SubrentTransitionCodeEnum.ACTIVATE) || !isSupplierInactive;

        return (
            <>
                <Spin spinning={loading || updating || this.state.operationIsLoading}>
                    <div className={'rr-page-renter'} style={{ minHeight: 400 }}>
                        {this.props.loadingError ? (
                            <Alert
                                message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                                description={
                                    <>
                                        <div>
                                            `<strong>{this.props.loadingError.title}</strong>
                                        </div>
                                        <div>{this.props.loadingError.message}</div>
                                    </>
                                }
                                type="error"
                                showIcon
                                closable
                                style={{ marginBottom: 30 }}
                            />
                        ) : null}

                        {entity ? (
                            <>
                                <div className="card-container">
                                    <Tabs
                                        defaultActiveKey={'description'}
                                        activeKey={currentTab}
                                        onChange={this.onTabsChange}
                                        className={'rr-tabs'}
                                        type="line"
                                        animated={false}
                                        tabBarExtraContent={
                                            subrentModuleEnabled ? (
                                                <>
                                                    {entity.stateCode !== SubrentStateCodeEnum.DRAFT ? (
                                                        <OperationTypeSelectButton
                                                            onChangeOperationType={async (
                                                                code: OperationTypeCodeEnum,
                                                                correctionCode?: RentStateCodeEnum | undefined
                                                            ) => {
                                                                this.createNewOperation(code, correctionCode);
                                                            }}
                                                            projectId={entity.id}
                                                            activityFrameType={RentActivityFrameTypeCodeEnum.SUBRENT}
                                                        />
                                                    ) : (
                                                        <RoundButton
                                                            className={'ant-btn-page-actions'}
                                                            colorScheme={'DRAFT'}
                                                            onClick={this.onCreateDraftButtonClick}
                                                        >
                                                            <Icon component={IconBorderStyleSolid} />
                                                            Рассчитать черновик
                                                        </RoundButton>
                                                    )}
                                                    {
                                                        <RoundButton colorScheme={'default'} onClick={this.onEditButtonClick}>
                                                            <Icon component={IconEdit} />
                                                            <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}</span>
                                                        </RoundButton>
                                                    }

                                                    {entity.stateCode === SubrentStateCodeEnum.DRAFT ? (
                                                        <Tooltip
                                                            title={
                                                                !isActivateActionAvailable
                                                                    ? 'Поставщик в неактивном статусе, действие недоступно'
                                                                    : undefined
                                                            }
                                                        >
                                                            <div>
                                                                <RoundButton
                                                                    colorScheme={'inRent'}
                                                                    onClick={this.onChangeStatusClick}
                                                                    disabled={!isActivateActionAvailable}
                                                                >
                                                                    <Icon component={IconRocket} />
                                                                    {localize(LocalizationEnum.PAGE__SHIPPINGS__ACTIONS__START, 'span')}
                                                                </RoundButton>
                                                            </div>
                                                        </Tooltip>
                                                    ) : null}

                                                    {entity.stateCode === SubrentStateCodeEnum.FINISHED ? (
                                                        <Tooltip
                                                            title={
                                                                !isActivateActionAvailable
                                                                    ? 'Поставщик в неактивном статусе, действие недоступно'
                                                                    : undefined
                                                            }
                                                        >
                                                            <div>
                                                                <RoundButton
                                                                    colorScheme={'inRent'}
                                                                    onClick={this.onChangeStatusClick}
                                                                    disabled={!isActivateActionAvailable}
                                                                >
                                                                    <Icon component={IconReply} />
                                                                    {localize(LocalizationEnum.PAGE__SHIPPINGS__ACTIONS__RESUME, 'span')}
                                                                </RoundButton>
                                                            </div>
                                                        </Tooltip>
                                                    ) : null}

                                                    <span style={{ marginLeft: '16px' }}>
                                                        <AdditionalEntityActions
                                                            disabled={false}
                                                            content={
                                                                <>
                                                                    {entity?.stateCode !== SubrentStateCodeEnum.DRAFT &&
                                                                    canViewFinancialData &&
                                                                    canUpdateFinancialData ? (
                                                                        <Button className={'ant-btn-page-actions'} onClick={this.onPay}>
                                                                            <SystemIcon type={SystemIconType.financial} />
                                                                            <span>Произвести оплату</span>
                                                                        </Button>
                                                                    ) : null}

                                                                    <Button
                                                                        className={'ant-btn-page-actions'}
                                                                        onClick={this.onCopyButtonClick}
                                                                    >
                                                                        <Icon
                                                                            component={IconControlPointDuplicateBlack}
                                                                            style={{ color: '#34bfa3' }}
                                                                        />
                                                                        {localize(LocalizationEnum.ASPECT__ACTION__DUPLICATE, 'span')}
                                                                    </Button>

                                                                    {entity.stateCode === SubrentStateCodeEnum.INPROGRESS &&
                                                                    entity.availableTransitionCodes?.includes(
                                                                        SubrentTransitionCodeEnum.FINISH
                                                                    ) ? (
                                                                        <Button
                                                                            className={'ant-btn-page-actions'}
                                                                            onClick={this.onChangeStatusClick}
                                                                        >
                                                                            <Icon
                                                                                component={IconFlagCheckered}
                                                                                style={{ color: '#34bfa3' }}
                                                                            />
                                                                            {localize(
                                                                                LocalizationEnum.PAGE__SHIPPINGS__ACTIONS__FINISH,
                                                                                'span'
                                                                            )}
                                                                        </Button>
                                                                    ) : null}

                                                                    {entity.availableTransitionCodes?.includes(
                                                                        SubrentTransitionCodeEnum.CANCEL
                                                                    ) ? (
                                                                        <Button
                                                                            className={'ant-btn-page-actions'}
                                                                            onClick={() =>
                                                                                this.changeStatus(SubrentTransitionCodeEnum.CANCEL)
                                                                            }
                                                                        >
                                                                            <Icon component={IconEraser} style={{ color: '#a0a0a0' }} />
                                                                            Отменить
                                                                        </Button>
                                                                    ) : null}

                                                                    {entity.archivable ? (
                                                                        !entity.archive ? (
                                                                            <Button
                                                                                className={'ant-btn-page-actions'} //#c4c5d6
                                                                                onClick={this.onArchiveButtonClick}
                                                                            >
                                                                                <Icon
                                                                                    component={IconArchive}
                                                                                    style={{ color: '#c4c5d6' }}
                                                                                />
                                                                                {localize(
                                                                                    LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE,
                                                                                    'span'
                                                                                )}
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                className={'ant-btn-page-actions'} //#00c5dc
                                                                                onClick={this.onArchiveButtonClick}
                                                                            >
                                                                                <Icon
                                                                                    component={IconLevelUp}
                                                                                    style={{ color: '#00c5dc' }}
                                                                                />
                                                                                {localize(
                                                                                    LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE,
                                                                                    'span'
                                                                                )}
                                                                            </Button>
                                                                        )
                                                                    ) : null}
                                                                    {(entity.stateCode === SubrentStateCodeEnum.FINISHED ||
                                                                        entity.stateCode === SubrentStateCodeEnum.INPROGRESS) &&
                                                                        canViewFinancialData &&
                                                                        canUpdateFinancialData && (
                                                                            <Button
                                                                                className={'ant-btn-page-actions'}
                                                                                onClick={this.onRecalculateClick}
                                                                            >
                                                                                <Icon
                                                                                    component={IconCalculatorSolid}
                                                                                    style={{ color: '#ff8d3a' }}
                                                                                />
                                                                                Расчет отработанных смен
                                                                            </Button>
                                                                        )}
                                                                </>
                                                            }
                                                        >
                                                            <AdditionalEntityActionsButton />
                                                        </AdditionalEntityActions>
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {/*<OperationTypeSelectButton*/}
                                                    {/*    disabled={!canCreateOperation}*/}
                                                    {/*    onChangeOperationType={async (*/}
                                                    {/*        code: OperationTypeCodeEnum,*/}
                                                    {/*        correctionCode?: RentStateCodeEnum | undefined*/}
                                                    {/*    ) => {*/}
                                                    {/*        this.createNewOperation(code, correctionCode);*/}
                                                    {/*    }}*/}
                                                    {/*    projectId={entity.id}*/}
                                                    {/*    activityFrameType={RentActivityFrameTypeCodeEnum.SUBRENT}*/}
                                                    {/*    presetData={{*/}
                                                    {/*        operationTypeCodes: [*/}
                                                    {/*            OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER,*/}
                                                    {/*            OperationTypeCodeEnum.CORRECT,*/}
                                                    {/*            OperationTypeCodeEnum.SUBRENTCANCEL,*/}
                                                    {/*        ],*/}
                                                    {/*        correctionTargetStateCodes: [*/}
                                                    {/*            RentStateCodeEnum.SUBRENTRETURNEDTOSHIPPER,*/}
                                                    {/*            RentStateCodeEnum.SUBRENTCANCELED,*/}
                                                    {/*        ],*/}
                                                    {/*    }}*/}
                                                    {/*/>*/}
                                                </>
                                            )
                                        }
                                    >
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                            key={SubrentShippingPageTabsEnum.DESCRIPTION}
                                        >
                                            <SubrentShippingPageDescriptionTabPane />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__ELEMENTS)}
                                            key={SubrentShippingPageTabsEnum.ELEMENTS}
                                            disabled={false}
                                        >
                                            <ElementsListWrapper
                                                ref={(ref) => {
                                                    if (!this.elementsListRef) this.elementsListRef = ref;
                                                }}
                                                gridName={'shipping-elements'}
                                                visible={currentTab === SubrentShippingPageTabsEnum.ELEMENTS}
                                                parentType={'shipping'}
                                                parentId={entity.id}
                                                location={this.props.location}
                                                updateParentEntity={() => {
                                                    this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
                                                }}
                                            />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane
                                            tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__OPERATIONS)}
                                            key={SubrentShippingPageTabsEnum.OPERATIONS}
                                            disabled={false}
                                        >
                                            <OperationsList
                                                gridName={'shipping-operations'}
                                                location={this.props.location}
                                                projectId={entity.id}
                                                parentEntityType={'shipping'}
                                            />
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                            </>
                        ) : null}
                    </div>
                </Spin>

                {this.props.editMode && (
                    <SubrentShippingCreateModal
                        title={localize(LocalizationEnum.PAGE__SHIPPINGS__FORM__FORM_TITLE__EDIT)}
                        businessAccountId={this.props.businessAccountId}
                        initialValues={entity as { [k: string]: any }}
                        filters={editSubrentShippingSchemeModalFilters}
                        modalName={'edit-subrent_shipping'}
                    />
                )}

                {entity && this.props.copyMode && (
                    <SubrentShippingCreateModal
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__ADD)}
                        validateAfterCreate={true}
                        title={localize(LocalizationEnum.PAGE__SHIPPINGS__FORM__FORM_TITLE__CREATE)}
                        copyMode
                        businessAccountId={this.props.businessAccountId}
                        initialValues={entity}
                        filters={createSubrentShippingSchemeModalFilters}
                        modalName={'create-subrent_shipping'}
                    />
                )}
                {this.props.payMode && <PaymentCreateModal subrent={entity} />}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    return {
        entity: storeState.subrent.entity,
        loading: storeState.subrent.loading,
        updating: storeState.subrent.updating,

        loadingError: storeState.subrent.loadingError,
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        editMode: !!(storeState.subrent.entity && ownProps.match.params['action'] === 'edit'),
        payMode: !!(storeState.subrent.entity && ownProps.match.params['action'] === 'pay'),

        copyMode: !!(storeState.subrent.entity && ownProps.match.params['action'] === 'copy'),
        tab:
            searchParams.tab &&
            (searchParams.tab === SubrentShippingPageTabsEnum.DESCRIPTION ||
                searchParams.tab === SubrentShippingPageTabsEnum.OPERATIONS ||
                searchParams.tab === SubrentShippingPageTabsEnum.ELEMENTS) /* || searchParams.tab === TabsEnum.STATISTICS*/
                ? searchParams.tab
                : SubrentShippingPageTabsEnum.ELEMENTS,
        operationFormMnemoKey: storeState.operationForm.mnemoKey,
        defaultShiftLengthInMinutes: storeState.businessAccountPreferences.preferences?.shiftLengthInMinutes,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        canUpdateFinancialData: canUpdateFinancialData(storeState.permissions.permissions),
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        tabFromStore: getCurrentTabsSelector(storeState)?.['subrentShipping'],
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
        statisticsModuleEnabled: statisticsModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = {
    loadEntity,
    setStatusAndArchive,
    reset,
    push,
    replace,
    setRenterAndProject,
    resetOperationForm,
    startNewOperation,
    fullResetOperation,
    removeConcurrentOperation,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const SubrentShippingPage = connect(mapStateToProps, mapDispatchToProps)(_SubrentShippingPage);
