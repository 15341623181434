import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import './roundButton.less';

interface IProps extends ButtonProps {
    colorScheme:
        | 'default'
        | 'danger'
        | 'success'
        | 'success2'
        | 'info'
        | 'brandSecond'
        | 'brandSecond2'
        | 'TO_ARCHIVE'
        | 'FROM_ARCHIVE'
        | 'blocked'
        | 'inRent'
        | 'PROLONG'
        | 'RETURN'
        | 'RETURN_BROKEN'
        | 'LOST_NO_RETURN'
        | 'CORRECT'
        | 'RENT'
        | 'CANCEL'
        | 'ORDER'
        | 'BOOK'
        | 'DRAFT'
        | 'UNDER_MAINTENANCE'
        | 'defaultSecondary'
        | 'successSecondary'
        | 'default3'
        | 'DELETE'
        | 'PLANNED'
        | 'ACTION_ELEMENTS_SET_DATES'
        | 'ACTION_ELEMENTS_SET_SHIFTS'
        | 'ACTION_ELEMENTS_RECALCULATE_SHIFTS'
        | 'ACTION_ELEMENTS_SET_PRICE'
        | 'default4'
        | 'join';
}

const RoundButton = (props: IProps) => (
    <Button
        icon={props.icon}
        title={props.title}
        href={props.href}
        target={props.target}
        block={props.block}
        loading={props.loading}
        disabled={props.disabled}
        onClick={props.onClick}
        style={props.style}
        shape="round"
        size={props.size ? props.size : 'large'}
        className={(props.className ? props.className + ' ' : '') + 'rr-round-btn rr-round-btn-' + props.colorScheme}
    >
        {props.children}
    </Button>
);

export default RoundButton;
