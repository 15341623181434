import { ReactNode, useEffect, useState } from 'react';
import { useLazyListContactsQuery, useListContactsQuery } from '../../../modules/main/renters/tabs/contacts/api/contactsApi';
import { ContactDrawer } from '../../../modules/main/renters/tabs/contacts/drawer/components/ContactDrawer/ContactDrawer';
import { ContactRecord } from '../../../server';
import { businessAccountIdSelector } from '../../../shared/reducers/system.reducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import DetailedSelect, { IDetailedSelectColumn } from './DetailedSelect';
import { useDebounce } from '../../../core/hooks/useDebounce';
import { BaseSelectOptionProps } from '../select/baseSelect';
import { useContactActionsFromDrawer } from '../../../modules/main/renters/tabs/contacts/hooks/useContactActionsFromDrawer';
import { setContact } from '../../../modules/main/renters/tabs/contacts/reducers/contact/contact.reducer';

interface Filters {
    hideArchive?: boolean;
}
export interface ContactDetailedSelectProps<T extends {}> {
    id: string;
    columns?: IDetailedSelectColumn[];
    placeholder?: ReactNode;
    value: number[];
    multiple?: boolean;
    onChange: (value: number | number[]) => void;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    getOptionProps?: (record: T) => BaseSelectOptionProps;
    getOptionPrefix?: (record: T) => ReactNode | null;
    onDataChange?: (data: T[]) => void;
    filters?: Filters;
    allowClear?: boolean;
    disabled?: boolean;
    records?: ContactRecord[];
    closeDrawerOnEdit?: boolean;
}

const ContactsDetailedSelect = ({
    id,
    placeholder = 'Выберите работника',
    multiple,
    value,
    onChange,
    getPopupContainer,
    getOptionProps,
    onDataChange,
    columns,
    filters,
    getOptionPrefix,
    allowClear,
    disabled,
    records,
    closeDrawerOnEdit,
}: ContactDetailedSelectProps<ContactRecord>) => {
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [selectedId, setSelectedId] = useState<number>();
    const [search, setSearch] = useState<string>();
    const debouncedSearchString = useDebounce<string | undefined>(search, 200);
    const dispatch = useAppDispatch();

    const { data, isLoading } = useListContactsQuery(
        {
            businessAccountId,
            params: {
                search: debouncedSearchString ? debouncedSearchString : undefined,
                limit: 100,
                page: 1,
                sortBy: 'lastUpdateDate',
                sortOrder: 'DESC',
            },
        },
        { refetchOnMountOrArgChange: true, skip: !!records }
    );

    const {
        edit: editAction,
        delete: deleteAction,
        archive: archiveAction,
        isLoading: isEntityActionsLoading,
    } = useContactActionsFromDrawer();

    const [getData] = useLazyListContactsQuery();

    useEffect(() => {
        getData({ businessAccountId, params: { limit: 100, page: 1, sortBy: 'lastUpdateDate', sortOrder: 'DESC' } }).then((res) => {
            const records = res.data?.entitiesData.records || [];
            const selectedData = records.filter((r) => value.includes(r.id));
            onDataChange?.(selectedData);
        });
    }, [businessAccountId, getData, JSON.stringify(value)]);

    return (
        <>
            <DetailedSelect<ContactRecord>
                allowClear={allowClear === false ? false : true}
                id={id}
                placeholder={placeholder}
                items={records ?? data?.entitiesData.records ?? []}
                columns={
                    columns ?? [
                        { key: 'personFullName', width: 240 },
                        { key: 'phoneNumber', width: 150 },
                        { key: 'email', width: 200, secondary: true },
                    ]
                }
                getKey={(item) => item.id}
                value={value}
                onChange={onChange}
                onSearch={(value: string) => setSearch(value)}
                details={{ text: 'Смотреть', clickHandler: (id: number | undefined) => setSelectedId(id) }}
                loading={isLoading}
                selectedId={selectedId}
                getPopupContainer={getPopupContainer}
                multiple={multiple}
                getOptionProps={getOptionProps}
                hideArchive={filters?.hideArchive}
                getOptionPrefix={getOptionPrefix}
                disabled={disabled}
            />
            {selectedId && (
                <ContactDrawer
                    contactId={selectedId}
                    onClose={() => setSelectedId(undefined)}
                    archiveContact={archiveAction}
                    deleteContact={deleteAction}
                    editContact={(contact) => {
                        if (closeDrawerOnEdit) {
                            setSelectedId(undefined);
                            dispatch(setContact(contact));
                        }
                        editAction(contact);
                    }}
                    isEntityActionsLoading={isEntityActionsLoading}
                />
            )}
        </>
    );
};
export default ContactsDetailedSelect;
