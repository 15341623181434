import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import {
    InstanceInfoCreate,
    InstanceInfoCreateBulk,
    InstanceInfoRead,
    InstanceTrackingTypeCodeEnum,
    serverApi,
    ServerError,
    WorkflowDirective,
} from '../../../../../server';
import { push } from 'connected-react-router';
import { getPathFromState, getServerError } from '../../../../../shared/util/utils';
import { showNotification } from '../../../../../components/notification/showNotification';
import { loadEntities, setLoading } from './product-instances.reducer';
import { loadEntities as loadEntitiesVariant } from '../../variants/reducers/product-variants.reducer';
import { loadEntity } from '../../products/reducers/product.reducer';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { getGridStorageData } from '../../../../../components/grid/utils';
import { IntlShape } from 'react-intl';

export const ACTION_TYPES = {
    CREATE_PRODUCT_INSTANCE: 'productInstance/CREATE_PRODUCT_INSTANCE',
    CREATE_INSTANCES_BULK: 'productInstance/CREATE_INSTANCES_BULK',
    UPDATE_PRODUCT_INSTANCE: 'productInstance/UPDATE_PRODUCT_INSTANCE',
    RESET_ERROR: 'productInstance/RESET_ERROR',
    CONDITION_PRODUCT_INSTANCE: 'productInstance/CONDITION_PRODUCT_INSTANCE',
    CHANGE_INSTANCE_STATUS: 'productInstance/CHANGE_INSTANCE_STATUS',
    LIST_PRODUCT_VARIANT: 'productInstance/LIST_PRODUCT_VARIANT',
    RESET: 'productInstance/RESET',
};

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    updating: false,
    updatingError: undefined as undefined | ServerError,
    entity: null as InstanceInfoRead | null,
};

export type productInstanceState = Readonly<typeof initialState>;

// Reducer

export default (state: productInstanceState = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.CREATE_PRODUCT_INSTANCE):
            return {
                ...state,
                updating: true,
                updatingError: undefined,
            };
        case FAILURE(ACTION_TYPES.CREATE_PRODUCT_INSTANCE):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.CREATE_PRODUCT_INSTANCE):
            return {
                ...state,
                updating: false,
                loading: false,
            };
        case REQUEST(ACTION_TYPES.CREATE_INSTANCES_BULK):
            return {
                ...state,
                updating: true,
                updatingError: undefined,
            };
        case FAILURE(ACTION_TYPES.CREATE_INSTANCES_BULK):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.CREATE_INSTANCES_BULK):
            return {
                ...state,
                updating: false,
                loading: false,
            };
        case REQUEST(ACTION_TYPES.UPDATE_PRODUCT_INSTANCE):
            return {
                ...state,
                updating: true,
                updatingError: undefined,
            };
        case FAILURE(ACTION_TYPES.UPDATE_PRODUCT_INSTANCE):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.UPDATE_PRODUCT_INSTANCE):
            return {
                ...state,
                updating: false,
                loading: false,
            };
        case REQUEST(ACTION_TYPES.CONDITION_PRODUCT_INSTANCE):
            return {
                ...state,
                loading: true,
                updatingError: undefined,
            };
        case FAILURE(ACTION_TYPES.CONDITION_PRODUCT_INSTANCE):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.CONDITION_PRODUCT_INSTANCE):
            return {
                ...state,
                entity: action.payload.data,
                loading: false,
            };
        case REQUEST(ACTION_TYPES.CHANGE_INSTANCE_STATUS):
            return {
                ...state,
                updating: true,
                updatingError: undefined,
            };
        case FAILURE(ACTION_TYPES.CHANGE_INSTANCE_STATUS):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.CHANGE_INSTANCE_STATUS):
            return {
                ...state,
                updating: false,
            };
        case REQUEST(ACTION_TYPES.LIST_PRODUCT_VARIANT):
            return {
                ...state,
                loading: true,
                updatingError: undefined,
            };
        case FAILURE(ACTION_TYPES.LIST_PRODUCT_VARIANT):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload),
            };
        case SUCCESS(ACTION_TYPES.LIST_PRODUCT_VARIANT):
            return {
                ...state,
                entity: action.payload.data,
                loading: true,
            };
        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                updatingError: undefined,
                loadingError: undefined,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
// Actions

// Получение состояния продукта

// Изменение продукта

export const createProductInstance = (
    intl,
    businessAccountId: number,
    id: number,
    entity: InstanceInfoCreate,
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum,
    variantId: number
) => {
    let newEntity = { ...entity };

    if (entity.inventoryNumber === '') {
        newEntity = { ...entity, inventoryNumber: undefined };
    }

    if (entity.stateDescription === '' || entity.inventoryNumber === '') {
        newEntity = {
            ...entity,
            stateDescription: entity.stateDescription === '' ? undefined : entity.stateDescription,
            inventoryNumber: entity.inventoryNumber === '' ? undefined : entity.inventoryNumber,
        };
    }

    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CREATE_PRODUCT_INSTANCE,
            payload:
                instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK
                    ? serverApi.createProductVariantInstance(businessAccountId, id, variantId, newEntity)
                    : serverApi.createProductInstance(businessAccountId, id, newEntity),
        }).then((result) => {
            if (result instanceof Error) {
                // Продукт не обновлен
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__INSTANCES__POPUP_NOTIFICATIONS__CREATION_FAILED));
            } else {
                // Продукт обновлен успешно
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__INSTANCES__POPUP_NOTIFICATIONS__CREATED)); // TODO мб выводить норм сообщение
                setTimeout(() => dispatch(loadEntities(intl, businessAccountId, id)), 1500);
                setTimeout(() => dispatch(loadEntitiesVariant(intl, businessAccountId, id)), 3000);
                dispatch(loadEntity(businessAccountId, id));

                const gridData = getGridStorageData('product-instances');
                dispatch(
                    push(
                        getPathFromState('/' + businessAccountId + `/inventory/products/${id}`, '', {
                            ...gridData.filters,
                            ...gridData.params,
                            tab: 'instances',
                        })
                    )
                );
            }
        });
    };
};

export const createInstancesBulk = (
    intl: IntlShape,
    businessAccountId: number,
    id: number,
    data: InstanceInfoCreateBulk,
    setSerialNumber: boolean,
    setInventoryNumber: boolean,
    setBarcode: boolean
) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CREATE_INSTANCES_BULK,
            payload: serverApi.createInstancesBulk(businessAccountId, id, setSerialNumber, setInventoryNumber, setBarcode, data),
        }).then((result) => {
            if (result instanceof Error) {
                // Продукт не обновлен
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__INSTANCES__POPUP_NOTIFICATIONS__CREATION_FAILED));
            } else {
                // Продукт обновлен успешно
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__INSTANCES__POPUP_NOTIFICATIONS__CREATED)); // TODO мб выводить норм сообщение
                setTimeout(() => dispatch(loadEntities(intl, businessAccountId, id)), 1500);
                setTimeout(() => dispatch(loadEntitiesVariant(intl, businessAccountId, id)), 3000);
                dispatch(loadEntity(businessAccountId, id));

                const gridData = getGridStorageData('product-instances');
                dispatch(
                    push(
                        getPathFromState('/' + businessAccountId + `/inventory/products/${id}`, '', {
                            ...gridData.filters,
                            ...gridData.params,
                            tab: 'instances',
                        })
                    )
                );
            }
        });
    };
};

export const updateProductInstanceById = (
    intl,
    businessAccountId: number,
    productId: number,
    id: number,
    entity,
    instanceTrackingTypeCode: InstanceTrackingTypeCodeEnum,
    variantId: number
) => {
    return (dispatch) => {
        let newEntity = { ...entity };

        if (entity.inventoryNumber === '') {
            newEntity = { ...entity, inventoryNumber: undefined };
        }

        if (entity.stateDescription === '' || entity.inventoryNumber === '') {
            newEntity = {
                ...entity,
                stateDescription: entity.stateDescription === '' ? undefined : entity.stateDescription,
                inventoryNumber: entity.inventoryNumber === '' ? undefined : entity.inventoryNumber,
            };
        }

        return dispatch({
            type: ACTION_TYPES.UPDATE_PRODUCT_INSTANCE,
            payload:
                instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK
                    ? serverApi.updateProductVariantInstanceById(businessAccountId, productId, variantId, id, newEntity)
                    : serverApi.updateProductInstanceById(businessAccountId, productId, id, newEntity),
        }).then((result) => {
            if (result instanceof Error) {
                // Продукт не обновлен
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__INSTANCES__POPUP_NOTIFICATIONS__UPDATE_FAILED));
            } else {
                // Продукт обновлен успешно
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__INSTANCES__POPUP_NOTIFICATIONS__UPDATED)); // TODO мб выводить норм сообщение
                setTimeout(() => dispatch(loadEntities(intl, businessAccountId, productId)), 1500);

                const gridData = getGridStorageData('product-instances');
                dispatch(
                    push(
                        getPathFromState('/' + businessAccountId + `/inventory/products/${productId}`, '', {
                            ...gridData.filters,
                            ...gridData.params,
                            tab: 'instances',
                        })
                    )
                );
                dispatch(loadEntity(businessAccountId, productId));
            }
        });
    };
};

export type conditionProductInstanceState = Readonly<typeof initialState>;

export const getProductInstanceById = (intl, businessAccountId: number, productId: number, id: number) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CONDITION_PRODUCT_INSTANCE,
            payload: serverApi.getProductInstanceById(businessAccountId, productId, id),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                dispatch(loadEntity(businessAccountId, productId));
            }
        });
    };
};

export type changeInstanceStatusState = Readonly<typeof initialState>;

export const transitProductInstanceWorkflowBulk = (
    intl,
    businessAccountId: number,
    productId: number,
    directive: Array<WorkflowDirective>
) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_INSTANCE_STATUS,
            payload: serverApi.transitProductInstanceWorkflowBulk(businessAccountId, productId, { directives: directive }),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__INSTANCES__POPUP_NOTIFICATIONS__UPDATED)); // TODO мб выводить норм сообщение
                dispatch(setLoading(true));
                setTimeout(() => dispatch(loadEntities(intl, businessAccountId, productId)), 1500);
                dispatch(loadEntity(businessAccountId, productId));
                setTimeout(() => dispatch(loadEntitiesVariant(intl, businessAccountId, productId)), 3000);
            }
        });
    };
};

export type listProductVariantsState = Readonly<typeof initialState>;

export const listProductVariants = (intl, businessAccountId: number, productId: number) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.LIST_PRODUCT_VARIANT,
            payload: serverApi.listProductVariants(businessAccountId, productId),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
            }
        });
    };
};

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR,
});

export const resetInstance = () => ({
    type: ACTION_TYPES.RESET,
});
