import React from 'react';
import {Card, Icon} from 'antd';
import {
    loadProjects,
    ProjectsHideState,
    projectsInitialParamsState,
    reset,
    setArchiveState,
    setStatus,
} from '../reducers/simpleOrders.reducer';
import {connect} from 'react-redux';
import {IRootState} from '../../../../../shared/reducers';
import {push, replace} from 'connected-react-router';
import RoundButton from '../../../../../components/button/roundButton';
import {
    IconArchive,
    IconBorderStyleSolid,
    IconCheckSquare,
    IconEraser,
    IconFlagCheckered,
    IconHandStopO,
    IconLevelUp
} from '../../../../../components/icons';
import {Spin} from '../../../../../components';
import {
    convertAvailableFiltersToObj,
    getFilteredParams,
    getPathFromState,
    getStateFromPath2,
    roundMaxFilterValue,
    roundMinFilterValue,
} from '../../../../../shared/util/utils';
import {
    BaseProjectFinancialFieldsTypeCode,
    BaseProjectNotFinancialFieldsTypeCode,
    ExcelExportTemplateTypeCodeEnum,
    ProjectRecord,
    ProjectTransitionCodeEnum,
    serverApi,
    TagEntityTypeCode,
} from '../../../../../server';
import every from 'lodash/every';
import projectColumns from './simpleOrdersColumns';
import {Location} from 'history';
import {simpleOrdersFilters} from './simpleOrdersFilters';
import moment from 'moment';
import debounce from 'lodash/debounce';
import {logEvent} from '../../../../../analytics/analytics';
import {LocalizationEnum, localize} from '../../../../../localization';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {Grid} from '../../../../../components/grid/Grid';
import {getGridStorageData, setGridStorageDataFilters} from '../../../../../components/grid/utils';
import {PageUtils} from '../../../../../shared/util/pageUtils';
import {canViewFinancialData} from '../../../../../shared/util/permissionUtils';
import {saveCurrentTabToStore} from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import {EntityActionType} from '../../../../../../index';
import {
    crewModuleEnabledSelector,
    logisticsModuleEnabledSelector,
} from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import {NestedAdditionalEntityActionsForTags} from '../../../../../components/additionalEntityActions/NestedAdditionalEntityActionsForTags/NestedAdditionalEntityActionsForTags';
import {GridName} from '../../../../../components/grid/utils/types';
import {IFormField} from '../../../../../components/dynamicForm/DynamicForm';
import {getProjectsFilters} from '../utils/getProjectsFilters';
import {downloadOffloading} from '../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import {getProjectsSortBy} from '../utils/getProjectsSortBy';
import {ProjectPageTabsEnum} from '../../../../../shared/constants/tabEnums';
import {ExportOffloadingPopover} from '../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import {OffloadingDocumentOnDownload} from '../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';
import {SimpleOrderActionType} from "../data/simpleOrderData";

interface IState {
    selection: number[];
    locationId?: number;
}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    renterId?: number;
    gridName: GridName;
}

class Component extends React.Component<IProps, IState> {
    private grid;
    private filtersForm;
    private fromFilters;
    private initialValues;
    private availableFilters;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = [
        'search',
        'status',
        'renterId',
        'hide',
        'delay',
        'shortage',
        'startDate',
        'problem',
        'sourceCode',
        'sortBy',
        'sortOrder',
        'locationId',
        'tags',
        'assigneeId',
        'debtSum',
    ];

    constructor(props: IProps) {
        super(props);

        this.state = {
            selection: [],
        };

        this.updateFilters = debounce(this.updateFilters, 300);
    }

    componentDidMount = async () => {
        this.initialValues = this.props.pageParams;
        const filtersChanged = await this.getActualFilters();
        if(!filtersChanged){
            let gridData = getGridStorageData(this.props.gridName);
            const params = {
                ...gridData.filters,
                ...gridData.params,
            };

            PageUtils.setPageParamsOrLoadData(
                getStateFromPath2(this.props.location.search),
                this.filters,
                () => {
                    this.setSavedParams(this.props.location.search);
                },
                this.loadDataAndSetFilterValues,
                params
            );
        }
    };

    componentDidUpdate = async (prevProps: IProps) => {
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );
        // let params = getStateFromPath2(this.props.locationSearchParams);
        // // if (params.tab === 'projects' && this.props.entities === null && prevProps.projectsLoading === this.props.projectsLoading) {
        // //     this.loadProjects();
        // // }
    };

    componentWillUnmount = () => {
        this.props.reset();
    };

    getActualFilters = async () => {
        const { businessAccountId, pageParams, renterId } = this.props;
        let needsToChangeParams = false;

        try {
            const { data: availableFiltersValues } = await serverApi.listProjectsAvailableFiltersValues(
                businessAccountId,
                renterId ? [`renterId;EQ;${renterId}`] : undefined
            );

            this.availableFilters = convertAvailableFiltersToObj(availableFiltersValues.filters);
            const filter = this.availableFilters.debtSum;

            if (filter) {
                let field = (simpleOrdersFilters[1].fields as IFormField[]).find((item) => item.id === 'debtSum');
                if (field) {
                    const min = roundMinFilterValue(filter[0] / 100);
                    const max = roundMaxFilterValue(filter[1] / 100);

                    if(pageParams.debtSum){
                        let debtSumMin = pageParams.debtSum[0];
                        let debtSumMax = pageParams.debtSum[1] || Number.MAX_SAFE_INTEGER;
                        if(debtSumMax > max){
                            needsToChangeParams = true;
                            debtSumMax = max;
                        }
                        if(debtSumMin < min){
                            needsToChangeParams = true;
                            debtSumMin = min;
                        }
                        if(needsToChangeParams){
                            const path = getPathFromState(this.props.location.pathname, this.props.location.search, {debtSum: [debtSumMin, debtSumMax]});
                            this.props.replace(path);
                        }
                    }

                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'currency',
                        min,
                        max,
                    };
                    if (!pageParams.debtSum) {
                        this.filtersForm?.setFieldsValue({ debtSum: [min, max] });
                    }
                }
            }
        } catch (e) {
            console.error(e);
            if (!pageParams.debtSum && this.filtersForm) {
                this.filtersForm.setFieldsValue({ debtSum: [0, 1000] });
            }
        }
        return needsToChangeParams;
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(this.props.gridName);
        const params = {
            ...gridData.filters,
            ...gridData.params,
        };
        const path = getPathFromState(this.props.location.pathname, search, params);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(path);
    };

    loadDataAndSetFilterValues = () => {
        this.loadProjects();
        if (!this.fromFilters && this.filtersForm) {
            const values = { ...projectsInitialParamsState, ...this.props.pageParams };
            if (!values.debtSum && this.availableFilters) {
                if (this.availableFilters.debtSum) {
                    values.debtSum = [
                        roundMinFilterValue(this.availableFilters.debtSum[0] / 100),
                        roundMaxFilterValue(this.availableFilters.debtSum[1] / 100),
                    ];
                }
            }
            this.filtersForm.setFieldsValue(values);
        }
        this.fromFilters = false;
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    changeOrderStatus = (record: ProjectRecord, code: ProjectTransitionCodeEnum) => {
        this.props.setStatus(this.props.intl, this.props.businessAccountId, [
            {
                id: record.id,
                businessVersion: record.businessVersion,
                transitionCode: code
            },
        ]);
    }

    onItemAction = async (item: ProjectRecord, action: EntityActionType) => {
        const itemUrl = `/${this.props.businessAccountId}/projects/simpleOrders/${item.id}`;
        const callback = (
            {
                edit: () => {
                    saveCurrentTabToStore('project', ProjectPageTabsEnum.DESCRIPTION);
                    this.props.push(itemUrl);
                    this.props.push(`${itemUrl}/edit?tab=description`);
                },
                archive: () => {
                    this.props.setArchiveState(this.props.intl, this.props.businessAccountId, [
                        {
                            id: item.id,
                            businessVersion: item.businessVersion,
                            archive: !item.archive,
                        },
                    ]);
                },
                pay: () => {
                    saveCurrentTabToStore('project', ProjectPageTabsEnum.DESCRIPTION);
                    this.props.push(`${itemUrl}`);
                    this.props.push(`${itemUrl}/pay?tab=description`);
                },
                cancel: async () => {
                    this.changeOrderStatus(item, ProjectTransitionCodeEnum.CANCEL);
                },
                [SimpleOrderActionType.returnToDraft]: () => {
                    this.changeOrderStatus(item, ProjectTransitionCodeEnum.RETURNTODRAFT);
                },
                [SimpleOrderActionType.rent]: () => {
                    this.changeOrderStatus(item, ProjectTransitionCodeEnum.RENT);
                },
                [SimpleOrderActionType.book]: () => {
                    this.changeOrderStatus(item, ProjectTransitionCodeEnum.BOOK);
                },
                [SimpleOrderActionType.finish]: () => {
                    this.changeOrderStatus(item, ProjectTransitionCodeEnum.FINISH);
                }
            } satisfies Partial<Record<EntityActionType, () => void>>
        )[action];

        if (callback) callback();
    };

    loadProjects = async () => {
        await this.props.loadProjects(this.props.intl, this.props.businessAccountId);

        const { pageParams, filteredCount } = this.props;
        logEvent({
            type: 'list projects',
            data: {
                'num filtered': filteredCount,
                'num per page': pageParams.limit,
                'num of the page': pageParams.page,
                'filter delay': pageParams.delay,
                'filter shortage': pageParams.shortage,
                'filter status': pageParams.status,
                'filter search': pageParams.search,
                'filter archive': !pageParams.hide,
                'filter renter id': pageParams.renterId,
                'filter start date from': pageParams.startDate ? pageParams.startDate[0] : undefined,
                'filter start date to': pageParams.startDate ? pageParams.startDate[1] : undefined,
            },
        });
    };

    onPageSizeChanged = (size) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                limit: size,
                page: 1,
            })
        );
        this.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                sortBy: id,
                sortOrder: desc ? 'DESC' : 'ASC',
            })
        );
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
        this.clearSelection();
    };

    onSelectionChanged = (data) => {
        this.setState({ selection: data });
    };

    onArchiveBtnClick = () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => ({ id: item.id, businessVersion: item.businessVersion, archive: !item.archive }));
            this.props.setArchiveState(this.props.intl, this.props.businessAccountId, selectedItems);
            this.clearSelection();
        }
    };

    changeState = (code: ProjectTransitionCodeEnum) => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => {
                    return {
                        id: item.id,
                        businessVersion: item.businessVersion,
                        transitionCode: code,
                    };
                });

            this.props.setStatus(this.props.intl, this.props.businessAccountId, selectedItems);
            this.clearSelection();
        }
    }

    getActionButtons = () => {
        let selectedItems = this.props.entities
            ? this.props.entities.filter((item: ProjectRecord) => {
                  return this.state.selection.indexOf(item.id) > -1;
              })
            : [];
        return [
            every(selectedItems, (item) => item.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.FINISH)) ? (
                <RoundButton
                    key={1}
                    title={'Вернуть и завершить'}
                    colorScheme={'success'}
                    onClick={()=>this.changeState(ProjectTransitionCodeEnum.FINISH)}
                >
                    <Icon component={IconFlagCheckered} />
                    Вернуть и завершить
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.CANCEL)) ? (
                <RoundButton key={2} title={'Отменить'} colorScheme={'CANCEL'} onClick={()=>this.changeState(ProjectTransitionCodeEnum.CANCEL)}>
                    <Icon component={IconEraser} />
                    Отменить
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.RETURNTODRAFT)) ? (
                <RoundButton key={3} title={'В черновик'} colorScheme={'DRAFT'} onClick={()=>this.changeState(ProjectTransitionCodeEnum.RETURNTODRAFT)}>
                    <Icon component={IconBorderStyleSolid} />
                    В черновик
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.BOOK)) ? (
                <RoundButton key={4} title={'Забронировать'} colorScheme={'BOOK'} onClick={()=>this.changeState(ProjectTransitionCodeEnum.BOOK)}>
                    <Icon component={IconCheckSquare} />
                    Забронировать
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.RENT)) ? (
                <RoundButton key={5} title={'Выдать'} colorScheme={'RENT'} onClick={()=>this.changeState(ProjectTransitionCodeEnum.RENT)}>
                    <Icon component={IconHandStopO} />
                    Выдать
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => !item.archive && item.archivable) ? (
                <RoundButton
                    key={6}
                    title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'TO_ARCHIVE'}
                >
                    <Icon component={IconArchive} />
                    {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.archive) ? (
                <RoundButton
                    key={7}
                    title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'FROM_ARCHIVE'}
                >
                    <Icon component={IconLevelUp} />
                    {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,
            <NestedAdditionalEntityActionsForTags
                entities={selectedItems}
                tagType={TagEntityTypeCode.PROJECT}
                clearSelection={this.clearSelection}
                onSuccess={() => {
                    this.props.loadProjects(this.props.intl, this.props.businessAccountId);
                }}
            />,
        ];
    };

    onFiltersChange = (data) => {
        this.updateFilters(this.getProcessedParamsForUpdate(data));
    };

    onOffloadingDownload: OffloadingDocumentOnDownload = async ({ fileType, options }) => {
        const { selection } = this.state;
        const { intl, businessAccountId, pageParams, canViewFinancialData } = this.props;
        const { sortOrder, search } = pageParams;
        const filters = getProjectsFilters(pageParams);
        const sortBy = getProjectsSortBy(pageParams);

        const { excludeColumns } = this.getExcludeValues();

        await downloadOffloading({
            businessAccountId,
            canViewFinancialData,
            exportTemplateType: ExcelExportTemplateTypeCodeEnum.BASEPROJECTTEMPLATE,
            fileName: 'Экспорт проектов',
            fileType,
            filters,
            financialFieldsEnum: BaseProjectFinancialFieldsTypeCode,
            grid: this.grid,
            intl,
            notFinancialFieldsEnum: BaseProjectNotFinancialFieldsTypeCode,
            selection,
            values: options,
            sortOrder,
            sortBy,
            search,
            excludeColumns,
        });
    };

    getProcessedParamsForUpdate = (data) => {
        const processedData = { ...data };

        processedData.renterId =
            processedData.renterId && processedData.renterId.key
                ? processedData.renterId.key
                : processedData.renterId
                ? processedData.renterId
                : undefined;

        if (processedData.startDate) {
            const startDate = processedData.startDate.map((item) => (item ? moment(item).valueOf() : ''));
            if (!startDate[0] && !startDate[1]) processedData.startDate = undefined;
            else if (!startDate[1]) processedData.startDate = [startDate[0]];
            else processedData.startDate = startDate;
        }

        if (processedData.hideArchive === false) processedData.hideArchive = undefined;

        processedData.page = 1;

        return processedData;
    };

    updateFilters = (data) => {
        data.renterId = data.renterId && data.renterId.key ? data.renterId.key : data.renterId || undefined;

        if (data.debtSum) {
            if (
                data.debtSum.toString() ===
                [
                    roundMinFilterValue(this.availableFilters.debtSum[0] / 100),
                    roundMaxFilterValue(this.availableFilters.debtSum[1] / 100),
                ].toString()
            ) {
                data.debtSum = undefined;
            }
        }

        if (data.startDate) {
            const startDate = data.startDate.map((item) => (item ? moment(item).valueOf() : ''));
            if (!startDate[0] && !startDate[1]) data.startDate = undefined;
            else if (!startDate[1]) data.startDate = [startDate[0]];
            else data.startDate = startDate;
        }

        if (data.hideArchive === false) data.hideArchive = undefined;

        if (Array.isArray(data.status) && data.status.length === 0) {
            data.status = undefined;
        }
        if (Array.isArray(data.tags) && data.tags.length === 0) {
            data.tags = undefined;
        }

        data.page = 1;

        this.fromFilters = true;
        setGridStorageDataFilters(this.props.gridName, data);
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        this.clearSelection();
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            status: undefined,
            renterId: undefined,
            hideArchive: undefined,
            delay: undefined,
            shortage: undefined,
            startDate: undefined,
            problem: undefined,
            sourceCode: undefined,
            hide: undefined,
            locationId: undefined,
            tags: undefined,
            assigneeId: undefined,
            debtSum: undefined,
        };
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(this.props.gridName, data);
    };

    fetchEntityErrorCallback = (id: number) => {
        const { filteredParams, deletedCount } = getFilteredParams(this.props.pageParams, id);

        const processedParams = this.getProcessedParamsForUpdate(filteredParams);

        if (deletedCount > 0) {
            setGridStorageDataFilters(this.props.gridName, processedParams);
            this.props.replace(getPathFromState(this.props.location.pathname, '', processedParams));
        }
    };

    getExcludeValues = () => {
        const { canViewFinancialData, logisticsModuleEnabled, crewModuleEnabled } = this.props;

        let excludeColumns = canViewFinancialData
            ? []
            : [
                  'defaultDiscount',
                  'currentOrderedSum',
                  'currentBookedSum',
                  'currentRentSum',
                  'sumAfterTaxes',
                  'debtSum',
                  'costOfRent',
                  'costOfWork',
                  'costOfExpenses',
                  'sumToBePaidAfterTaxes'
              ];

        excludeColumns = this.props.renterId === undefined ? excludeColumns : [...excludeColumns, 'renterShortName', 'sourceCode'];
        let filtersExcludeFields = this.props.renterId === undefined ? [] : ['renterId', 'sourceCode'];

        if (!logisticsModuleEnabled) {
            excludeColumns.push('locationName');
            filtersExcludeFields.push('locationId');
        }

        if (!crewModuleEnabled) {
            excludeColumns.push('costOfWork');
        }

        return {
            excludeColumns,
            filtersExcludeFields,
        };
    };

    render() {
        const { entities, projectsLoading, filteredCount } = this.props;

        const { excludeColumns, filtersExcludeFields } = this.getExcludeValues();

        return (
            <Spin spinning={projectsLoading}>
                <Card bordered={false}>
                    {this.availableFilters && <Grid
                        filtersResetFiltersCb={this.resetFilters}
                        filtersCurrentValues={this.props.pageParams}
                        filtersDefaultValues={projectsInitialParamsState}
                        filtersGetFiltersFormRef={this.getFiltersForm}
                        filtersOnChange={this.onFiltersChange}
                        filtersData={simpleOrdersFilters}
                        filtersInitialValues={this.initialValues}
                        filtersExcludeFields={filtersExcludeFields}
                        excludeColumns={excludeColumns}
                        ref={(ref) => {
                            if (!this.grid) this.grid = ref;
                        }}
                        onRowAction={this.onItemAction}
                        onSortedChange={this.onSortedChange}
                        onPageChanged={this.onPageChanged}
                        onPageSizeChanged={this.onPageSizeChanged}
                        onSelectionChanged={this.onSelectionChanged}
                        filtered={filteredCount}
                        pageSize={this.props.pageParams.limit}
                        currentPage={this.props.pageParams.page}
                        columns={projectColumns}
                        data={entities}
                        indexFieldName={'id'}
                        actionButtons={this.getActionButtons()}
                        entityType={'project'}
                        defaultSorted={this.props.pageParams.sortBy}
                        defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                        hideArchive={
                            this.props.pageParams.hide === ProjectsHideState.Archive ||
                            this.props.pageParams.hide === ProjectsHideState.ArchiveCancelled
                        }
                        gridName={this.props.gridName}
                        onCellAction={(dataIndex, record: ProjectRecord) => {
                            if (dataIndex === 'locationName') {
                                this.setState({ locationId: record.locationId });
                            }
                        }}
                        fetchEntityErrorCallback={this.fetchEntityErrorCallback}
                        exportBlock={<ExportOffloadingPopover storageKey={'projectsOffloading'} onDownload={this.onOffloadingDownload} />}
                    />}
                </Card>
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entities: storeState.simpleOrders.entities,
        renter: storeState.renter,
        projectsLoading: storeState.simpleOrders.loading,
        locationSearchParams: storeState.router.location.search,
        pageParams: storeState.simpleOrders.params,
        filteredCount: storeState.simpleOrders.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
        locationPathname: storeState.router.location.pathname,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        logisticsModuleEnabled: logisticsModuleEnabledSelector(storeState),
        crewModuleEnabled: crewModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = { loadProjects, reset, push, replace, setArchiveState, setStatus };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const SimpleOrdersList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));
