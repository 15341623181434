import React from 'react';
import {Card, Icon} from 'antd';
import {loadSubrents, reset, setArchiveState, setStatus, subrentsInitialParamsState} from './reducers/subrents.reducer';
import {connect} from 'react-redux';
import {IRootState} from '../../../../shared/reducers';
import {push, replace} from 'connected-react-router';
import RoundButton from '../../../../components/button/roundButton';
import {
    IconArchive,
    IconEraser,
    IconFlagCheckered,
    IconLevelUp,
    IconReply,
    IconRocket
} from '../../../../components/icons';
import {Spin} from '../../../../components';
import {
    convertAvailableFiltersToObj,
    getFilteredParams,
    getPathFromState,
    getStateFromPath2, isDefined,
    roundMaxFilterValue,
    roundMinFilterValue,
} from '../../../../shared/util/utils';
import {
    ExcelExportTemplateTypeCodeEnum,
    ProjectRecord,
    ProjectStateCodeEnum,
    SubrentFinancialFieldsTypeCode,
    SubrentNotFinancialFieldsTypeCode,
    SubrentRecord,
    SubrentStateCodeEnum,
    SubrentTransitionCodeEnum,
} from '../../../../server/api';
import every from 'lodash/every';
import columns from './subrentShippingsListTabPaneColumns';
import {Location} from 'history';
import {subrentFilters} from './subrentShippingsListTabPaneFilters';
import moment from 'moment';
import debounce from 'lodash/debounce';
import {logEvent} from '../../../../analytics/analytics';
import {LocalizationEnum, localize} from '../../../../localization';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {Grid} from '../../../../components/grid/Grid';
import {getGridStorageData, setGridStorageDataFilters} from '../../../../components/grid/utils';
import {PageUtils} from '../../../../shared/util/pageUtils';
import {canViewFinancialData} from '../../../../shared/util/permissionUtils';
import {EntityActionType} from '../../../../../index';
import {subrentPageUrlRoute} from '../subrentPage';
import {saveCurrentTabToStore} from '../../../../shared/reducers/userSettings/userSettings.reducer';
import {GridName} from '../../../../components/grid/utils/types';
import {IFormField} from '../../../../components/dynamicForm/DynamicForm';
import {serverApi} from '../../../../server';
import {ExportOffloadingPopover} from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import {downloadOffloading} from '../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import {subrentModuleEnabledSelector} from '../../../../shared/reducers/businessAccountPreferences.reducer';
import {getSubrentsFilters} from './utils/getSubrentsFilters';
import {getSubrentsSortBy} from './utils/getSubrentsSortBy';

import {SubrentPageTabsEnum, SubrentShippingPageTabsEnum} from '../../../../shared/constants/tabEnums';
import {OffloadingDocumentOnDownload} from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';

interface IState {
    selection: number[];
}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    supplierId?: number;
    gridName: GridName;
}

class Component extends React.Component<IProps, IState> {
    private grid;
    private filtersForm;
    private fromFilters;
    private initialValues;
    private availableFilters;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = [
        'search',
        'status',
        'supplierId',
        'hide',
        'delay',
        'shortage',
        'startDate',
        'problem',
        'sortBy',
        'sortOrder',
        'assigneeId',
        'debtToSupplierSum',
    ];

    constructor(props: IProps) {
        super(props);

        this.state = {
            selection: [],
        };

        this.updateFilters = debounce(this.updateFilters, 300);
    }

    componentDidMount = async () => {
        this.initialValues = this.props.pageParams;
        const filtersChanged = await this.getActualFilters();
        if(!filtersChanged){
            PageUtils.setPageParamsOrLoadData(
                getStateFromPath2(this.props.location.search),
                this.filters,
                () => {
                    this.setSavedParams(this.props.location.search);
                },
                this.loadDataAndSetFilterValues
            );
        }
    };

    componentDidUpdate = async (prevProps: IProps) => {
        if(prevProps.locationPathname === this.props.locationPathname){
            PageUtils.update(
                prevProps.pageParams,
                this.props.pageParams,
                this.updatingAfterSetFromStorage,
                this.fromFilters,
                this.setSavedParams,
                this.loadDataAndSetFilterValues,
                this.filters,
                () => {
                    this.updatingAfterSetFromStorage = false;
                }
            );
            let params = getStateFromPath2(this.props.locationSearchParams);
            if (params.tab === 'projects' && this.props.entities === null && prevProps.projectsLoading === this.props.projectsLoading) {
                this.loadProjects();
            }
        }
    };

    componentWillUnmount = () => {
        this.props.reset();
    };

    getActualFilters = async () => {
        const { businessAccountId, pageParams, supplierId } = this.props;
        let needsToChangeParams = false;

        try {
            const { data: availableFiltersValues } = await serverApi.listSubrentsAvailableFiltersValues(
                businessAccountId,
                supplierId ? [`supplierId;EQ;${supplierId}`] : undefined
            );

            this.availableFilters = convertAvailableFiltersToObj(availableFiltersValues.filters);
            const filter = this.availableFilters.debtToSupplierSum;
            let debtToSupplierFilterMin:number|undefined;
            let debtToSupplierFilterMax:number|undefined;

            if (filter) {
                let field = (subrentFilters[1].fields as IFormField[]).find((item) => item.id === 'debtToSupplierSum');
                if (field) {
                    debtToSupplierFilterMin = roundMinFilterValue(filter[0] / 100);
                    debtToSupplierFilterMax = roundMaxFilterValue(filter[1] / 100);
                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'currency',
                        min: debtToSupplierFilterMin,
                        max: debtToSupplierFilterMax,
                    };
                    if (!pageParams.debtToSupplierSum) {
                        this.filtersForm?.setFieldsValue({ debtToSupplierSum: [debtToSupplierFilterMin, debtToSupplierFilterMax] });
                    }

                    if(pageParams.debtToSupplierSum){
                        let debtSumMin = pageParams.debtToSupplierSum[0];
                        let debtSumMax = pageParams.debtToSupplierSum[1] || Number.MAX_SAFE_INTEGER;
                        if(debtSumMax > debtToSupplierFilterMax){
                            needsToChangeParams = true;
                            debtSumMax = debtToSupplierFilterMax;
                        }
                        if(debtSumMin < debtToSupplierFilterMin){
                            needsToChangeParams = true;
                            debtSumMin = debtToSupplierFilterMin;
                        }
                        if(needsToChangeParams){
                            const path = getPathFromState(this.props.location.pathname, this.props.location.search, {debtToSupplierSum: [debtSumMin, debtSumMax]});
                            this.props.replace(path);
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
            if (!pageParams.debtToSupplierSum && this.filtersForm) {
                this.filtersForm.setFieldsValue({ debtToSupplierSum: [0, 1000] });
            }
        }
        return needsToChangeParams;
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(this.props.gridName);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    loadDataAndSetFilterValues = () => {
        this.loadProjects();

        if (!this.fromFilters && this.filtersForm) {
            const values = { ...subrentsInitialParamsState, ...this.props.pageParams };
            if (!values.debtToSupplierSum && this.availableFilters) {
                if (this.availableFilters.debtToSupplierSum) {
                    values.debtToSupplierSum = [
                        roundMinFilterValue(this.availableFilters.debtToSupplierSum[0] / 100),
                        roundMaxFilterValue(this.availableFilters.debtToSupplierSum[1] / 100),
                    ];
                }
            }
            this.filtersForm.setFieldsValue(values);
        }
        this.fromFilters = false;
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    onItemAction = (item: ProjectRecord, action: EntityActionType) => {
        const itemUrl = `/${this.props.businessAccountId}/${subrentPageUrlRoute}/${SubrentPageTabsEnum.SHIPPINGS}/${item.id}`;

        const callback = (
            {
                edit: () => {
                    saveCurrentTabToStore('subrent', SubrentShippingPageTabsEnum.DESCRIPTION);
                    this.props.push(itemUrl);
                    this.props.push(`${itemUrl}/edit?tab=description`);
                },
                archive: () => {
                    this.props.setArchiveState(this.props.intl, this.props.businessAccountId, [
                        { id: item.id, businessVersion: item.businessVersion, archive: !item.archive },
                    ]);
                },
                lock: () => {
                    this.props.setStatus(this.props.intl, this.props.businessAccountId, [
                        {
                            id: item.id,
                            businessVersion: item.businessVersion,
                            transitionCode: item.stateCode === ProjectStateCodeEnum.DRAFT || item.stateCode === 'FINISHED' ? 'ACTIVATE' : 'FINISH',
                        },
                    ]);
                },
                copy: () => {
                    saveCurrentTabToStore('subrent', SubrentShippingPageTabsEnum.DESCRIPTION);
                    this.props.push(itemUrl);
                    this.props.push(`${itemUrl}/copy?tab=description`);
                },
                pay: () => {
                    saveCurrentTabToStore('subrent', SubrentShippingPageTabsEnum.DESCRIPTION);
                    this.props.push(itemUrl);
                    this.props.push(`${itemUrl}/pay?tab=description`);
                },
            } satisfies Partial<Record<EntityActionType, Function>>
        )[action];

        if (callback) callback();
    };

    loadProjects = async () => {
        await this.props.loadProjects(this.props.intl, this.props.businessAccountId);

        const { pageParams, filteredCount } = this.props;
        logEvent({
            type: 'list projects',
            data: {
                'num filtered': filteredCount,
                'num per page': pageParams.limit,
                'num of the page': pageParams.page,
                'filter delay': pageParams.delay,
                'filter shortage': pageParams.shortage,
                'filter status': pageParams.status,
                'filter search': pageParams.search,
                'filter renter id': pageParams.supplierId,
                'filter start date from': pageParams.startDate ? pageParams.startDate[0] : undefined,
                'filter start date to': pageParams.startDate ? pageParams.startDate[1] : undefined,
            },
        });
    };

    onPageSizeChanged = (size) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { limit: size, page: 1 }));
        this.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, { sortBy: id, sortOrder: desc ? 'DESC' : 'ASC' })
        );
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
        this.clearSelection();
    };

    onSelectionChanged = (data) => {
        this.setState({ selection: data });
    };

    onArchiveBtnClick = () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => ({ id: item.id, businessVersion: item.businessVersion, archive: !item.archive }));
            this.props.setArchiveState(this.props.intl, this.props.businessAccountId, selectedItems);
            this.clearSelection();
        }
    };

    onChangeStateButtonClick = () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => {
                    return {
                        id: item.id,
                        businessVersion: item.businessVersion,
                        transitionCode: item.stateCode === 'IN_PROGRESS' ? 'FINISH' : 'ACTIVATE',
                    };
                });

            this.props.setStatus(this.props.intl, this.props.businessAccountId, selectedItems);
            this.clearSelection();
        }
    };

    onCancelButtonClick = () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => {
                    return {
                        id: item.id,
                        businessVersion: item.businessVersion,
                        transitionCode: SubrentTransitionCodeEnum.CANCEL
                    };
                });

            this.props.setStatus(this.props.intl, this.props.businessAccountId, selectedItems);
            this.clearSelection();
        }
    };

    getActionButtons = () => {
        let selectedItems = this.props.entities
            ? this.props.entities.filter((item: SubrentRecord) => {
                  return this.state.selection.indexOf(item.id) > -1;
              })
            : [];
        return [
            every(
                selectedItems,
                (item) =>
                    item.availableTransitionCodes?.includes(SubrentTransitionCodeEnum.ACTIVATE) &&
                    item.stateCode === SubrentStateCodeEnum.DRAFT
            ) ? (
                <RoundButton
                    key={0}
                    title={LocalizationEnum.PAGE__SHIPPINGS__ACTIONS__START}
                    colorScheme={'inRent'}
                    onClick={this.onChangeStateButtonClick}
                >
                    <Icon component={IconRocket} />
                    {localize(LocalizationEnum.PAGE__SHIPPINGS__ACTIONS__START, 'span')}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(SubrentTransitionCodeEnum.FINISH)) ? (
                <RoundButton
                    key={1}
                    title={LocalizationEnum.PAGE__SHIPPINGS__ACTIONS__FINISH}
                    colorScheme={'success'}
                    onClick={this.onChangeStateButtonClick}
                >
                    <Icon component={IconFlagCheckered} />
                    {localize(LocalizationEnum.PAGE__SHIPPINGS__ACTIONS__FINISH, 'span')}
                </RoundButton>
            ) : null,
            every(
                selectedItems,
                (item) =>
                    item.availableTransitionCodes?.includes(SubrentTransitionCodeEnum.ACTIVATE) &&
                    item.stateCode === SubrentStateCodeEnum.FINISHED
            ) ? (
                <RoundButton
                    key={2}
                    title={LocalizationEnum.PAGE__SHIPPINGS__ACTIONS__RESUME}
                    colorScheme={'inRent'}
                    onClick={this.onChangeStateButtonClick}
                >
                    <Icon component={IconReply} />
                    {localize(LocalizationEnum.PAGE__SHIPPINGS__ACTIONS__RESUME, 'span')}
                </RoundButton>
            ) : null,
            every(selectedItems, (item) => item.availableTransitionCodes?.includes(SubrentTransitionCodeEnum.CANCEL)) ? (
                <RoundButton key={5} title={'Отменить'} colorScheme={'CANCEL'} onClick={this.onCancelButtonClick}>
                    <Icon component={IconEraser} />
                    Отменить
                </RoundButton>
            ) : null,
            every(selectedItems, (item) => !item.archive && item.archivable) ? (
                <RoundButton
                    key={3}
                    title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'TO_ARCHIVE'}
                >
                    <Icon component={IconArchive} />
                    {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.archive) ? (
                <RoundButton
                    key={4}
                    title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'FROM_ARCHIVE'}
                >
                    <Icon component={IconLevelUp} />
                    {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,
        ];
    };

    onFiltersChange = (data) => {
        this.updateFilters(this.getProcessedFiltersForUpdate(data));
    };

    getProcessedFiltersForUpdate = (data) => {
        const processedData = { ...data };

        processedData.supplierId =
            processedData.supplierId && processedData.supplierId.key
                ? processedData.supplierId.key
                : processedData.supplierId
                ? processedData.supplierId
                : undefined;

        if (processedData.startDate) {
            const startDate = processedData.startDate.map((item) => (item ? moment(item).valueOf() : ''));
            if (!startDate[0] && !startDate[1]) processedData.startDate = undefined;
            else if (!startDate[1]) processedData.startDate = [startDate[0]];
            else processedData.startDate = startDate;
        }

        processedData.page = 1;

        return processedData;
    };

    updateFilters = (data) => {
        this.fromFilters = true;

        if (data.debtToSupplierSum) {
            if (
                data.debtToSupplierSum.toString() ===
                [
                    roundMinFilterValue(this.availableFilters.debtToSupplierSum[0] / 100),
                    roundMaxFilterValue(this.availableFilters.debtToSupplierSum[1] / 100),
                ].toString()
            ) {
                data.debtToSupplierSum = undefined;
            }
        }

        if (Array.isArray(data.status) && data.status.length === 0) {
            data.status = undefined;
        }

        setGridStorageDataFilters(this.props.gridName, data);
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        this.clearSelection();
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            status: undefined,
            supplierId: undefined,
            hide: undefined,
            delay: undefined,
            shortage: undefined,
            startDate: undefined,
            problem: undefined,
            assigneeId: undefined,
            debtToSupplierSum: undefined,
        };
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(this.props.gridName, data);
    };

    fetchEntityErrorCallback = (id: number) => {
        const { filteredParams, deletedCount } = getFilteredParams(this.props.pageParams, id);

        const processedParams = this.getProcessedFiltersForUpdate(filteredParams);

        if (deletedCount > 0) {
            setGridStorageDataFilters(this.props.gridName, processedParams);
            this.props.replace(getPathFromState(this.props.location.pathname, '', processedParams));
        }
    };

    onOffloadingDownload: OffloadingDocumentOnDownload = async ({ fileType, options }) => {
        const { selection } = this.state;
        const { intl, businessAccountId, pageParams, canViewFinancialData } = this.props;
        const { sortOrder, search } = pageParams;
        const filters = getSubrentsFilters(pageParams);
        const sortBy = getSubrentsSortBy(pageParams);

        const { excludeColumns } = this.getExcludeValues();

        await downloadOffloading({
            businessAccountId,
            canViewFinancialData,
            exportTemplateType: ExcelExportTemplateTypeCodeEnum.SUBRENTTEMPLATE,
            fileName: 'Экспорт поставок',
            fileType,
            filters,
            financialFieldsEnum: SubrentFinancialFieldsTypeCode,
            grid: this.grid,
            intl,
            notFinancialFieldsEnum: SubrentNotFinancialFieldsTypeCode,
            selection,
            values: options,
            sortOrder,
            sortBy,
            search,
            excludeColumns,
        });
    };

    getExcludeValues = () => {
        const { supplierId, canViewFinancialData } = this.props;
        const excludeColumns = supplierId === undefined ? [] : ['supplierShortName'];
        const filtersExcludeFields = supplierId === undefined ? [] : ['supplierId'];

        if (!canViewFinancialData) {
            excludeColumns.push('debtToSupplierSum', 'sum', 'currentSubrentSum', 'currentSubrentShipmentBookedSum');
        }

        return {
            excludeColumns,
            filtersExcludeFields,
        };
    };

    render() {
        const { entities, projectsLoading, filteredCount } = this.props;

        const { excludeColumns, filtersExcludeFields } = this.getExcludeValues();

        return (
            <Spin spinning={projectsLoading}>
                <Card bordered={false}>
                    {this.availableFilters && <Grid
                        filtersResetFiltersCb={this.resetFilters}
                        filtersCurrentValues={this.props.pageParams}
                        filtersDefaultValues={subrentsInitialParamsState}
                        filtersGetFiltersFormRef={this.getFiltersForm}
                        filtersOnChange={this.onFiltersChange}
                        filtersData={subrentFilters}
                        filtersInitialValues={this.initialValues}
                        filtersExcludeFields={filtersExcludeFields}
                        excludeColumns={excludeColumns}
                        ref={(ref) => {
                            if (!this.grid) this.grid = ref;
                        }}
                        onRowAction={this.onItemAction}
                        onSortedChange={this.onSortedChange}
                        onPageChanged={this.onPageChanged}
                        onPageSizeChanged={this.onPageSizeChanged}
                        onSelectionChanged={this.onSelectionChanged}
                        filtered={filteredCount}
                        pageSize={this.props.pageParams.limit}
                        currentPage={this.props.pageParams.page}
                        columns={columns}
                        data={entities}
                        indexFieldName={'id'}
                        actionButtons={this.getActionButtons()}
                        entityType={'subrent'}
                        defaultSorted={this.props.pageParams.sortBy}
                        defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                        gridName={this.props.gridName}
                        fetchEntityErrorCallback={this.fetchEntityErrorCallback}
                        exportBlock={<ExportOffloadingPopover storageKey={'subrentsOffloading'} onDownload={this.onOffloadingDownload} />}
                    />}
                </Card>
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entities: storeState.subrents.entities,
        renter: storeState.renter,
        projectsLoading: storeState.subrents.loading,
        locationSearchParams: storeState.router.location.search,
        pageParams: storeState.subrents.params,
        filteredCount: storeState.subrents.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
        locationPathname: storeState.router.location.pathname,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = { loadProjects: loadSubrents, reset, push, replace, setArchiveState, setStatus };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const SubrentShippingsListTabPane = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));
