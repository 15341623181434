import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { SidebarWrapper } from '../../shared/layout/sidebar/sidebarWrapper';
import { renderRoutes } from 'react-router-config';
import { routes as appRoutes } from '../../config/routes';
import intersection from 'lodash/intersection';
import {
    GET_BUSINESS_ACCOUNT_CONTACTS_INTERVAL_MSEC,
    GET_BUSINESS_ACCOUNT_INDICATORS_INTERVAL_MSEC,
    GET_BUSINESS_ACCOUNT_INFO_INTERVAL_MSEC,
    GET_BUSINESS_ACCOUNT_PREFERENCES_INTERVAL_MSEC,
    GET_CONCURRENT_OPERATIONS_INTERVAL_MSEC,
    GET_PERMISSIONS_INTERVAL_MSEC,
    MENU_COLLAPSED_WIDTH,
    MENU_WIDTH,
    UPDATE_DATA_INTERVAL_MSEC,
} from '../../config/constants';
import Footer from '../../shared/layout/footer/footer';
import Content from '../../shared/layout/content/content';
import { IRootState } from '../../shared/reducers';
import { setSettings, setSidebarCollapsed, UserSettingsState } from '../../shared/reducers/userSettings/userSettings.reducer';
import Media from 'react-media';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { getBusinessAccountIndicators } from '../../shared/reducers/businessAccountIndicators.reducer';
import { getBusinessAccount } from '../../shared/reducers/businessAccount.reducer';
import {
    BarcodeEntityObj,
    BarcodeEntityObjEntityTypeCodeEnum,
    BusinessAccountModuleCodeEnum,
    BusinessAccountPreferencesInfoRead,
    BusinessAccountStateCodeEnum,
    GlobalPermissionCodeEnum,
    RentActivityFrameTypeCodeEnum,
    serverApi,
} from '../../server';
import { Alert } from '../../components/alert/alert';
import { rrLogout } from '../../shared/util/rrLogout';
import { getServerError } from '../../shared/util/utils';
import Invitation from '../invitation/invitation';
import { CurrencyContext } from '../../components/currentCurrency/currentCurrency';
import {
    crewModuleEnabledSelector,
    expensesModuleEnabledSelector,
    getBusinessAccountPreferences,
    logisticsModuleEnabledSelector,
    offersModuleEnabledSelector,
    statisticsModuleEnabledSelector,
    subrentModuleEnabledSelector,
} from '../../shared/reducers/businessAccountPreferences.reducer';
import { getBusinessAccountContacts } from '../../shared/reducers/businessAccountContacts.reducer';
import {
    barcodeScanEventTarget,
    getOperationFromLS,
    getStore,
    getStoreState,
    isOperationInLS,
    removeOperationFromLS,
} from '../../../index';
import { push } from 'connected-react-router';
import { hidePreloader } from '../../App';
import {
    registerAndListConcurrentOperations,
    removeConcurrentOperation,
    setBAPreferences,
    setOperationFormState, setOperationShiftCountWarningsEnabled,
    updateElementsContext,
} from './operationForm/reducers/operationForm.reducer';
import semver from 'semver';
import { APP_VERSION } from '../../config/version';
import { setServerTime } from '../../shared/reducers/system.reducer';
import moment from 'moment';
import { BarcodeScanEvent } from '../../shared/BarcodeScanEvent';
import { AxiosResponse } from 'axios';
import { showNotification } from '../../components/notification/showNotification';
import keycode from 'keycode';
import { getCurrentRolesAndPermissions } from '../../shared/reducers/permissions.reducer';
import { BlockedCompanyModal } from '../../components/blockedCompanyModal/blockedCompanyModal';
import { LocalStorageUtils } from '../../core/utils/localStorageUtils';
import { canViewFinancialData } from '../../shared/util/permissionUtils';
import { DisabledModule } from './notfound/disabledModule';
import { Modals } from '../../core/modals/modals';
import { showConfirm } from '../../components/confirm/showConfirm';
import { NomenclaturesInfo } from './operationForm/operationModule/nomenclaturesInfo/NomenclaturesInfo';
import { ScanErrorType, showScanErrorNotification } from '../../shared/reducers/scanner.reducer';

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    deviceType: 'phone' | 'tablet' | 'desktop';
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    Invitation: any;
}

interface IState {
    error;
    accept;
    showAboutModal: boolean;
    debug: boolean;
}

class MainModule extends React.PureComponent<IProps, IState> {
    private updateIntervalId;
    private barcodeIntervalId: number | undefined;
    private barcode: string | undefined = '';
    private getCurrentOperationIntervalId;

    constructor(props) {
        super(props);

        let showAboutModal = false; //localStorage.getItem('showAboutModal') !== '0';

        this.state = {
            error: null,
            accept: false,
            showAboutModal: showAboutModal,
            debug: false,
        };

        window.addEventListener('keydown', this.onKeyDown);

        /*
        setInterval(()=>{
            const elements = window.document.getElementsByTagName('body');
            const bodyEl = elements?.[0];
            if(bodyEl && bodyEl.style && bodyEl.style.overflow === 'hidden') {
                let openedModalsCount = 0;
                const divs = bodyEl.getElementsByTagName('div');
                if(divs){
                    for (let i = 0; i < divs.length; i++) {
                        const div = divs[i];
                        const openedDrawers = div.getElementsByClassName('ant-drawer-open');
                        if(openedDrawers.length > 0){
                            openedModalsCount += openedDrawers.length;
                        }
                    }
                }
                if(openedModalsCount === 0){
                    //bodyEl.style.overflow = 'hidden';
                }
            }
        }, 1000);
        */
    }

    onKeyDown = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.metaKey && e.shiftKey && e.code === 'KeyI') {
            // TODO
        } else if (e.ctrlKey && e.metaKey && e.shiftKey && e.code === 'KeyD') {
            // Shift + Control + Command + D
            this.setState({ debug: !this.state.debug });
        }

        // // http://localhost:3000/234/projects/production/26002117?tab=elements
        // if(e.key === '1'){
        //     this.handleBarcode('SSS_1');
        // }else if(e.key === '2'){
        //     this.handleBarcode('SSS_2');
        // }else if(e.key === '3'){
        //     this.handleBarcode('SSS_3');
        // }else if(e.key === '4'){
        //     this.handleBarcode('SSS_C3');
        // }
        // return;

        /*
        if(e.key === '1'){
            // Набор: 0001 re
            this.handleBarcode('KIT1');
        }else if(e.key === '2'){
            // Продукт: 12312412
            this.handleBarcode('PROD1');
        }else if(e.key === '3'){
            // Продукт: 123213 Вариант: 12312312
            this.handleBarcode('VAR1');
        }else if(e.key === '4'){
            // Экземпляр
            //this.handleBarcode('XXX-5934974');
        }else if(e.key === '0'){
            getStore().dispatch(removeAllTimetables());
            // Экземпляр
            //this.handleBarcode('XXX-5934974');
        }
        return;
*/
        /*
        if(e.key === '1'){
            this.handleBarcode('ABC-abc-1232');
            //this.handleBarcode('P0012');
        }else if(e.key === '2'){
            this.handleBarcode('ABC-abc-1233');
            //this.handleBarcode('P0013');
        }else if(e.key === '3'){
            this.handleBarcode('V0009');
        }else if(e.key === '4'){
            this.handleBarcode('XXX-5934974');
        }else if(e.key === '5'){
            this.handleBarcode('XXX666');
        }
        else if(e.key === '9'){
            getStore().dispatch(showScanNomenclatureSuccessNotification());
        }
        else if(e.key === '0'){
            getStore().dispatch(showScanInstanceSuccessNotification());
        }
        return;

        if(e.key === '1'){
            this.handleBarcode('TEST-10001');
        }else if(e.key === '2'){
            this.handleBarcode('TEST-10002');
        }else if(e.key === '3'){
            this.handleBarcode('TEST-10003');
        }else if(e.key === '4'){
            this.handleBarcode('TEST-0004');
        }else if(e.key === '5'){
            this.handleBarcode('TEST-0005');
        }else if(e.key === '8'){
            this.handleBarcode('TTT-008');
        }else if(e.key === '9'){
            this.handleBarcode('TTT-009');
        }
        return;
        console.log('AAAA', e);

        if(e.key === '1'){
            this.handleBarcode('V-505101');
        }else if(e.key === '2'){
            this.handleBarcode('P-505097');
        }else if(e.key === '3'){
            this.handleBarcode('P-7237037');
        }
        return;
        */

        if (this.barcodeIntervalId) clearInterval(this.barcodeIntervalId);

        if (e.code === 'Enter' && this.barcode) {
            this.handleBarcode(this.barcode);
            this.barcode = '';
            return;
        }
        if (e.key !== 'Shift' && e.key !== 'Enter' && e.key !== 'CapsLock' && e.key !== 'Control' && e.key !== 'Alt' && e.key !== 'Meta') {
            if (e.code === 'Minus') {
                if (e.shiftKey === true) this.barcode += '_';
                else this.barcode += '-';
            } else {
                if (keycode(e)) this.barcode += keycode(e).toUpperCase();
            }
        }
        this.barcodeIntervalId = window.setInterval(() => (this.barcode = ''), 60);
    };

    handleBarcode = (code) => {
        if (
            !(
                document.activeElement &&
                document.activeElement.tagName.toLowerCase() === 'input' &&
                document.activeElement.getAttribute('type') === 'text'
            )
        ) {
            barcodeScanEventTarget.dispatch({ code: code });
        }
    };

    getBusinessAccountIndicators = async () => {
        const indicators = await this.props.getBusinessAccountIndicators(this.props.businessAccountId);
        return indicators;
    };

    getBusinessAccount = async () => {
        const ba = await this.props.getBusinessAccount(this.props.businessAccountId);
        return ba;
    };

    getBusinessAccountPreferences = async () => {
        let preferences = await this.props.getBusinessAccountPreferences(this.props.businessAccountId);
        if (preferences instanceof Error === false && (preferences as any).value && (preferences as any).value.data) {
            this.props.setBAPreferences(
                ((preferences as any).value.data as BusinessAccountPreferencesInfoRead).shiftLengthInMinutes,
                ((preferences as any).value.data as BusinessAccountPreferencesInfoRead).shiftCountRoundingType,
                ((preferences as any).value.data as BusinessAccountPreferencesInfoRead).requiredTimeIndentBetweenElementsInMinutes
            );
        }
        return preferences;
    };

    getBusinessAccountContacts = async () => {
        const contacts = await this.props.getBusinessAccountContacts(this.props.businessAccountId);
        return contacts;
    };

    getPermissions = async () => {
        const permissions = await this.props.getCurrentRolesAndPermissions(this.props.businessAccountId);
        return permissions;
    };

    showError = (title: string | React.ReactNode, descripotion: string | React.ReactNode) => {
        return (
            <div style={{ padding: '30px' }}>
                <Alert message={title} description={descripotion} type="error" />
            </div>
        );
    };

    componentDidMount = async () => {
        // Получаем серверное время
        try {
            let serverTime = await serverApi.getTime();
            getStore().dispatch(setServerTime(moment(serverTime.data).valueOf()));
        } catch (e) {
            await this.setState({ error: e });
            hidePreloader();
            return;
        }

        setTimeout(async () => {
            try {
                let serverTime = await serverApi.getTime();
                getStore().dispatch(setServerTime(moment(serverTime.data).valueOf()));
            } catch (error) {}
        }, 30 * 1000);

        // Паша тут мутил что-то
        if (window.location.pathname.slice(0, 18) === '/acceptInvitation/') {
            this.setState({
                accept: true,
            });
            hidePreloader();
        }
        // Есть БА в сторе или нет
        else {
            // 1
            if (!this.props.businessAccountId) {
                let businessAccountId = localStorage.getItem(`${this.props.oidcProfile?.sub}_businessAccountId`)
                    ? Number(localStorage.getItem(`${this.props.oidcProfile?.sub}_businessAccountId`))
                    : undefined;
                // Есть БАИД в памяти
                if (businessAccountId) {
                    // Получаем список БА
                    const list = await serverApi.listBusinessAccounts(1000);
                    if (list && list.data && list.data.records && list.data.records.length > 0) {
                        let ba = list.data.records.find((record) => record.id === businessAccountId);
                        if (ba) {
                            window.location.href = `/${ba.id}${window.location.pathname}${window.location.search}`;
                        } else {
                            ba = list.data.records[0];
                            window.location.href = `/${ba.id}${window.location.pathname}${window.location.search}`;
                        }
                    } else {
                        // Тут или список пустой или что-то пошло не так
                        this.props.push('/companyRegistration');
                    }
                } else {
                    try {
                        const list = await serverApi.listBusinessAccounts(1);
                        if (list && list.data && list.data.records && list.data.records.length > 0) {
                            // Берем id  первого БА и пергружаем страницу
                            const baId = list.data.records[0].id;
                            window.location.href = `/${baId}${window.location.pathname}${window.location.search}`;
                        } else {
                            // Тут или список пустой или что-то пошло не так
                            this.props.push('/companyRegistration');
                        }
                    } catch (e: any) {
                        if (e && e.response && e.response.status === 403) rrLogout();
                        else {
                            //
                            await this.setState({ error: e });
                            hidePreloader();
                        }
                    }
                }
            }
            // 2
            else {
                // Получение пользовательских настроек из локалСтора, и перенос старых настроек, если есть и запись в стор
                {
                    const key = LocalStorageUtils.getKey('user-settings', this.props.businessAccountId, this.props.oidcProfile?.sub);
                    let settings: UserSettingsState | undefined;
                    try {
                        settings = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '') : undefined;
                    } catch (e) {}
                    if (settings){
                        getStore().dispatch(setSettings(settings));
                        getStore().dispatch(setOperationShiftCountWarningsEnabled(settings.operationShiftCountWarningsEnabled));
                    }
                }

                // Есть БА, надо получить данные БА
                const res = await this.getBusinessAccount();

                const res1 = await this.getPermissions();

                if (res instanceof Error === false && res1 instanceof Error === false) {
                    const baIndicators = await this.getBusinessAccountIndicators();
                    if (baIndicators instanceof Error === true) this.setState({ error: baIndicators });

                    const baPref = await this.getBusinessAccountPreferences();
                    if (baPref instanceof Error === true) this.setState({ error: baPref });
                    const baContacts = await this.getBusinessAccountContacts();
                    if (baContacts instanceof Error === true) this.setState({ error: baContacts });
                    this.updateIntervalId = setInterval(this.updateBAData, UPDATE_DATA_INTERVAL_MSEC);
                    localStorage.setItem(`${this.props.oidcProfile?.sub}_businessAccountId`, '' + this.props.businessAccountId);
                }

                if (isOperationInLS()) this.showRestoreOperationNotification();

                hidePreloader();
            }
        }

        barcodeScanEventTarget.addListener(this.onBarcodeScanEvent);
    };

    showRestoreOperationNotification = () => {
        showConfirm(this.props.intl, 'Восстановить сохраненную операцию?').then((yes) => {
            if (yes) {
                try {
                    const state = getOperationFromLS();
                    if (state) {
                        getStore().dispatch(setOperationFormState(state));
                        getStore().dispatch(updateElementsContext());
                        showNotification('success', 'Операция восстановлена');
                    }
                } catch (error) {
                    showNotification('error', 'Не удалось восстановить операцию');
                    console.error(error);
                }
            } else {
                removeOperationFromLS();
            }
        });
    };

    updateBAData = async () => {
        if (!document.hidden) {
            let state: IRootState = getStoreState();

            // Настройки БА
            if (
                !state.businessAccountPreferences.loadingLastTime ||
                (state.businessAccountPreferences.loadingLastTime &&
                    Date.now() >
                        state.businessAccountPreferences.loadingLastTime.getTime() + GET_BUSINESS_ACCOUNT_PREFERENCES_INTERVAL_MSEC)
            ) {
                this.getBusinessAccountPreferences();
            }

            // Контакты БА
            if (
                !state.businessAccountContacts.loadingLastTime ||
                (state.businessAccountContacts.loadingLastTime &&
                    Date.now() > state.businessAccountContacts.loadingLastTime.getTime() + GET_BUSINESS_ACCOUNT_CONTACTS_INTERVAL_MSEC)
            ) {
                this.getBusinessAccountContacts();
            }

            // Индикаторы БА
            if (
                !state.businessAccountIndicators.loadingLastTime ||
                (state.businessAccountIndicators.loadingLastTime &&
                    Date.now() > state.businessAccountIndicators.loadingLastTime.getTime() + GET_BUSINESS_ACCOUNT_INDICATORS_INTERVAL_MSEC)
            ) {
                this.getBusinessAccountIndicators();
            }

            // Сам БА
            if (
                !state.businessAccount.loadingLastTime ||
                (state.businessAccount.loadingLastTime &&
                    Date.now() > state.businessAccount.loadingLastTime.getTime() + GET_BUSINESS_ACCOUNT_INFO_INTERVAL_MSEC)
            ) {
                this.getBusinessAccount();
            }

            if (
                !state.permissions.loadingLastTime ||
                (state.permissions.loadingLastTime &&
                    Date.now() > state.permissions.loadingLastTime.getTime() + GET_PERMISSIONS_INTERVAL_MSEC)
            ) {
                this.getPermissions();
            }
        }
    };

    componentWillUnmount = () => {
        clearInterval(this.updateIntervalId);
        barcodeScanEventTarget.removeListener(this.onBarcodeScanEvent);
        clearInterval(this.getCurrentOperationIntervalId);
        window.removeEventListener('keydown', this.onKeyDown);
    };

    onBarcodeScanEvent = async (e: CustomEvent<BarcodeScanEvent>) => {
        if (/\/\d+\/inventory\//.test(window.location.pathname)) {
            const code = e.detail.code;
            let res: AxiosResponse<BarcodeEntityObj> | undefined;
            try {
                res = await serverApi.getEntityByBarcode(this.props.businessAccountId, code);
            } catch (e: any) {
                if (e?.response?.status === 404) {
                    //showBarcodeScannerNotification('error', 'Штриховой код не найден в системе', undefined, null);
                    getStore().dispatch(
                        showScanErrorNotification({
                            type: ScanErrorType.CodeNotFound,
                            code,
                        })
                    );
                } else {
                    //showBarcodeScannerNotification('error', 'Не удалось загрузить данные', undefined, null);
                    getStore().dispatch(
                        showScanErrorNotification({
                            type: ScanErrorType.LoadingError,
                        })
                    );
                }
            }
            if (res?.data) {
                if (res.data.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.INSTANCE) {
                    //this.props.push(`/${this.props.businessAccountId}/inventory/products/${res.data.parentId}?tab=instances&search=${code}`);
                    this.props.push(
                        `/${this.props.businessAccountId}/inventory/products/${res.data.parentId}?tab=description&instanceId=${res.data.id}`
                    );
                } else if (res.data.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.KIT) {
                    this.props.push(`/${this.props.businessAccountId}/inventory/kits/${res.data.id}?tab=description`);
                } else if (res.data.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.PRODUCT) {
                    this.props.push(`/${this.props.businessAccountId}/inventory/products/${res.data.id}`);
                } else if (res.data.entityTypeCode === BarcodeEntityObjEntityTypeCodeEnum.VARIANT) {
                    //this.props.push(`/${this.props.businessAccountId}/inventory/products/${res.data.parentId}?tab=variants&search=${code}`);
                    this.props.push(
                        `/${this.props.businessAccountId}/inventory/products/${res.data.parentId}?tab=description&variantId=${res.data.id}`
                    );
                }
            }
        }
    };

    componentDidUpdate = (prevProps: IProps) => {
        if (prevProps.businessAccountId !== this.props.businessAccountId) {
            document.location.reload();
        }
        if (prevProps.currentOperationUUID !== this.props.currentOperationUUID && prevProps.currentOperationUUID) {
            clearInterval(this.getCurrentOperationIntervalId);
            try {
                this.props.removeConcurrentOperation(prevProps.currentOperationUUID);
            } catch (e) {}
        }

        if (
            prevProps.operationStartTime?.valueOf() !== this.props.operationStartTime?.valueOf() &&
            this.props.operationStartTime?.valueOf()
        ) {
            // Новая операция
            this.getCurrentOperation();
            this.getCurrentOperationIntervalId = setInterval(this.getCurrentOperation, GET_CONCURRENT_OPERATIONS_INTERVAL_MSEC);
        }
    };

    getCurrentOperation = () => {
        const {
            activityFrameId,
            activityFrame,
            operationTypeCode,
            operationCorrectionStateCode,
            operationStartTime,
            currentOperationUUID,
        } = this.props;

        if (operationStartTime && activityFrameId && operationTypeCode) {
            this.props.registerAndListConcurrentOperations({
                uuid: currentOperationUUID,
                activityFrame: {
                    id: activityFrameId,
                    type: activityFrame,
                },
                operationType: operationTypeCode,
                targetStateCode: operationCorrectionStateCode,
                progressStartedDate: operationStartTime, //.toDate(),
            });
        }
    };

    onCollapse = (collapsed, type) => {
        this.props.setSidebarCollapsed(collapsed);
    };

    render() {
        //console.log('Main1Module render()', this.props.deviceType, this.props);

        const {
            deviceType,
            responsive,
            sidebarCollapsed,
            userPermissions,
            businessAccountLoadingError,
            businessAccountPreferences,
            intl,
            subrentModuleEnabled,
            statisticsModuleEnabled,
            logisticsModuleEnabled,
            crewModuleEnabled,
            expensesModuleEnabled,
            offersModuleEnabled,
        } = this.props;
        let menuWidth = 0;

        if (deviceType === 'desktop') {
            menuWidth = sidebarCollapsed ? MENU_COLLAPSED_WIDTH : MENU_WIDTH;
        } else if (deviceType === 'tablet' || deviceType === 'phone') {
            menuWidth = sidebarCollapsed ? MENU_COLLAPSED_WIDTH : 80;
        }

        let menuData = appRoutes.filter(
            (item) =>
                item.inMenu === true &&
                item.path &&
                (!item.permissions ||
                    item.permissions.length === 0 ||
                    (item.permissions && item.permissions.length > 0 && intersection(item.permissions, userPermissions).length > 0))
        );
        menuData.sort((item1, item2) => item1.sortOrder - item2.sortOrder);

        const enabledModules: BusinessAccountModuleCodeEnum[] = [];
        if (logisticsModuleEnabled) enabledModules.push(BusinessAccountModuleCodeEnum.LOGISTICS);
        if (crewModuleEnabled) enabledModules.push(BusinessAccountModuleCodeEnum.CREW);
        if (subrentModuleEnabled) enabledModules.push(BusinessAccountModuleCodeEnum.SUBRENT);
        if (statisticsModuleEnabled) enabledModules.push(BusinessAccountModuleCodeEnum.STATISTICS);
        if (expensesModuleEnabled) enabledModules.push(BusinessAccountModuleCodeEnum.EXPENSES);
        if (offersModuleEnabled) enabledModules.push(BusinessAccountModuleCodeEnum.OFFERS);

        menuData = menuData.filter((item) => {
            if (item.moduleCode && !enabledModules.includes(item.moduleCode)) return false;
            return true;
        });

        const routes = appRoutes.map((item) => {
            return {
                ...item,
                component: item.moduleCode && !enabledModules.includes(item.moduleCode) ? DisabledModule : item.component,
            };
        });

        let error = businessAccountLoadingError || this.state.error;
        let errObj = error ? getServerError(error) : undefined;

        if (this.state.accept) {
            return <Invitation />;
        }
        const withNewVersionMessage: boolean = Boolean(this.props.appVersion && semver.gt(this.props.appVersion, APP_VERSION));

        return businessAccountPreferences ? (
            <CurrencyContext.Provider value={businessAccountPreferences.baseCurrency}>
                {withNewVersionMessage ? (
                    <div className={'rr-new-app-version-block'}>
                        Доступна новая версия!{' '}
                        <span
                            className={'rr-new-app-version-block__link'}
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Обновите страницу
                        </span>
                    </div>
                ) : null}
                <Layout className={'rr-basicLayout'} style={{ paddingTop: withNewVersionMessage ? 25 : 0 }}>
                    <SidebarWrapper
                        withTopMessage={withNewVersionMessage}
                        deviceType={deviceType}
                        width={menuWidth}
                        collapsed={sidebarCollapsed}
                        menuData={menuData}
                        onCollapse={this.onCollapse}
                    />
                    <Layout style={{ marginLeft: menuWidth, minHeight: '100vh' }}>
                        <Content>{renderRoutes(routes, { intl, userPermissions, deviceType, responsive })}</Content>
                        <Footer />
                    </Layout>
                </Layout>

                {!this.props.userPermissions.includes(GlobalPermissionCodeEnum.VIEWBUSINESSACCOUNT) &&
                    this.props.businessAccount &&
                    this.props.businessAccount.stateCode === BusinessAccountStateCodeEnum.BLOCKED && (
                        <BlockedCompanyModal companyName={this.props.businessAccountShortName} companyId={this.props.businessAccountId} />
                    )}

                {/*
                    this.state.showAboutModal &&
                    <AboutServiceModal onClose={()=>{
                        localStorage.setItem('showAboutModal', '0');
                        this.setState({
                            showAboutModal: false
                        });
                    }} />
                */}

                <Modals />

                {this.state.debug && this.props.mnemoKey && <NomenclaturesInfo />}
            </CurrencyContext.Provider>
        ) : errObj ? (
            this.showError(errObj.title, errObj.message)
        ) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => {
    const activityFrameId: number | undefined = storeState.operationForm.projectId;
    const activityFrame: RentActivityFrameTypeCodeEnum = storeState.operationForm.isSubrent
        ? RentActivityFrameTypeCodeEnum.SUBRENT
        : storeState.operationForm.projectTemplate
        ? RentActivityFrameTypeCodeEnum.TEMPLATE
        : RentActivityFrameTypeCodeEnum.PROJECT;
    return {
        sidebarCollapsed: storeState.userSettings.sidebarCollapsed,
        businessAccountId: storeState.system.businessAccountId,
        userPermissions: storeState.permissions.permissions,
        businessAccount: storeState.businessAccount.entity,
        businessAccountLoadingError: storeState.businessAccount.loadingError,
        businessAccountPreferences: storeState.businessAccountPreferences.preferences,
        appVersion: storeState.system.appVersion,
        oidcProfile: storeState.oidc.user?.profile,
        businessAccountShortName: storeState.businessAccountContacts.entity?.shortName,
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        operationStartTime: storeState.operationForm.operationStartTime,
        activityFrameId: activityFrameId,
        activityFrame: activityFrame,
        operationTypeCode: storeState.operationForm.typeCode,
        operationCorrectionStateCode: storeState.operationForm.targetStateCode,
        mnemoKey: storeState.operationForm.mnemoKey,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        logisticsModuleEnabled: logisticsModuleEnabledSelector(storeState),
        statisticsModuleEnabled: statisticsModuleEnabledSelector(storeState),
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
        crewModuleEnabled: crewModuleEnabledSelector(storeState),
        expensesModuleEnabled: expensesModuleEnabledSelector(storeState),
        offersModuleEnabled: offersModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = {
    setSidebarCollapsed,
    getBusinessAccountIndicators,
    setBAPreferences,
    getBusinessAccount,
    getBusinessAccountPreferences,
    getBusinessAccountContacts,
    getCurrentRolesAndPermissions,
    push,
    removeConcurrentOperation,
    registerAndListConcurrentOperations,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )((props: any) => (
        <Media
            queries={{
                phone: '(max-width: 992px)',
                tablet: '(min-width: 993px) and (max-width: 1516px)',
                desktop: '(min-width: 1517px)',

                xs: '(max-width: 575px)',
                sm: '(min-width: 576px) and (max-width: 767px)',
                md: '(min-width: 768px) and (max-width: 991px)',
                lg: '(min-width: 992px) and (max-width: 1199px)',
                xl: '(min-width: 1200px) and (max-width: 1599px)',
                xxl: '(min-width: 1600px)',
            }}
        >
            {(matches) => {
                let responsive = 'xs';
                if (matches.sm) responsive = 'sm';
                else if (matches.md) responsive = 'md';
                else if (matches.lg) responsive = 'lg';
                else if (matches.xl) responsive = 'xl';
                else if (matches.xxl) responsive = 'xxl';

                let deviceType = matches.phone ? 'phone' : matches.tablet ? 'tablet' : 'desktop';

                return <MainModule {...props} responsive={responsive} deviceType={deviceType} />;
            }}
        </Media>
    ))
);
