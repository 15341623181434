import React from 'react';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { Spin } from '../../../../../../components';
import { Card, Icon } from 'antd';
import { Grid } from '../../../../../../components/grid/Grid';
import {
    getCounterpartiesListFilters,
    initialParamsState,
    loadEntities,
    RentersPageParams,
    reset,
    setArchiveState,
    setStatus,
} from '../../../reducers/renters/renters.reducer';
import counterpartyColumns from './CounterpartiesColumns';
import { IRootState } from '../../../../../../shared/reducers';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { counterpartiesFilters } from './CounterpartiesFilters';
import { PageUtils } from '../../../../../../shared/util/pageUtils';
import {
    convertAvailableFiltersToObj,
    findCategoryById,
    getPathFromState,
    getStateFromPath,
    getStateFromPath2, isDefined,
    roundMaxFilterValue,
    roundMinFilterValue,
} from '../../../../../../shared/util/utils';
import { loadCategories } from '../../../../../../shared/reducers/entities.reducer';
import {
    getGridCategoriesStorageData,
    getGridStorageData,
    setGridCategoriesStorageData,
    setGridStorageDataFilters,
} from '../../../../../../components/grid/utils';
import { push, replace } from 'connected-react-router';
import { logEvent } from '../../../../../../analytics/analytics';
import {
    CategorisedEntityCodeEnum,
    CategoryTypeCodeEnum,
    ExcelExportTemplateTypeCodeEnum,
    RenterFinancialFieldsTypeCode,
    RenterNotFinancialFieldsTypeCode,
    RenterRecord,
    RenterStateCodeEnum,
    serverApi,
    SimpleTransitionCodeEnum,
} from '../../../../../../server';
import every from 'lodash/every';
import RoundButton from '../../../../../../components/button/roundButton';
import { IconArchive, IconCheck, IconLevelUp, IconUnlock, IconUnlockAlt } from '../../../../../../components/icons';
import { canViewFinancialData } from '../../../../../../shared/util/permissionUtils';
import moment from 'moment/moment';
import { IntlShape } from 'react-intl';
import debounce from 'lodash/debounce';
import { resetOperationForm } from '../../../../operationForm/reducers/operationForm.reducer';
import { EntityActionType } from '../../../../../../../index';
import { AdditionalEntityActionForCategories } from '../../../../../../components/additionalEntityActions/AdditionalEntityActionForCategories/AdditionalEntityActionForCategories';
import { GridCategoriesTree } from '../../../../../../components/categories/GridCategoriesTree/GridCategoriesTree';
import { saveCurrentTabToStore } from '../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { subrentModuleEnabledSelector } from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { ProblemEnum } from '../../../../../../types';
import { GridName } from '../../../../../../components/grid/utils/types';
import { AdditionalEntityActionsPopoverContainer } from '../../../../../../components/additionalEntityActions/AdditionalEntityActionsPopoverContainer/AdditionalEntityActionsPopoverContainer';
import { IFormField } from '../../../../../../components/dynamicForm/DynamicForm';
import { downloadOffloading } from '../../../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import { CounterpartyPageTabsEnum } from '../../../../../../shared/constants/tabEnums';
import { ExportOffloadingPopover } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import { OffloadingDocumentOnDownload } from '../../../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';

interface IProps extends StateProps, DispatchProps {
    gridName: GridName;
    intl: IntlShape;
}

interface IState {
    selection: number[];
}

export class _CounterpartiesList extends React.PureComponent<IProps, IState> {
    private grid;
    private filtersForm;
    private fromFilters: boolean = false;
    private initialValues;
    private availableFilters;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = [
        'search',
        'status',
        'typeCode',
        'country',
        'categoryIds',
        'delay',
        'shortage',
        'hideArchive',
        'lastActivityDate',
        'activityTypeCode',
        'sourceCode',
        'sortBy',
        'sortOrder',
        'debtOfRenter',
        'debtToSupplier',
    ];

    constructor(props: IProps) {
        super(props);
        this.state = {
            selection: [],
        };
        this.updateFilters = debounce(this.updateFilters, 300);
        this.props.resetOperationForm();
    }

    getSubrentParams = () => {
        let { problem } = this.props.pageParams;
        if (!this.props.subrentModuleEnabled) {
            if (problem) {
                if (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY || problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY)
                    problem = undefined;
            }
        }
        return { problem };
    };

    async componentDidMount() {
        this.initialValues = { ...this.props.pageParams, ...this.getSubrentParams() };
        await this.props.loadCategories(this.props.businessAccountId, CategoryTypeCodeEnum.RENTER);
        const filtersChanged = await this.getActualFilters();

        if(!filtersChanged){
            PageUtils.setPageParamsOrLoadData(
                getStateFromPath2(this.props.history.location.search),
                this.filters,
                () => {
                    this.setSavedParams(this.props.history.location.search);
                },
                this.loadDataAndSetFilterValues
            );

            this.forceUpdate();
        }
    }

    componentDidUpdate = (prevProps: IProps) => {
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );
    };

    getActualFilters = async () => {
        const { pageParams } = this.props;
        let needsToChangeParams = false;
        try {
            const { data: availableFiltersValues } = await serverApi.listRentersAvailableFiltersValues(this.props.businessAccountId);

            this.availableFilters = convertAvailableFiltersToObj(availableFiltersValues.filters);

            const debtOfRenterFilter = this.availableFilters.debtOfRenter;
            let debtOfRenterFilterMin:number|undefined;
            let debtOfRenterFilterMax:number|undefined;
            if (debtOfRenterFilter) {
                let field = (counterpartiesFilters[1].fields as IFormField[]).find((item) => item.id === 'debtOfRenter');
                if (field) {
                    debtOfRenterFilterMin = roundMinFilterValue(debtOfRenterFilter[0] / 100);
                    debtOfRenterFilterMax = roundMaxFilterValue(debtOfRenterFilter[1] / 100);

                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'currency',
                        min: debtOfRenterFilterMin,
                        max: debtOfRenterFilterMax,
                    };
                    if (!this.props.pageParams.debtOfRenter) {
                        this.filtersForm?.setFieldsValue({
                            debtOfRenter: [debtOfRenterFilterMin, debtOfRenterFilterMax],
                        });
                    }
                }
            }

            const debtToSupplierFilter = this.availableFilters.debtToSupplier;
            let debtToSupplierFilterMin:number|undefined;
            let debtToSupplierFilterMax:number|undefined;
            if (debtToSupplierFilter) {
                let field = (counterpartiesFilters[1].fields as IFormField[]).find((item) => item.id === 'debtToSupplier');
                if (field) {
                    debtToSupplierFilterMin = roundMinFilterValue(debtToSupplierFilter[0] / 100);
                    debtToSupplierFilterMax = roundMaxFilterValue(debtToSupplierFilter[1] / 100);

                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'currency',
                        min: debtToSupplierFilterMin,
                        max: debtToSupplierFilterMax,
                    };
                    if (!this.props.pageParams.debtToSupplier) {
                        this.filtersForm?.setFieldsValue({
                            debtToSupplier: [debtToSupplierFilterMin, debtToSupplierFilterMax],
                        });
                    }
                }
            }

            // http://localhost:3000/30248271/crm/counterparties?debtOfRenter=448952587853495%2C9007199254740991&sortBy=debtOfRenter&sortOrder=DESC&debtToSupplier=0%2C9007199254740991

            if(pageParams.debtOfRenter || pageParams.debtToSupplier){
                const params:Partial<RentersPageParams> = {};
                if(pageParams.debtOfRenter && isDefined(debtOfRenterFilterMin) && isDefined(debtOfRenterFilterMax)){
                    let toUpdate = false;
                    let debtSumMin = pageParams.debtOfRenter[0] || Number.MIN_SAFE_INTEGER;
                    let debtSumMax = pageParams.debtOfRenter[1] || Number.MAX_SAFE_INTEGER;
                    if(debtSumMax > debtOfRenterFilterMax || debtSumMax < debtOfRenterFilterMin){
                        needsToChangeParams = true;
                        toUpdate = true;
                        debtSumMax = debtOfRenterFilterMax;
                    }
                    if(debtSumMin < debtOfRenterFilterMin || debtSumMin > debtOfRenterFilterMax){
                        needsToChangeParams = true;
                        toUpdate = true;
                        debtSumMin = debtOfRenterFilterMin;
                    }
                    if(toUpdate) params.debtOfRenter = (debtSumMin === debtOfRenterFilterMin && debtSumMax === debtOfRenterFilterMax) ? undefined : [debtSumMin, debtSumMax];
                }

                if(pageParams.debtToSupplier && isDefined(debtToSupplierFilterMin) && isDefined(debtToSupplierFilterMax)){
                    let toUpdate = false;
                    let debtSumMin = pageParams.debtToSupplier[0] || Number.MIN_SAFE_INTEGER;
                    let debtSumMax = pageParams.debtToSupplier[1] || Number.MAX_SAFE_INTEGER;
                    if(debtSumMax > debtToSupplierFilterMax || debtSumMax < debtToSupplierFilterMin){
                        needsToChangeParams = true;
                        toUpdate = true;
                        debtSumMax = debtToSupplierFilterMax;
                    }
                    if(debtSumMin < debtToSupplierFilterMin || debtSumMin > debtToSupplierFilterMax){
                        needsToChangeParams = true;
                        toUpdate = true;
                        debtSumMin = debtToSupplierFilterMin;
                    }
                    if(toUpdate) params.debtToSupplier = (debtSumMin === debtToSupplierFilterMin && debtSumMax === debtToSupplierFilterMax) ? undefined :[debtSumMin, debtSumMax];
                }

                if(needsToChangeParams){
                    console.log('PPP', params);
                    const path = getPathFromState(this.props.history.location.pathname, this.props.history.location.search, params);
                    this.props.replace(path);
                }

                // if(needsToChangeParams){
                //     const path = getPathFromState(this.props.location.pathname, this.props.location.search, {debtSum: [debtSumMin, debtSumMax]});
                //     this.props.replace(path);
                // }
            }

            // if(this.props.pageParams.debtOfRenter || this.props.pageParams.debtToSupplier){
            //     const newParams:any = {};
            //     let needToReplace = false;
            //     if(this.props.pageParams.debtOfRenter && isDefined(debtOfRenterFilterMin) && isDefined(debtOfRenterFilterMax)){
            //         const debt = this.props.pageParams.debtOfRenter;
            //         if(isDefined(debt[0]) && debt[0] < debtOfRenterFilterMin){
            //             debt[0] = debtOfRenterFilterMin;
            //             needToReplace = true;
            //         }
            //         if(isDefined(debt[1]) && debt[1] > debtOfRenterFilterMax){
            //             debt[1] = debtOfRenterFilterMax;
            //             needToReplace = true;
            //         }
            //         newParams.debtOfRenter = debt;
            //     }
            //
            //     if(this.props.pageParams.debtToSupplier && isDefined(debtToSupplierFilterMin) && isDefined(debtToSupplierFilterMax)){
            //         const debt = this.props.pageParams.debtToSupplier;
            //         if(isDefined(debt[0]) && debt[0] < debtToSupplierFilterMin){
            //             debt[0] = debtToSupplierFilterMin;
            //             needToReplace = true;
            //         }
            //         if(isDefined(debt[1]) && debt[1] > debtToSupplierFilterMax){
            //             debt[1] = debtToSupplierFilterMax;
            //             needToReplace = true;
            //         }
            //         newParams.debtToSupplier = debt;
            //     }
            //
            //     if(needToReplace){
            //         replace(getPathFromState(this.props.history.location.pathname, this.props.history.location.search, newParams));
            //         return true;
            //     }
            // }
        } catch (e) {
            console.error(e);
        }
        return needsToChangeParams;
    };

    loadDataAndSetFilterValues = () => {
        this.loadRenters();
        if (!this.fromFilters && this.filtersForm) {
            const values = { ...initialParamsState, ...this.props.pageParams };
            if (!values.debtOfRenter && this.availableFilters) {
                if (this.availableFilters.debtOfRenter) {
                    values.debtOfRenter = [
                        roundMinFilterValue(this.availableFilters.debtOfRenter[0] / 100),
                        roundMaxFilterValue(this.availableFilters.debtOfRenter[1] / 100),
                    ];
                }
            }
            if (!values.debtToSupplier && this.availableFilters) {
                if (this.availableFilters.debtToSupplier) {
                    values.debtToSupplier = [
                        roundMinFilterValue(this.availableFilters.debtToSupplier[0] / 100),
                        roundMaxFilterValue(this.availableFilters.debtToSupplier[1] / 100),
                    ];
                }
            }

            this.filtersForm.setFieldsValue(values);
        }
        this.fromFilters = false;
    };

    setSavedParams = (search: string) => {
        const { gridName, categories, replace, history } = this.props;

        let gridData = getGridStorageData(gridName);

        let gridCategoriesData = getGridCategoriesStorageData(gridName);

        let selectedCategories: string[] | undefined;

        // Если есть сохраненные категории и она была удалена, то не ставим ее в урл и удаляем из памяти
        if (gridCategoriesData.selectedIds && categories) {
            let deletedCategories = false;
            gridCategoriesData.selectedIds.forEach((id) => {
                if (categories) {
                    if (findCategoryById(categories, +id)) {
                        if (!selectedCategories) selectedCategories = [];
                        selectedCategories.push(id);
                    } else deletedCategories = true;
                }
            });
            if (deletedCategories) {
                setGridCategoriesStorageData(gridName, { selectedIds: selectedCategories });
            }
        }

        this.updatingAfterSetFromStorage = true;

        replace(
            getPathFromState(history.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
                categoryIds: selectedCategories ? selectedCategories.join(';') : undefined,
            })
        );
    };

    loadRenters = async () => {
        await this.props.loadEntities(this.props.intl, this.props.businessAccountId);

        let { pageParams, filteredCount } = this.props;
        logEvent({
            type: 'list renters',
            data: {
                'num filtered': filteredCount,
                'num per page': pageParams.limit,
                'num of the page': pageParams.page,
                'filter delay': pageParams.delay,
                'filter shortage': pageParams.shortage,
                'filter status': pageParams.status,
                'filter search': pageParams.search,
                'filter archive': !pageParams.hideArchive,
                'filter last activity date from': pageParams.lastActivityDate ? pageParams.lastActivityDate[0] : undefined,
                'filter last activity date to': pageParams.lastActivityDate ? pageParams.lastActivityDate[1] : undefined,
            },
        });
    };

    updateFilters = (data) => {
        if (data.debtOfRenter) {
            if (
                data.debtOfRenter.toString() ===
                [
                    roundMinFilterValue(this.availableFilters.debtOfRenter[0] / 100),
                    roundMaxFilterValue(this.availableFilters.debtOfRenter[1] / 100),
                ].toString()
            ) {
                data.debtOfRenter = undefined;
            }
        }

        if (data.debtToSupplier) {
            if (
                data.debtToSupplier.toString() ===
                [
                    roundMinFilterValue(this.availableFilters.debtToSupplier[0] / 100),
                    roundMaxFilterValue(this.availableFilters.debtToSupplier[1] / 100),
                ].toString()
            ) {
                data.debtToSupplier = undefined;
            }
        }

        if (data.lastActivityDate) {
            const lastActivityDate = data.lastActivityDate.map((item) => (item ? moment(item).valueOf() : ''));
            if (!lastActivityDate[0] && !lastActivityDate[1]) data.lastActivityDate = undefined;
            else if (!lastActivityDate[1]) data.lastActivityDate = [lastActivityDate[0]];
            else data.lastActivityDate = lastActivityDate;
        }
        if (data.hideArchive === false) data.hideArchive = undefined;
        data.page = undefined;
        this.fromFilters = true;
        this.props.replace(getPathFromState(this.props.history.location.pathname, this.props.history.location.search, data));
        setGridStorageDataFilters(this.props.gridName, data);
        this.clearSelection();
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    onFiltersChange = (data) => {
        this.updateFilters(data);
    };

    resetFilters = () => {
        let data: RentersPageParams = {
            search: undefined,
            status: undefined,
            typeCode: undefined,
            hideArchive: undefined,
            shortage: undefined,
            delay: undefined,
            problem: undefined,
            categoryIds: undefined,
            lastActivityDate: undefined,
            page: undefined,
            activityTypeCode: undefined,
            debtOfRenter: undefined,
            debtToSupplier: undefined,
            sourceCode: undefined,
            country: undefined,
        };
        this.updatingAfterSetFromStorage = true;
        this.props.replace(getPathFromState(this.props.history.location.pathname, this.props.history.location.search, data));
        setGridStorageDataFilters(this.props.gridName, data);
        setGridCategoriesStorageData(this.props.gridName, { selectedIds: undefined });
    };

    onCategoriesTreeChanged = async (selectedKeys?: string[]) => {
        const { locationPathname, locationSearchParams, push } = this.props;

        this.clearSelection();
        push(
            getPathFromState(locationPathname, locationSearchParams, {
                categoryIds: (selectedKeys ?? []).join(';'),
                page: 1,
            })
        );
    };

    onItemAction = (item: RenterRecord, action: EntityActionType) => {
        const { businessAccountId, intl, push, setArchiveState, setStatus } = this.props;

        const itemUrl = `/${businessAccountId}/crm/counterparties/${item.id}`;
        const callback = (
            {
                edit: () => {
                    saveCurrentTabToStore('counterparty', CounterpartyPageTabsEnum.DESCRIPTION);
                    push(`${itemUrl}`);
                    setTimeout(() => {
                        push(`${itemUrl}/edit?tab=description`);
                    });
                },
                archive: () => {
                    setArchiveState(intl, businessAccountId, [
                        {
                            id: item.id,
                            businessVersion: item.businessVersion,
                            archive: !item.archive,
                        },
                    ]);
                },
                copy: () => {
                    saveCurrentTabToStore('counterparty', CounterpartyPageTabsEnum.DESCRIPTION);
                    push(`${itemUrl}`);
                    setTimeout(() => {
                        push(`${itemUrl}/copy`);
                    });
                },
                lock: () => {
                    const transitionCode = item.stateCode === 'NEW' || item.stateCode === 'BLOCKED' ? 'ACTIVATE' : 'BLOCK';

                    setStatus(intl, businessAccountId, [
                        {
                            id: item.id,
                            businessVersion: item.businessVersion,
                            transitionCode,
                        },
                    ]);
                },
                createProject: () => {
                    saveCurrentTabToStore('counterparty', CounterpartyPageTabsEnum.PROJECTS);
                    push(`${itemUrl}/new}`);
                    setTimeout(() => {
                        push(`${itemUrl}/newProject`);
                    });
                },
                createSupply: () => {
                    saveCurrentTabToStore('counterparty', CounterpartyPageTabsEnum.SHIPPINGS);
                    push(`${itemUrl}/new$`);
                    setTimeout(() => {
                        push(`${itemUrl}/newShipping`);
                    });
                },
            } satisfies Partial<Record<EntityActionType, Function>>
        )[action];

        if (callback) callback();
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.history.location.pathname, this.props.history.location.search, {
                sortBy: id,
                sortOrder: desc ? 'DESC' : 'ASC',
            })
        );
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.history.location.pathname, this.props.history.location.search, { page: page }));
        this.clearSelection();
    };

    _onPageSizeChanged = (size) => {
        this.props.push(
            getPathFromState(this.props.history.location.pathname, this.props.history.location.search, {
                limit: size,
                page: undefined,
            })
        );
        this.clearSelection();
    };

    _onSelectionChanged = (data) => {
        this.setState({ selection: data });
    };

    onChangeStateButtonClick = () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => {
                    return {
                        id: item.id,
                        businessVersion: item.businessVersion,
                        transitionCode: item.stateCode === 'ACTIVE' ? 'BLOCK' : 'ACTIVATE',
                    };
                });
            this.props.setStatus(this.props.intl, this.props.businessAccountId, selectedItems);
            this.clearSelection();
        }
    };

    getActionButtons = () => {
        const { entities } = this.props;
        const { selection } = this.state;

        if (!entities) return [];

        const selectedItems = entities.filter((item) => selection.indexOf(item.id) > -1);

        return [
            every(selectedItems, (item) => {
                return (
                    item.stateCode === RenterStateCodeEnum.NEW && item.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE)
                );
            }) ? (
                <RoundButton
                    key={0}
                    title={LocalizationEnum.ASPECT__ACTION__ACTIVATE}
                    colorScheme={'success'}
                    onClick={this.onChangeStateButtonClick /*this.onActivateButtonClick*/}
                >
                    <Icon component={IconCheck} />
                    {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE, 'span')}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.BLOCK)) ? (
                <RoundButton
                    key={1}
                    title={LocalizationEnum.ASPECT__ACTION__BLOCK}
                    colorScheme={'blocked'}
                    onClick={this.onChangeStateButtonClick /*this.onLockButtonClick*/}
                >
                    <Icon component={IconUnlock} />
                    {localize(LocalizationEnum.ASPECT__ACTION__BLOCK, 'span')}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => {
                return (
                    item.stateCode === RenterStateCodeEnum.BLOCKED &&
                    item.availableTransitionCodes?.includes(SimpleTransitionCodeEnum.ACTIVATE)
                );
            }) ? (
                <RoundButton
                    key={2}
                    title={LocalizationEnum.ASPECT__ACTION__UNLOCK}
                    colorScheme={'success'}
                    onClick={this.onChangeStateButtonClick /*this.onUnlockButtonClick*/}
                >
                    <Icon component={IconUnlockAlt} />
                    {localize(LocalizationEnum.ASPECT__ACTION__UNLOCK, 'span')}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => !item.archive && item.archivable) ? (
                <RoundButton
                    key={3}
                    title={LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'TO_ARCHIVE'}
                >
                    <Icon component={IconArchive} />
                    {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.archive) ? (
                <RoundButton
                    key={4}
                    title={LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE}
                    onClick={this.onArchiveBtnClick}
                    colorScheme={'FROM_ARCHIVE'}
                >
                    <Icon component={IconLevelUp} />
                    {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                </RoundButton>
            ) : null,
            <AdditionalEntityActionsPopoverContainer>
                <AdditionalEntityActionForCategories
                    categories={this.props.categories ?? []}
                    selectedRecords={selectedItems}
                    entityTypeCode={CategorisedEntityCodeEnum.RENTER}
                    clearSelection={this.clearSelection}
                    selectedCategoriesKeys={this.props.categoryIds ? this.props.categoryIds.split(';') : undefined}
                />
            </AdditionalEntityActionsPopoverContainer>,
        ];
    };

    onArchiveBtnClick = () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => {
                    return { id: item.id, businessVersion: item.businessVersion, archive: !item.archive };
                });
            this.props.setArchiveState(this.props.intl, this.props.businessAccountId, selectedItems);
            this.clearSelection();
        }
    };

    onOffloadingDownload: OffloadingDocumentOnDownload = async ({ fileType, options }) => {
        const { selection } = this.state;
        const { intl, businessAccountId, pageParams, subrentModuleEnabled, canViewFinancialData } = this.props;
        const { sortBy, sortOrder, search } = pageParams;
        const filters = getCounterpartiesListFilters(pageParams, subrentModuleEnabled);

        await downloadOffloading({
            businessAccountId,
            canViewFinancialData,
            exportTemplateType: ExcelExportTemplateTypeCodeEnum.RENTERTEMPLATE,
            fileName: 'Экспорт контрагентов',
            fileType,
            filters,
            financialFieldsEnum: RenterFinancialFieldsTypeCode,
            grid: this.grid,
            intl,
            notFinancialFieldsEnum: RenterNotFinancialFieldsTypeCode,
            selection,
            values: options,
            sortOrder,
            sortBy,
            search,
            excludeColumns: undefined,
        });
    };

    render() {
        const { entities, rentersLoading, filteredCount, pageParams, canViewFinancialData, subrentModuleEnabled } = this.props;

        const excludeColumns: (keyof RenterRecord)[] = !canViewFinancialData
            ? [
                  'defaultDiscount',
                  'currentBookedSum',
                  'currentRentSum',
                  'allProjectsSumAfterTaxes',
                  'allSubrentsSum',
                  'debtOfRenter',
                  'debtToSupplier',
              ]
            : [];

        const filtersExcludeFields: (keyof RenterRecord)[] = [];

        if (!subrentModuleEnabled) {
            excludeColumns.push('activityTypeCode', 'allSubrentsSum', 'debtToSupplier');
            filtersExcludeFields.push('activityTypeCode');
        }

        return (
            <Spin spinning={rentersLoading}>
                <Card bordered={false}>
                    {this.availableFilters && <Grid
                        filtersData={counterpartiesFilters}
                        filtersInitialValues={this.initialValues}
                        filtersCurrentValues={{ ...pageParams, ...this.getSubrentParams() }}
                        filtersDefaultValues={initialParamsState}
                        filtersGetFiltersFormRef={this.getFiltersForm}
                        filtersOnChange={this.onFiltersChange}
                        filtersResetFiltersCb={this.resetFilters}
                        filtersExcludeFields={filtersExcludeFields}
                        categoriesFilter={true}
                        categoriesComponent={GridCategoriesTree}
                        categoriesType={'RENTER'}
                        categoriesFilterOffsetBottom={131}
                        categoriesSelectedKeys={
                            this.props.categoryIds
                                ? this.props.categoryIds.split(';')
                                : this.props.pageParams.categoryIds
                                ? this.props.pageParams.categoryIds.toString().split(';')
                                : undefined
                        }
                        categoriesOnSelect={this.onCategoriesTreeChanged}
                        ref={(ref) => {
                            if (!this.grid) this.grid = ref;
                        }}
                        onRowAction={this.onItemAction}
                        onSortedChange={this.onSortedChange}
                        onPageChanged={this.onPageChanged}
                        onPageSizeChanged={this._onPageSizeChanged}
                        onSelectionChanged={this._onSelectionChanged}
                        filtered={filteredCount}
                        pageSize={pageParams.limit}
                        currentPage={pageParams.page}
                        columns={counterpartyColumns}
                        data={entities}
                        entityType={'renter'}
                        indexFieldName={'id'}
                        actionButtons={this.getActionButtons()}
                        defaultSorted={pageParams.sortBy}
                        defaultSortDesc={pageParams.sortOrder === 'DESC'}
                        hideArchive={pageParams.hideArchive}
                        gridName={this.props.gridName}
                        excludeColumns={excludeColumns}
                        exportBlock={
                            <ExportOffloadingPopover storageKey={'counterpartiesOffloading'} onDownload={this.onOffloadingDownload} />
                        }
                    />}
                </Card>
            </Spin>
        );
    }
}

const mapStateToProps = (
    storeState: IRootState,
    ownProps: {
        history: RouteComponentProps['history'];
    }
) => {
    let pageParams = getStateFromPath(ownProps.history.location.search),
        categoryIds = pageParams['categoryIds'] || undefined;

    return {
        businessAccountId: storeState.system.businessAccountId,
        categories: storeState.entities.categories.renters,
        categoryIds: categoryIds,
        entities: storeState.renters.entities,
        filteredCount: storeState.renters.filteredCount,
        locationPathname: storeState.router.location.pathname,
        locationSearchParams: storeState.router.location.search,
        pageParams: storeState.renters.params,
        rentersLoading: storeState.renters.loading,
        history: ownProps.history,
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    };
};

const mapDispatchToProps = {
    loadCategories,
    loadEntities,
    push,
    replace,
    reset,
    setArchiveState,
    setStatus,
    resetOperationForm,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const CounterpartiesList = connect(mapStateToProps, mapDispatchToProps)(_CounterpartiesList);
