import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { CategoriesBlockSortProps } from '../../categories/categories-block-sort';
import { CategoryTypeCodeEnum } from '../../../../server';
import CategoriesBlock from '../../categories/categories-block';
import { IRootState } from '../../../../shared/reducers';
import { loadCategories } from '../../../../shared/reducers/entities.reducer';
import { updateCategoriesSortOrder } from '../../../../shared/reducers/userSettings/userSettings.reducer';

interface IState {
    sortState: CategoriesBlockSortProps;
}

interface IProps extends StateProps, DispatchProps {
    typeCode: CategoryTypeCodeEnum;
    sortState: CategoriesBlockSortProps;
    height?: number;
}

class ProductCategoriesTabPane extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            sortState: {
                ...props.sortState,
                onChangeSortState: this.onChangeSortState,
            },
        };
    }

    componentDidMount() {
        const sortStateValue = this.props.userSettings.categoriesSortOrder?.[this.props.typeCode];
        if (sortStateValue) {
            this.state.sortState.onChangeSortState({
                value: sortStateValue,
            });
        }
        this.props.loadCategories(this.props.businessAccountId, this.props.typeCode, this.state.sortState.value);
    }

    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        if (!isEqual(prevState?.sortState, this.state?.sortState)) {
            if (this.state.sortState) {
                this.state.sortState.onChangeSortState({
                    value: this.state.sortState.value,
                });
            }
        }
    };

    onChangeSortState = (sortState: Partial<CategoriesBlockSortProps>) => {
        if (this.state.sortState.value !== this.props.userSettings.categoriesSortOrder?.[this.props.typeCode]) {
            const updatedSortOrder = {
                [this.props.typeCode]: this.state.sortState.value,
            };

            this.props.updateCategoriesSortOrder(updatedSortOrder);
            // localStorage.setItem(
            //     'rr-state',
            //     JSON.stringify({
            //         userSettings: userSettings(
            //             this.props.userSettings,
            //             this.props.updateCategoriesSortOrder(updatedSortOrder)
            //         ),
            //     })
            // );
        }
        this.setState({
            sortState: {
                ...this.state.sortState,
                ...sortState,
            },
        });
        this.props.sortState.onChangeSortState(sortState);
    };

    render() {
        return (
            <CategoriesBlock
                data={this.props.products}
                height={this.props.height}
                entities={this.props.entities}
                typeCode={this.props.typeCode}
                searchString={this.props.searchString}
                sortData={this.state.sortState}
                route={{}}
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    entities: storeState.entities.categories.products,
    searchString: storeState.categories.products.searchString,
    products: storeState.categories.products,
    businessAccountId: storeState.system.businessAccountId,
    userSettings: storeState.userSettings,
});

const mapDispatchToProps = { loadCategories, updateCategoriesSortOrder };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategoriesTabPane);
