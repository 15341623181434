import React from 'react';
import { Card, Col, Icon, Row } from 'antd';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { AbstractEntityInfoCard, FormValue } from '../../../components';
import { CurrentRentIndicatorsBlock } from '../../../components/currentRentIndicators/currentRentIndicatorsBlock';
import { formatMoney } from '../../../shared/util/formatMoney';
import { formatPercents } from '../../../shared/util/formatPercents';
import { Link } from 'react-router-dom';
import { getBusinessAccountId } from '../../../../index';
import moment from 'moment';
import { Status } from '../../../components/status/status';
import { LocalizationEnum, localize } from '../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import './operationPageDescription.less';
import { getOperationStatusTypeData } from '../operationForm/utils/utils';
import { Avatar } from '../../../components/avatar/avatar';
import { getGridStorageData, setGridStorageDataFilters } from '../../../components/grid/utils';
import { OperationTypeCodeEnum } from '../../../server';
import { canViewFinancialData } from '../../../shared/util/permissionUtils';
import { subrentModuleEnabledSelector } from '../../../shared/reducers/businessAccountPreferences.reducer';

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {}

const GRID_NAME: string = 'operation-description';

class OperationPageDescriptionTabPane extends React.PureComponent<IProps> {
    state = {
        collapsed: [],
    };

    componentDidMount = async () => {
        let collapsed = await getGridStorageData(GRID_NAME);

        if (collapsed.filters?.collapsedIndicators && collapsed.filters.collapsedIndicators.length > 0) {
            await this.setState({
                collapsed: collapsed.filters.collapsedIndicators,
            });
        } else {
            await this.setState({
                collapsed: collapsed.filters?.collapsedIndicators,
            });
        }

        console.log('OperationPageDescriptionTabPane componentDidMount()');
    };

    onCollapsedIndicators = (key) => {
        setGridStorageDataFilters(GRID_NAME, { collapsedIndicators: key });
        setGridStorageDataFilters(GRID_NAME, { collapsedIndicators: key });
        if (key && key.length > 0) {
            this.setState({
                collapsed: key,
            });
        } else {
            this.setState({
                collapsed: key,
            });
        }
    };

    componentWillUnmount = () => {
        console.log('OperationPageDescriptionTabPane componentWillUnmount()');
    };

    renderActiveElements = (value) =>
        value > 0 ? (
            <>
                <span style={{ color: '#525a95' }}>{value} </span>
                {
                    <Link
                        style={{ color: '#525a95' }}
                        to={`/${getBusinessAccountId()}/history/operations/${
                            this.props.entity !== undefined ? this.props.entity.id : undefined
                        }/?tab=elements&hideInactive=true`}
                    >
                        {localize(LocalizationEnum.ASPECT__PLURAL__ACTIVE_NEUTER_GENDER, undefined, { value: value })}
                    </Link>
                }
            </>
        ) : (
            <span className={'rr-empty-value'}>{localize(LocalizationEnum.ASPECT__GLOBAL__NO)}</span>
        );

    render() {
        let { entity, canViewFinancialData, subrentModuleEnabled } = this.props,
            typeData = getOperationStatusTypeData(entity ? entity.typeCode : undefined, true),
            targetStateCode = entity ? entity.targetStateCode : undefined;

        let rentOrSubrent: 'rent' | 'subrent' | 'all' = 'all';

        if (
            entity?.typeCode === OperationTypeCodeEnum.DRAFT ||
            entity?.typeCode === OperationTypeCodeEnum.ORDER ||
            entity?.typeCode === OperationTypeCodeEnum.BOOK ||
            entity?.typeCode === OperationTypeCodeEnum.RENT ||
            entity?.typeCode === OperationTypeCodeEnum.PROLONG ||
            entity?.typeCode === OperationTypeCodeEnum.RETURN ||
            entity?.typeCode === OperationTypeCodeEnum.RETURNBROKEN ||
            entity?.typeCode === OperationTypeCodeEnum.LOSTNORETURN ||
            entity?.typeCode === OperationTypeCodeEnum.CANCEL
        ) {
            rentOrSubrent = 'rent';
        } else if (
            entity?.typeCode === OperationTypeCodeEnum.SUBRENTDRAFT ||
            entity?.typeCode === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT ||
            entity?.typeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT ||
            entity?.typeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
            entity?.typeCode === OperationTypeCodeEnum.SUBRENTCANCEL
        ) {
            rentOrSubrent = 'subrent';
        }

        if (entity && entity.projectId) {
            rentOrSubrent = 'rent';
        } else if (entity && entity.subrentId) {
            rentOrSubrent = 'subrent';
        }

        return entity ? (
            <Row gutter={32}>
                {/* Левый блок */}
                <Col xxl={6} xl={8} lg={10} span={24} style={{ marginBottom: 32 }}>
                    <Card bordered={false} className={'rr-card-without-padding'}>
                        <div style={{ padding: 32, paddingTop: 30 + 12 }}>
                            <div style={{ textAlign: 'center', marginBottom: 28 + 12 }}>
                                <Avatar className={'rr-avatar-big-with-shadow rr-operation-avatar-' + entity.typeCode}>
                                    <Icon component={typeData.iconComponent} />
                                </Avatar>
                            </div>

                            <Row>
                                <Col className="rr-form-item" style={{ marginBottom: 29 }}>
                                    <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CODE)}</div>
                                    <h3>
                                        <FormValue
                                            value={entity.mnemoKey}
                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                        />
                                    </h3>
                                </Col>
                                <Col className="rr-form-item">
                                    <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__TYPE)}</div>
                                    <div className="rr-value">
                                        <FormValue
                                            value={typeData.name}
                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                        />
                                    </div>
                                </Col>
                                {targetStateCode && (
                                    <Col className="rr-form-item" style={{ marginBottom: 29 }}>
                                        <div className="rr-label">
                                            {localize(LocalizationEnum.PAGE__OPERATIONS__CARD__TARGET_ELEMENTS_STATE)}
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <Status entityType={'element'} code={targetStateCode} />
                                        </div>
                                    </Col>
                                )}
                                <Col className="rr-form-item" style={{ marginBottom: 0 }}>
                                    <div className="rr-label">
                                        {localize(LocalizationEnum.PAGE__OPERATIONS__CARD__ELEMENTS_TOTAL_AMOUNT)}
                                    </div>
                                    <div className={'rr-value'}>
                                        <FormValue
                                            value={entity.operationIndicators.totalElementsCount}
                                            emptyText={LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SET}
                                        />
                                    </div>
                                </Col>

                                {/*<Col className='rr-form-item'>*/}
                                {/*    <div className='rr-label'>Источник</div>*/}
                                {/*    <div className='rr-value'>{originCode[entity.originCode]}</div>*/}
                                {/*</Col>*/}
                            </Row>
                        </div>
                    </Card>
                </Col>

                {/* Правый блок блок */}
                <Col xxl={18} xl={16} lg={14} span={24}>
                    <Card bordered={false} className={'rr-card-without-padding'}>
                        <Row style={{ marginLeft: 8, marginRight: 8 }}>
                            <Col>
                                <Row gutter={16} type={'flex'}>
                                    <Col
                                        xxl={12}
                                        xl={24}
                                        lg={24}
                                        span={24}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            borderRight: '1px solid #e4e5ec',
                                        }}
                                    >
                                        <div>
                                            <div
                                                className={'rr-page-renter-block-with-top-border'}
                                                style={{
                                                    borderBottom: '1px solid #e4e5ec',
                                                    fontSize: 18,
                                                    lineHeight: '57px',
                                                    paddingLeft: 30,
                                                    marginLeft: -8,
                                                    marginRight: -8,
                                                }}
                                            >
                                                {localize(LocalizationEnum.PAGE__OPERATIONS__CARD__RENT_TERMS_ON_CREATION)}
                                            </div>

                                            <Row gutter={0} style={{ padding: '30px 24px' }}>
                                                {entity?.projectId && (
                                                    <Col style={{ marginBottom: 22 }}>
                                                        <Row gutter={16}>
                                                            <Col span={24} xxl={20} className="rr-form-item" style={{ marginBottom: 0 }}>
                                                                <div className="rr-label">Период аренды</div>
                                                                <span className="rr-value">
                                                                    {moment(entity.operationIndicators.rentPeriodStartDateMin).format(
                                                                        'DD MMMM YYYY'
                                                                    )}{' '}
                                                                </span>
                                                                <span className="rr-value-date-time">
                                                                    {moment(entity.operationIndicators.rentPeriodStartDateMin).format(
                                                                        'HH:mm'
                                                                    )}{' '}
                                                                </span>
                                                                <span
                                                                    style={{ fontSize: 17, fontWeight: 700, marginLeft: 4, marginRight: 4 }}
                                                                >
                                                                    →
                                                                </span>
                                                                <span className="rr-value">
                                                                    {moment(entity.operationIndicators.rentPeriodEndDateMax).format(
                                                                        'DD MMMM YYYY'
                                                                    )}{' '}
                                                                </span>
                                                                <span className="rr-value-date-time">
                                                                    {moment(entity.operationIndicators.rentPeriodEndDateMax).format(
                                                                        'HH:mm'
                                                                    )}{' '}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                                <Col>
                                                    <Row gutter={16} style={{ flexGrow: 0 }}>
                                                        <Col span={12} xxl={10} className="rr-form-item" style={{ marginBottom: 0 }}>
                                                            <div className="rr-label">
                                                                {localize(LocalizationEnum.ASPECT__GRID__COLUMN__SHIFT_COUNT)}
                                                            </div>
                                                            <div className="rr-value">
                                                                {entity.operationIndicators.shiftCountMin ===
                                                                entity.operationIndicators.shiftCountMax ? (
                                                                    <FormValue
                                                                        value={entity.operationIndicators.shiftCountMin}
                                                                        originalValue={entity.operationIndicators.shiftCountMin}
                                                                    />
                                                                ) : (
                                                                    `${entity.operationIndicators.shiftCountMin} - ${entity.operationIndicators.shiftCountMax}`
                                                                )}
                                                            </div>
                                                        </Col>
                                                        {canViewFinancialData && (
                                                            <Col span={12} xxl={10} className="rr-form-item" style={{ marginBottom: 0 }}>
                                                                <div className="rr-label">
                                                                    {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DISCOUNT)}
                                                                </div>
                                                                <div className={'rr-value'}>
                                                                    <FormValue
                                                                        value={formatPercents(entity.operationIndicators.totalDiscount)}
                                                                        originalValue={entity.operationIndicators.totalDiscount}
                                                                        emptyText={LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__F}
                                                                    />
                                                                    {entity.operationIndicators.discountMin &&
                                                                    entity.operationIndicators.discountMax &&
                                                                    entity.operationIndicators.discountMin !==
                                                                        entity.operationIndicators.discountMax ? (
                                                                        <span
                                                                            style={{ fontSize: '18px', fontWeight: 400, color: '#aaaaaa' }}
                                                                        >
                                                                            {' '}
                                                                            (
                                                                            {localize(
                                                                                LocalizationEnum.ASPECT__GLOBAL__FROM_AS_START_OF_THE_NUMBERS_INTERVAL
                                                                            )}{' '}
                                                                            {entity.operationIndicators.discountMin}{' '}
                                                                            {localize(
                                                                                LocalizationEnum.ASPECT__GLOBAL__FROM_AS_END_OF_THE_NUMBERS_INTERVAL
                                                                            )}{' '}
                                                                            {entity.operationIndicators.discountMax}%)
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                        {canViewFinancialData && (
                                            <Row
                                                gutter={0}
                                                type={'flex'}
                                                style={{ borderTop: '1px solid #e4e5ec', marginLeft: -8, marginRight: -8 }}
                                            >
                                                <Col
                                                    span={8}
                                                    className="rr-form-item"
                                                    style={{
                                                        borderRight: 'none',
                                                        padding: '22px 8px 23px 32px',
                                                        marginBottom: 0,
                                                        backgroundColor: '#FFF',
                                                    }}
                                                >
                                                    <div className="rr-label">
                                                        {localize(LocalizationEnum.PAGE__OPERATIONS__CARD__TOTAL_PRICE_BEFORE_DISCOUNT)}
                                                    </div>
                                                    <div className="rr-value">
                                                        <FormValue
                                                            value={formatMoney(entity.operationIndicators.totalPriceBeforeDiscount)}
                                                            originalValue={entity.operationIndicators.totalPriceBeforeDiscount}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={8}
                                                    className="rr-form-item"
                                                    style={{
                                                        borderLeft: '1px solid #e4e5ec',
                                                        padding: '22px 8px 22px 32px',
                                                        marginBottom: 0,
                                                        marginLeft: 0,
                                                        backgroundColor: '#FFF',
                                                    }}
                                                >
                                                    <div className="rr-label">
                                                        {localize(LocalizationEnum.PAGE__OPERATIONS__CARD__TOTAL_DISCOUNT)}
                                                    </div>
                                                    <div className={'rr-value'}>
                                                        <FormValue
                                                            value={formatMoney(
                                                                entity.operationIndicators.totalPriceBeforeDiscount -
                                                                    entity.operationIndicators.finalTotalPrice
                                                            )}
                                                            originalValue={
                                                                entity.operationIndicators.totalPriceBeforeDiscount -
                                                                entity.operationIndicators.finalTotalPrice
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={8}
                                                    className="rr-form-item"
                                                    style={{
                                                        borderLeft: '1px solid #525A95',
                                                        padding: '22px 8px 22px 32px',
                                                        backgroundColor: '#525A95',
                                                        marginBottom: 0,
                                                        marginLeft: 0,
                                                    }}
                                                >
                                                    <div className="rr-label" style={{ color: '#FFFFFF' }}>
                                                        {localize(LocalizationEnum.ASPECT__GLOBAL__IN_TOTAL)}
                                                    </div>
                                                    <div className={'rr-value'} style={{ color: '#FFFFFF' }}>
                                                        <FormValue
                                                            value={formatMoney(entity.operationIndicators.finalTotalPrice)}
                                                            originalValue={entity.operationIndicators.finalTotalPrice}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row style={{ backgroundColor: '#f0f2f5', marginLeft: -8, marginRight: -8, flexGrow: 1 }}>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                    <Col
                                        xxl={12}
                                        xl={24}
                                        lg={24}
                                        span={24}
                                        style={{ backgroundColor: '#f0f2f5' }}
                                        className="rr-page-renter-block-with-top-border"
                                    >
                                        <Row gutter={16} style={{ borderBottom: '1px solid #eaeaea' }}>
                                            <CurrentRentIndicatorsBlock
                                                entityType={'operation'}
                                                entity={entity}
                                                onCollapsed={this.onCollapsedIndicators}
                                                collapsed={this.state.collapsed}
                                                typeRent={rentOrSubrent}
                                                subrentModuleEnabled={subrentModuleEnabled}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    <AbstractEntityInfoCard leftBlockSpanLG={24} data={entity} style={{ marginTop: 32 }} />
                </Col>
            </Row>
        ) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    entity: storeState.operation.entity,
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OperationPageDescriptionTabPane));
