import { ColumnTypes, TableColumn } from '../../../../../../components/grid/Table';
import { ContactRecord, CrewMemberRecord, InstanceRecord } from '../../../../../../server';
import { LocalizationEnum, localize } from '../../../../../../localization';
import DashboardDate from '../../../../../../components/dashboardDate/dashboardDate';
import React from 'react';
import { GridColumnCreator } from '../../../../../../components/grid/gridColumnCreator';
import { isDefined } from '../../../../../../shared/util/utils';
import { tableCellMoneyRenderer } from '../../../../../../components/grid/renderers/tableCellMoneyRenderer';
import { TableCellAvatarRenderer } from '../../../../../../components/grid/renderers/tableCellAvatarRenderer';
import { Icon } from 'antd';
import { IconEnvelope, IconPhoneSolid } from '../../../../../../components/icons';

const personShortNameSizes = GridColumnCreator.getColumnSizes(60);
const sizes = GridColumnCreator.getColumnSizes(30);

export const crewMembersListColumns: TableColumn<CrewMemberRecord>[] = [
    GridColumnCreator.createImageColumn<CrewMemberRecord>({
        dataIndex: 'contactImage',
        type: ColumnTypes.CustomRender,
        render: (value, rowData: CrewMemberRecord) => {
            const rendererOpts = rowData.contactIsBusinessAccountRRUser
                ? {
                      highlight: true,
                  }
                : undefined;
            return <TableCellAvatarRenderer value={value} rowData={rowData} opts={rendererOpts} />;
        },
    }),
    GridColumnCreator.createShortNameColumn<CrewMemberRecord>(
        'crewMember',
        (row) => ({
            baId: row.businessAccountId,
            id: row.id,
            name: row.personFullName,
        }),
        {
            title: 'ФИО',
            dataIndex: 'personFullName',
            minWidth: personShortNameSizes.min,
            width: personShortNameSizes.width,
            maxWidth: personShortNameSizes.max,
            resizable: true,
            dontHide: true,
        }
    ),
    GridColumnCreator.createStatusColumn<CrewMemberRecord>(),
    {
        title: 'Телефон',
        dataIndex: 'phoneNumber',
        type: ColumnTypes.CustomRender,
        minWidth: sizes.min,
        width: sizes.width,
        defaultHidden: true,
        maxWidth: sizes.max,
        resizable: true,
        render: (value: any, row: ContactRecord) => {
            return value ? (
                <a
                    style={{
                        fontWeight: 400,
                        textAlign: 'left',
                        color: row.archive ? undefined : '#343f9a',
                    }}
                    className={'rr-grid-email-cell rr-grid-text-cell-left'}
                    href={`tel:${value}`}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <span
                        style={{
                            textAlign: 'left',
                            paddingLeft: 0,
                        }}
                    >
                        {value}
                    </span>
                    <Icon component={IconPhoneSolid} />
                </a>
            ) : undefined;
        },
    },
    {
        title: 'E-Mail',
        dataIndex: 'email',
        type: ColumnTypes.CustomRender,
        minWidth: sizes.min,
        width: sizes.width,
        defaultHidden: true,
        maxWidth: sizes.max,
        resizable: true,
        render: (value: any, row: ContactRecord) => {
            const { email } = row;
            return email ? (
                <div
                    style={{
                        fontWeight: 400,
                    }}
                    className={'rr-grid-email-cell rr-grid-text-cell-left'}
                    title={email}
                >
                    <a
                        href={`mailto:${email}`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {email}
                    </a>{' '}
                    <Icon component={IconEnvelope} />
                </div>
            ) : null;
        },
    },
    {
        title: 'Число специальностей',
        dataIndex: 'professionCount',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        render: (value: any, row: CrewMemberRecord) => {
            if (isDefined(value)) {
                return (
                    <div style={{ display: 'inline-flex', alignItems: 'center', width: 'unset', color: !value ? '#aaaaaa' : undefined }}>
                        {value}
                    </div>
                );
            } else {
                return undefined;
            }
        },
    },
    {
        title: 'Ставка за смену',
        dataIndex: 'defaultRates.ratePerShift',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: any, row: CrewMemberRecord) => {
            return tableCellMoneyRenderer(value);
        },
    },
    {
        title: 'Ставка за час',
        dataIndex: 'defaultRates.ratePerHour',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: any, row: CrewMemberRecord) => {
            return tableCellMoneyRenderer(value);
        },
    },
    GridColumnCreator.createAttachmentsCountColumn<CrewMemberRecord>({
        title: 'Число вложений',
        dataIndex: 'attachmentsCount',
        minWidth: 120,
    }),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date, rowData: InstanceRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    GridColumnCreator.createActionsColumn<CrewMemberRecord>(),
];
