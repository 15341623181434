import { ColumnTypes, TableColumn } from '../../../../components/grid/Table';
import React from 'react';
import { SubrentRecord, SubrentStateCodeEnum } from '../../../../server/api';
import { LocalizationEnum, localize } from '../../../../localization';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../../../config/constants';
import DashboardDate from '../../../../components/dashboardDate/dashboardDate';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { GridColumnCreator } from '../../../../components/grid/gridColumnCreator';
import MoneyValue from '../../../../components/moneyValue/MoneyValue';

// GRID COLUMNS
const columns: TableColumn<SubrentRecord>[] = [
    GridColumnCreator.createProblemSeverityColumn(),
    GridColumnCreator.createShortNameColumn<SubrentRecord>('subrent', (row) => ({
        baId: row.businessAccountId,
        id: row.id,
        name: row.shortName,
    })),
    GridColumnCreator.createStatusColumn(),
    GridColumnCreator.createCounterpartyColumn<SubrentRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.supplierId,
            name: row.supplierShortName,
        }),
        {
            title: 'Поставщик',
            dataIndex: 'supplierShortName',
        }
    ),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__BEGIN),
        dataIndex: 'startDate',
        type: ColumnTypes.Date,
        minWidth: 100,
        //maxWidth: MAX_WIDTH(100),
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.PAGE__SHIPPINGS__LIST__COLUMN__TARGET_FINISH_DATE),
        dataIndex: 'targetFinishDate', // TODO Тут видимо выводить или одну дату или другую
        type: ColumnTypes.Date,
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
        defaultHidden: true,
    },
    {
        title: localize(LocalizationEnum.PAGE__SHIPPINGS__LIST__COLUMN__REAL_FINISH_DATE),
        dataIndex: 'realFinishDate', // TODO Тут видимо выводить или одну дату или другую
        type: ColumnTypes.Date,
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
        defaultHidden: true,
    },
    {
        title: 'Бронь СА',
        dataIndex: 'currentSubrentShipmentBookedSum',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
        render: (v, rowData: SubrentRecord) => (
            <MoneyValue
                value={v}
                muted={rowData.stateCode === SubrentStateCodeEnum.DRAFT || rowData.stateCode === SubrentStateCodeEnum.CANCELED}
            />
        ),
        defaultHidden: true
    },
    {
        title: 'В субаренде',
        dataIndex: 'currentSubrentSum',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
        render: (v, rowData: SubrentRecord) => (
            <MoneyValue
                value={v}
                muted={rowData.stateCode === SubrentStateCodeEnum.DRAFT || rowData.stateCode === SubrentStateCodeEnum.CANCELED}
            />
        ),
    },
    {
        title: 'Итоговая стоимость',
        dataIndex: 'sum',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
        render: (v, rowData: SubrentRecord) => (
            <MoneyValue
                value={v}
                muted={rowData.stateCode === SubrentStateCodeEnum.DRAFT || rowData.stateCode === SubrentStateCodeEnum.CANCELED}
            />
        ),
    },
    {
        title: 'Долг поставщику',
        dataIndex: 'debtToSupplierSum',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        //maxWidth: MAX_WIDTH(140),
        resizable: false,
        render: (v, rowData: SubrentRecord) => (
            <MoneyValue
                value={v}
                muted={rowData.stateCode === SubrentStateCodeEnum.DRAFT || rowData.stateCode === SubrentStateCodeEnum.CANCELED}
                highlighted={rowData.stateCode === SubrentStateCodeEnum.FINISHED && v > 0}
            />
        ),
    },
    GridColumnCreator.createUserColumn<SubrentRecord>(
        (row) => ({
            baId: row.businessAccountId,
            id: row.assigneeId,
            name: row.assigneeShortName,
            hasOwn: row.hasOwnAssignee,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__RESPONSIBLE),
            dataIndex: 'assigneeShortName',
        }
    ),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        //maxWidth: MAX_WIDTH(130),
        resizable: false,
        render: (value: Date, rowData: SubrentRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.Actions,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
    },
];

export default columns;
