import { Icon, Popover, Spin } from 'antd';
import './ContactReferences.less';
import { IconLinkSolid } from '../../../../../../../../components/icons';
import { useState } from 'react';
import { useDecodeLinksQuery } from '../../../api/contactsApi';
import { entitySubtypeCodeMap, entityTypeCodeMap } from '../../../../../../../../localization/utils/entityTypeCodeMap';
import { localize } from '../../../../../../../../localization';

interface Props {
    contactId: number;
    count: number;
}

const ContactReferences = ({ contactId, count }: Props) => {
    const [open, setOpen] = useState(false);

    const { data, isLoading } = useDecodeLinksQuery(contactId, { skip: !open, refetchOnMountOrArgChange: true });

    return count ? (
        <Popover
            overlayClassName={'rr-grid-actions-popover'}
            autoAdjustOverflow
            arrowPointAtCenter
            placement="right"
            trigger="click"
            onVisibleChange={(visible) => setOpen(visible)}
            content={
                <Spin indicator={<Icon type="loading" spin />} style={{ width: '100%' }} spinning={isLoading}>
                    <div className={'rr-grid-actions-popover-header'}>
                        <Icon style={{ fontSize: 20, marginRight: 10 }} component={IconLinkSolid} />
                        <div>Ссылки на контакт</div>
                    </div>
                    <ul className="contact-references-content">
                        {data
                            ? data.map((link) => (
                                  <li
                                      key={
                                          (link.entityType ?? '') +
                                          (link.entityType && link.entitySubtype ? '-' : '') +
                                          (link.entitySubtype ?? '')
                                      }
                                      className="contact-references-item"
                                  >
                                      {link.entitySubtype ? (
                                          <span>{entitySubtypeCodeMap[link.entitySubtype]}</span>
                                      ) : (
                                          <span>{localize(entityTypeCodeMap[link.entityType])}</span>
                                      )}
                                      <span className="contact-references-value">{link.refCount}</span>
                                  </li>
                              ))
                            : null}
                    </ul>
                </Spin>
            }
        >
            <div className="contact-references">
                {count} <span className="contact-references-link">Смотреть</span>
            </div>
        </Popover>
    ) : (
        <span className="contact-references rr-0-value">Нет</span>
    );
};
export default ContactReferences;
