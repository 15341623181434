import React, { useEffect, useMemo, useState} from 'react';
import {SimpleTable, SimpleTableColumn} from '../v2/simpleTable/SimpleTable';
import {Input, Radio} from 'antd';
import { SystemIcon, SystemIconType } from '../v2/systemIcon/systemIcon';
import { ContactSelectAndCreate } from '../v2/select/contactSelect/contactSelectAndCreate';
import { isDefined } from '../../shared/util/utils';
import './contactsEditor.less';
import _ from 'lodash';
import { IDetailedSelectColumn } from '../v2/detailedSelect/DetailedSelect';
import classNames from "classnames";

export interface ContactsEditorProps {
    value: ContactData[];
    onChange: (value: ContactData[]) => void;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    selectColumns?: IDetailedSelectColumn[];
    withMainContact?: boolean;
    disabled?: boolean;
    required?: boolean;
}

export interface ContactData {
    position: string;
    contactId?: number;
    isMainContact?: boolean;
}

export const ContactsEditorFC = ({ value, onChange, getPopupContainer, withMainContact, disabled, required, selectColumns }: ContactsEditorProps) => {
    const [contacts, setContacts] = useState<ContactData[]>(value || []);

    useEffect(() => {
        const v = value || [];
        setContacts(v);
    }, [value]);

    const editContact = (index: number, contact: Partial<ContactData>) => {
        const updatedContacts = [...contacts];

        const existingContact = updatedContacts[index];
        if (existingContact) {
            updatedContacts[index] = { ...existingContact, ...contact };
            if (!_.isEqual(contacts, updatedContacts)) {
                setContacts(updatedContacts);
                onChange(updatedContacts);
            }
        }
    };

    const removeContact = (index: number) => {
        const updatedContacts = [...contacts];

        const contact = updatedContacts[index];
        if(contact){
            if(withMainContact){
                const contactIndex = updatedContacts.indexOf(contact);
                if(contact.isMainContact){
                    if(updatedContacts[contactIndex+1]){
                        updatedContacts[contactIndex+1].isMainContact = true;
                    }else if(updatedContacts[contactIndex-1]){
                        updatedContacts[contactIndex-1].isMainContact = true;
                    }
                }
            }
            updatedContacts.splice(index, 1);
        }

        setContacts(updatedContacts);
        onChange(updatedContacts);
    };

    const addContact = () => {
        const newContact:ContactData = {
            contactId: undefined,
            position: '',
            isMainContact: withMainContact ? (contacts.length === 0) : undefined
        };
        const updatedContacts = contacts ? [...contacts, newContact] : [newContact];

        setContacts(updatedContacts);
        onChange(updatedContacts);
    };


    const columns: SimpleTableColumn<ContactData>[] = withMainContact && !disabled ? [
        {
            id: 'isMainContact',
            width: 30,
            maxWidth: 30,
            render: (value, row, index) => {
                return (
                    <Radio style={row.isMainContact ? {marginBottom: 15} : undefined} checked={row.isMainContact} onClick={(e)=>{
                        const updatedContacts = contacts.map((contact)=>({...contact, isMainContact: false}));
                        updatedContacts[index].isMainContact = true;
                        //const deleted = updatedContacts.splice(index, 1);
                        //if(deleted && deleted[0]) updatedContacts.unshift(deleted[0]);
                        setContacts(updatedContacts);
                        onChange(updatedContacts);
                    }}></Radio>
                );
            },
            header: '',
        },
    ] : [];

    columns.push(
        {
            id: 'contactId',
            width: 200,
            style: { paddingRight: 30 },
            render: (value, row, index) => {
                return (
                    <div>
                        <ContactSelectAndCreate
                            onChange={(value) => {
                                if (isDefined(value)) editContact(index, { contactId: +value });
                            }}
                            onDataChange={(data) => {
                                const contactRecord = data?.[0];
                                const contact = contacts[index];
                                const position = !contact.position ? contactRecord?.primaryPosition : contact.position;
                                editContact(index, { contactId: contactRecord?.id, position });
                            }}
                            style={{ width: '100%' }}
                            value={row.contactId}
                            getPopupContainer={getPopupContainer}
                            filters={{ hideArchive: true }}
                            columns={selectColumns}
                            allowClear={false}
                            disabled={disabled}
                            placeholder={''}
                        />
                        {row.isMainContact && <div style={{fontSize: 11, color: '#AAAAAA', marginTop: 5}}>Основной</div>}
                    </div>
                );
            },
            header: (
                <div className={'ant-form-item-label'} style={{lineHeight: 1}}>
                    <label className={classNames('ant-form-item-no-colon', required ? 'ant-form-item-required' : undefined)}>
                        <span>Контакт</span>
                    </label>
                </div>
            )
        },
        {
            id: 'position',
            width: 100,
            style: { paddingRight: 20 },
            render: (value, row, index) => {
                return (
                    <Input
                        style={row.isMainContact ? {marginBottom: 15} : undefined}
                        value={row.position}
                        onChange={(e) => {
                            editContact(index, { position: e.target.value });
                        }}
                        onBlur={(e) => {
                            editContact(index, { position: e.target.value });
                        }}
                        //placeholder={row.position}
                        disabled={disabled}
                    />
                );
            },
            header: 'Должность',
        },
        {
            id: 'contactId',
            width: 32,
            minWidth: 32,
            maxWidth: 32,
            style: { lineHeight: 1 },
            render: (value, row, index) => {
                return disabled ? null : (
                    <SystemIcon
                        style={row.isMainContact ? {marginBottom: 15} : undefined}
                        type={SystemIconType.cancel}
                        onClick={(e) => {
                            removeContact(index);
                        }}
                    />
                );
            },
        },
    );

    const simpleTable = useMemo(() => {
        return <SimpleTable className={'contacts-editor-table'} columns={columns} data={contacts} />;
    }, [JSON.stringify(contacts)]);

    return (
        <div className={'rr-edit-contacts-form'}>
            {simpleTable}
            {/*<SimpleTable className={'contacts-editor-table'} columns={columns} data={contacts} />*/}
            {(!contacts || contacts.length === 0) && <div style={{ color: '#aaaaaa', fontSize: 18 }}>Контакты не указаны</div>}
            {
                !disabled && (
                    <a onClick={addContact} style={{ color: '#343f9a', textDecoration: 'underline' }}>
                        Добавить строку
                    </a>
                )
            }
        </div>
    );
};

// Без этого не работает прокрутка при валидации в форме
export class ContactsEditor extends React.Component<ContactsEditorProps> {
    render() {
        return <ContactsEditorFC {...this.props} />;
    }
}
