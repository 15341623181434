import React, { CSSProperties } from 'react';
import { Card, Col, Icon, Row } from 'antd';
import { AbstractEntityInfoCard, Spin } from '../../../../../components';
import {
    IconArrowsAltHSolid,
    IconBoxSolid,
    IconClose,
    IconHourglassSolid,
    IconIconsSolid,
    IconLuggageCartSolid,
    IconNoImage,
    IconShapesSolid,
    IconTags,
    IconTagSolid,
    IconWarehouseSolid,
} from '../../../../../components/icons';
import { convertMinutesToHoursMinString } from '../../../../../shared/util/getDateString';
import {
    AbstractEntityInfo,
    AttachmentParentEntityTypeCodeEnum,
    CustomFieldWithValueObjRead,
    ImageObj,
    InstanceTrackingTypeCodeEnum,
    KitInfoRead,
    NomenclatureEntityTypeCodeEnum,
    PricingSchemeModeCodeEnum,
    ProductInfoRead,
    TimetableTypeCodeEnum,
} from '../../../../../server';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../shared/reducers';
import { getImagePath, getPathFromState, isDefined } from '../../../../../shared/util/utils';
import ProductImages from '../../products/components/ProductImages/ProductImages';
import { Avatar } from '../../../../../components/avatar/avatar';
import FormValue from '../../../../../components/formValue/FormValue';
import { LocalizationEnum, localize } from '../../../../../localization';
import { CurrentRentIndicatorsBlock } from '../../../../../components/currentRentIndicators/currentRentIndicatorsBlock';
import { setGridStorageDataFilters } from '../../../../../components/grid/utils';
import { Status } from '../../../../../components/status/status';
import { Link, useLocation } from 'react-router-dom';
import { getCountriesByCurrentLocale } from '../../../../../localization/localizationUtils';
import { formatDate } from '../../../../../shared/util/formatDate';
import { RichTextView } from '../../../../../components/richTextView/RichTextView';
import { ProductCalendarPopover } from '../../../dev/OperationFormProductCalendarPopover';
import { formatMoney } from '../../../../../shared/util/formatMoney';
import { InventoryCurrentStateBlock } from '../InventoryCurrentStateBlock/InventoryCurrentStateBlock';
import { PricingSchemePricePopover } from '../../../settings/pricingSchemes/pricingSchemePricePopover';
import { AttachmentsBlock } from '../../../../../components/attachmentsBlock/attachmentsBlock';
import { businessAccountIdSelector } from '../../../../../shared/reducers/system.reducer';
import { PricingSchemeUtils } from '../../../../../core/utils/pricingSchemeUtils';
import { FormatPercents } from '../../../../../components/formatPercents/formatPercents';
import { subrentModuleEnabledSelector } from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { ProductVariantPopover } from '../../variants/components/productVariantPopover/productVariantPopover';
import { isNumber } from '../../../../../shared/util/isNumber';
import { push } from 'connected-react-router';
import DescriptionCustomField from '../DescriptionCustomField/DescriptionCustomField';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import ExportBarcodePopover from '../../../../../components/exportPopovers/downloadDocument/instances/ExportBadgePopover/ExportBarcodePopover';
import { InventoryCurrentStateBlockItem } from '../InventoryCurrentStateBlock/InventoryCurrentStateBlockItem';

interface PageDescriptionTabPaneProps {
    deviceType: 'phone' | 'tablet' | 'desktop';
    responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    currentImage: ImageObj | undefined;
    setCurrentImage: (data: { currentImage: ImageObj }) => void;
    collapsedIndicators: boolean;
    setCollapsedIndicators: (collapsedIndicators: boolean) => void;
    collapsed: string[] | undefined;
    setCollapsed: (collapsed: string[] | undefined) => void;
    entity: ProductInfoRead | KitInfoRead | null;
    typeDescription: 'product' | 'kit';
    gridName: string;
    showFinancialData: boolean;
    updateParentEntity?: () => void;
}

export const PageDescriptionTabPane = (props: PageDescriptionTabPaneProps) => {
    const { entity } = props;
    let businessAccountId = useAppSelector(businessAccountIdSelector);
    const permissions = useAppSelector((store) => store.permissions.permissions);
    let defaultShiftLengthInMinutes = useSelector(
        (state: IRootState) => state.businessAccountPreferences.preferences?.shiftLengthInMinutes
    );
    let defaultRequiredTimeIndentBetweenElementsInMinutes = useSelector(
        (state: IRootState) => state.businessAccountPreferences.preferences?.requiredTimeIndentBetweenElementsInMinutes
    );
    // let availableInstanceCount =
    //     entity && 'timetableStackCounters' in entity ? entity.timetableStackCounters.availableInstanceCount : entity?.availableKitsCount;
    // let occupiedInstanceCount =
    //     entity && 'timetableStackCounters' in entity ? entity.timetableStackCounters.occupiedInstanceCount : undefined;
    const subrentModuleEnabled = useAppSelector(subrentModuleEnabledSelector);

    const renderImages = (entity: ProductInfoRead | KitInfoRead) => {
        let images: Array<ImageObj> = [];
        if (entity && entity.mainImage) images.push(entity.mainImage);
        if (entity && entity.images) images = [...images, ...entity.images];

        let currentImage = props.currentImage ? props.currentImage : images.length > 0 ? images[0] : undefined;

        return (
            <Col className={'rr-product-page__images-block'}>
                {images && images.length > 0 ? (
                    <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
                        <div style={{ flex: '1 1 auto' }}>
                            <div style={{ height: '100%', textAlign: 'center', position: 'relative' }}>
                                <a href={getImagePath(currentImage)} target={'_blank'} rel="noopener noreferrer">
                                    <img
                                        className={'rr-product-page__main-image'}
                                        style={images.length === 1 ? { marginBottom: '0px' } : {}}
                                        alt={''}
                                        src={getImagePath(currentImage, '-x400')}
                                    />
                                </a>
                            </div>
                        </div>

                        {images.length > 1 ? (
                            <div style={{ flex: '0 1 70px', height: 70 }}>
                                <ProductImages onChange={(image) => props.setCurrentImage({ currentImage: image })} images={images} />
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <div style={{ textAlign: 'center' }}>
                            <Avatar className={'rr-avatar-medium-110'} style={{ backgroundColor: '#e2e5ec' }}>
                                <Icon component={IconNoImage} />
                            </Avatar>
                            <div style={{ marginTop: 36 }}>
                                <FormValue value={''} emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NO_IMAGE)} />
                            </div>
                        </div>
                    </div>
                )}
            </Col>
        );
    };

    const renderCurrentRentIndicators = (entity: ProductInfoRead | KitInfoRead, entityType: 'kit' | 'product') => {
        return (
            <Col>
                <Row gutter={16} style={{ marginLeft: 0, marginRight: 0 }}>
                    <CurrentRentIndicatorsBlock
                        entityType={entityType}
                        entity={entity}
                        onCollapsed={onCollapsedIndicators}
                        collapsed={props.collapsed}
                        subrentModuleEnabled={subrentModuleEnabled}
                    />
                </Row>
            </Col>
        );
    };

    const onCollapsedIndicators = (key) => {
        setGridStorageDataFilters(props.gridName, { collapsedIndicators: key });
        props.setCollapsedIndicators(key.length);
        props.setCollapsed(key);
    };

    const renderMainData1 = (entity: ProductInfoRead | KitInfoRead, phoneMode: boolean = false, selectedVariantId?: number) => {
        const variantsIcon = (
            <Icon style={{ color: '#525993' }} className="product-page-description-link-icon" component={IconShapesSolid} />
        );

        return (
            <Col className={'rr-product-page__main-params-block-1'} style={phoneMode ? { paddingBottom: 0 } : {}}>
                <Row>
                    <Col span={!phoneMode ? 14 : 18} className={'rr-form-item'} style={{ marginBottom: 24 }}>
                        {props.typeDescription === 'product' ? (
                            <>
                                <div style={{ marginBottom: 17 }}>
                                    <Status entityType={'product'} code={(entity as ProductInfoRead).stateCode} />
                                </div>
                                <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE)}</div>
                                <h3 style={{ marginRight: '15px', wordWrap: 'break-word' }}>
                                    <FormValue
                                        value={entity.shortName}
                                        emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                    />
                                </h3>
                                <div className={'rr-entity-page-categories'}>
                                    {entity.categories
                                        ? (entity as ProductInfoRead).categories!.map((item, index, d) => (
                                              <React.Fragment key={index}>
                                                  <Link
                                                      key={index}
                                                      className={'rr-link2'}
                                                      to={`/${businessAccountId}/inventory/products?categoryIds=${item.id}`}
                                                      style={index === 0 ? { fontWeight: 600 } : {}}
                                                  >
                                                      {item.name}
                                                  </Link>{' '}
                                                  {index !== d.length - 1 ? ' / ' : null}
                                              </React.Fragment>
                                          ))
                                        : null}
                                </div>
                            </>
                        ) : (
                            <>
                                <div style={{ marginBottom: 17 }}>
                                    <Status entityType={'kit'} code={(entity as KitInfoRead).stateCode} />
                                </div>

                                <div className="rr-label">{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE)}</div>
                                <h3 style={{ marginRight: '15px', wordWrap: 'break-word' }}>
                                    <FormValue
                                        value={entity.shortName}
                                        emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                                    />
                                </h3>
                                <div className={'rr-entity-page-categories'}>
                                    {entity.categories
                                        ? (entity as KitInfoRead).categories?.map((item, index, d) => (
                                              <React.Fragment key={index}>
                                                  <Link
                                                      key={index}
                                                      className={'rr-link2'}
                                                      to={`/${businessAccountId}/inventory/kits?categoryIds=${item.id}`}
                                                      style={index === 0 ? { fontWeight: 600 } : {}}
                                                  >
                                                      {item.title}
                                                  </Link>{' '}
                                                  {index !== d.length - 1 ? ' / ' : null}
                                              </React.Fragment>
                                          ))
                                        : null}
                                </div>
                            </>
                        )}
                    </Col>
                    <Col span={!phoneMode ? 2 : 1}></Col>

                    {props.typeDescription === 'product' ? (
                        <Col span={!phoneMode ? 10 : 5} style={{ marginTop: 38 }}>
                            {localize(LocalizationEnum.PAGE__PRODUCTS__CARD__VARIANTS)}
                            <div style={{ marginTop: 7, display: 'flex' }}>
                                {entity ? (
                                    (entity as ProductInfoRead).childVariantCount !== 0 && (entity as ProductInfoRead).childVariantCount ? (
                                        <Link
                                            to={`/${businessAccountId}/inventory/products/${entity.id}?tab=variants`}
                                            className="product-page-description-link"
                                        >
                                            {isDefined(selectedVariantId) ? (
                                                <span onClick={(e) => e.stopPropagation()}>
                                                    <ProductVariantPopover
                                                        productId={entity.id}
                                                        variantId={selectedVariantId}
                                                        visible={!!selectedVariantId}
                                                        onCloseButtonClick={() => {
                                                            const path = getPathFromState(location.pathname, location.search, {
                                                                variantId: undefined,
                                                            });
                                                            dispatch(push(path));
                                                        }}
                                                        placement={'bottomRight'}
                                                    >
                                                        {variantsIcon}
                                                    </ProductVariantPopover>
                                                </span>
                                            ) : (
                                                variantsIcon
                                            )}

                                            <span className="product-page-description-link-span" style={{ marginLeft: '10px' }}>
                                                {(entity as ProductInfoRead).childVariantCount}
                                            </span>
                                        </Link>
                                    ) : (
                                        <FormValue value={''} emptyText={localize(LocalizationEnum.ASPECT__GLOBAL__NO)} />
                                    )
                                ) : (
                                    0
                                )}
                            </div>
                        </Col>
                    ) : (
                        <Col span={!phoneMode ? 10 : 5} style={{ marginTop: 38 }}>
                            Компоненты
                            <div style={{ marginTop: 7 }}>
                                {(entity as KitInfoRead).membersCount ? (
                                    <div style={{ display: 'flex' }}>
                                        <Link
                                            to={`/${businessAccountId}/inventory/kits/${entity.id}?tab=composition`}
                                            className="product-page-description-link"
                                        >
                                            <Icon
                                                component={IconIconsSolid}
                                                style={{ color: '#525993' }}
                                                className="product-page-description-link-icon"
                                            />
                                            <span className="product-page-description-link-span" style={{ marginLeft: '10px' }}>
                                                {(entity as KitInfoRead).membersCount}
                                            </span>
                                        </Link>
                                    </div>
                                ) : (
                                    <>
                                        <div style={{ display: 'flex' }}>
                                            <Icon
                                                component={IconIconsSolid}
                                                style={{ color: '#525993' }}
                                                className="product-page-description-link-icon"
                                            />
                                            <span
                                                className="product-page-description-link-span"
                                                style={{ marginLeft: '10px', color: '#f4516c' }}
                                            >
                                                Нет
                                            </span>
                                        </div>
                                        <Link
                                            className={'rr-product-page__main-params-show-all'}
                                            style={{ display: 'inline-block', marginTop: 6 }}
                                            to={`/${businessAccountId}/inventory/kits/${entity.id}?tab=composition`}
                                        >
                                            Указать компоненты
                                        </Link>
                                    </>
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            </Col>
        );
    };

    const renderMainData2 = (entity: ProductInfoRead | KitInfoRead, phoneMode: boolean) => {
        let customFieldGroups = 'customFieldGroups' in entity && entity.customFieldGroups ? entity.customFieldGroups : [];

        customFieldGroups = customFieldGroups?.map((g) => {
            const newGroup = { ...g };
            newGroup.fields = newGroup.fields.filter((f) => {
                return !props.showFinancialData && f.financial ? false : true;
            });
            return newGroup;
        });

        const customFieldGroupsFieldsCountNoMain: number = customFieldGroups
            .map((el, index) => {
                return el.fields.length;
            })
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        const customFields: any = customFieldGroups
            .map((el, index) => {
                return el.fields;
            })
            .filter(function (x) {
                return x !== undefined;
            });

        const customFieldsList: CustomFieldWithValueObjRead[] = [].concat(...customFields);

        const countriesList = getCountriesByCurrentLocale();

        return (
            <>
                {props.typeDescription === 'product' ? (
                    <Col className={'product-info-block rr-product-page__main-params-block-2'}>
                        <Row style={{ marginTop: 10 }}>
                            <Col span={!phoneMode ? 14 : 12}>{localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE)}</Col>
                            <Col span={!phoneMode ? 10 : 12} className={'rr-value'} style={{ marginTop: -4 }}>
                                <FormValue
                                    value={(entity as ProductInfoRead).externalCode}
                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: 10 }}>
                            <Col span={!phoneMode ? 14 : 12}>Штриховой код</Col>
                            <Col span={!phoneMode ? 10 : 12} className={'rr-value'} style={{ marginTop: -4 }}>
                                <FormValue
                                    value={
                                        (entity as ProductInfoRead)?.barcode ? (
                                            <>
                                                <ExportBarcodePopover
                                                    nomenclatureType={NomenclatureEntityTypeCodeEnum.PRODUCT}
                                                    nomenclatureId={entity.id}
                                                />
                                                {entity.barcode}
                                            </>
                                        ) : undefined
                                    }
                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: 10 }}>
                            <Col span={!phoneMode ? 14 : 12}>Состоит в наборах</Col>
                            <Col span={!phoneMode ? 10 : 12} className={'rr-value'} style={{ marginTop: -4 }}>
                                <FormValue
                                    value={
                                        (entity as ProductInfoRead)?.kitsCount ? (
                                            <>
                                                <Link to={`/${businessAccountId}/inventory/kits/?productId=${entity.id}`}>
                                                    <Icon
                                                        component={IconLuggageCartSolid}
                                                        style={{
                                                            color: '#525993',
                                                            fontSize: 24,
                                                            marginTop: -4,
                                                            verticalAlign: 'middle',
                                                            marginRight: 6,
                                                        }}
                                                    />{' '}
                                                    {(entity as ProductInfoRead)?.kitsCount}
                                                </Link>
                                            </>
                                        ) : undefined
                                    }
                                    emptyText={'Нет'}
                                />
                            </Col>
                        </Row>

                        <Row style={{ marginTop: 10 }}>
                            <Col span={!phoneMode ? 14 : 12}>{localize(LocalizationEnum.ASPECT__FIELDS__PRODUCT__LAST_RENT_END_DATE)}</Col>
                            <Col span={!phoneMode ? 10 : 12}>
                                <span className={'rr-value'}>
                                    <FormValue
                                        value={formatDate((entity as ProductInfoRead).rentIndicators.lastRentEndDate, 'D MMMM YYYY')}
                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__ABSENT)}
                                    />
                                    &nbsp;
                                </span>
                                <span className={'rr-value-date-time'}>
                                    <FormValue value={formatDate((entity as ProductInfoRead).rentIndicators.lastRentEndDate, 'HH:mm')} />
                                </span>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: 10 }}>
                            <Col span={!phoneMode ? 14 : 12}>
                                {localize(LocalizationEnum.ASPECT__FIELDS__PRODUCT__COUNTRIES_OF_PRODUCTION)}
                            </Col>
                            <Col span={!phoneMode ? 10 : 12} className={'rr-value'} style={{ marginTop: -4 }}>
                                <FormValue
                                    value={
                                        (entity as ProductInfoRead).producerCountries &&
                                        (entity as ProductInfoRead).producerCountries!.length
                                            ? (entity as ProductInfoRead)
                                                  .producerCountries!.map((code) => {
                                                      let obj = countriesList.find((item) => item.value === code + '');
                                                      if (obj) return obj.name;
                                                      return undefined;
                                                  })
                                                  .filter((item) => !!item)
                                                  .join(', ')
                                            : null
                                    }
                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__F)}
                                />
                            </Col>
                        </Row>

                        {canViewFinancialData(permissions) && canUpdateFinancialData(permissions) && (
                            <Row style={{ marginTop: 10 }}>
                                <Col span={!phoneMode ? 14 : 12}>{localize(LocalizationEnum.PAGE__PRODUCTS__PURCHASE_PRICE)}</Col>
                                <Col span={!phoneMode ? 10 : 12} className={'rr-value'} style={{ marginTop: -4 }}>
                                    <FormValue
                                        value={
                                            (entity as ProductInfoRead).basePurchaseCost != null
                                                ? formatMoney((entity as ProductInfoRead).basePurchaseCost)
                                                : null
                                        }
                                        emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__F)}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>
                ) : (
                    <Col className={'product-info-block rr-product-page__main-params-block-2'}>
                        <Row style={{ marginTop: 10 }}>
                            <Col span={!phoneMode ? 14 : 12}>{localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE)}</Col>
                            <Col span={!phoneMode ? 10 : 12} className={'rr-value'} style={{ marginTop: -4 }}>
                                <FormValue
                                    value={(entity as KitInfoRead).externalCode}
                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <Col span={!phoneMode ? 14 : 12}>Штриховой код</Col>
                            <Col span={!phoneMode ? 10 : 12} className={'rr-value'} style={{ marginTop: -4 }}>
                                <FormValue
                                    value={
                                        (entity as KitInfoRead)?.barcode ? (
                                            <>
                                                <ExportBarcodePopover
                                                    // style={{
                                                    //     fontSize: 24,
                                                    //     marginTop: -2,
                                                    //     verticalAlign: 'middle',
                                                    //     marginRight: 10,
                                                    // }}
                                                    nomenclatureType={NomenclatureEntityTypeCodeEnum.KIT}
                                                    nomenclatureId={entity.id}
                                                />
                                                {entity.barcode}
                                            </>
                                        ) : undefined
                                    }
                                    emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                                />
                            </Col>
                        </Row>
                        {props.showFinancialData && (
                            <Row style={{ marginTop: 10 }}>
                                <Col span={14}>Скидка</Col>
                                <Col span={10}>
                                    <div className="rr-value">
                                        <FormValue
                                            value={
                                                <FormatPercents
                                                    value={(entity as KitInfoRead).totalDiscount}
                                                    withoutDots={false}
                                                    precision={2}
                                                />
                                            }
                                            originalValue={(entity as KitInfoRead).totalDiscount}
                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__ABSENT)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}

                        {props.showFinancialData && (
                            <Row style={{ marginTop: 10 }}>
                                <Col span={14}>Итоговая скидка</Col>
                                <Col span={10}>
                                    <div className="rr-value">
                                        <FormValue
                                            value={formatMoney(
                                                ((entity as KitInfoRead).finalTotalPrice / (100 - (entity as KitInfoRead).totalDiscount)) *
                                                    100 -
                                                    (entity as KitInfoRead).finalTotalPrice
                                            )}
                                            originalValue={
                                                ((entity as KitInfoRead).finalTotalPrice / (100 - (entity as KitInfoRead).totalDiscount)) *
                                                    100 -
                                                (entity as KitInfoRead).finalTotalPrice
                                            }
                                            emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__F)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}

                        <Row style={{ marginTop: 10 }}>
                            <Col span={14}>Экземпляров в наборе</Col>
                            <Col span={10}>
                                <div className="rr-value">
                                    <FormValue value={(entity as KitInfoRead).instancesCount} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                )}

                {
                    //Меньше 1 хр
                    customFieldGroupsFieldsCountNoMain < 1 ? (
                        <Col className={'product-info-block rr-product-page__main-params-block-2'}>
                            <Row>
                                <Link
                                    className={'rr-product-page__main-params-show-all'}
                                    to={`/${businessAccountId}/inventory/${props.typeDescription === 'product' ? 'products' : 'kits'}/${
                                        entity.id
                                    }?tab=features&edit=true`}
                                >
                                    {localize(LocalizationEnum.PAGE__PRODUCTS__CARD__ADD_FEATURES)}
                                </Link>
                            </Row>
                        </Col>
                    ) : (
                        //больше 1 х-и
                        <Col
                            className={'product-info-block rr-product-page__main-params-block-2  product-info-advanced-setting'}
                            style={{ paddingTop: '22px' }}
                        >
                            {customFieldsList.map((field, index) => {
                                if (index < 6)
                                    return (
                                        <Row style={{ marginTop: 10 }} key={index}>
                                            <Col span={!phoneMode ? 14 : 12}>{field.name}</Col>
                                            <Col span={!phoneMode ? 10 : 12} className={'rr-value'} style={{ marginTop: -4 }}>
                                                <DescriptionCustomField field={field} />
                                            </Col>
                                        </Row>
                                    );
                                else return null;
                            })}

                            <Row style={{ marginTop: 26 }}>
                                <Col>
                                    <Link
                                        className={'rr-product-page__main-params-show-all'}
                                        to={`/${businessAccountId}/inventory/${props.typeDescription === 'product' ? 'products' : 'kits'}/${
                                            entity.id
                                        }?tab=features`}
                                    >
                                        {(customFieldGroupsFieldsCountNoMain > 6 ? customFieldGroupsFieldsCountNoMain - 6 : 0) > 0 ? (
                                            <>
                                                {localize(LocalizationEnum.PAGE__PRODUCTS__CARD__SHOW_ALL_FEATURES)}
                                                <span style={{ fontWeight: 600 }}>
                                                    {' '}
                                                    ({localize(LocalizationEnum.ASPECT__GLOBAL__MORE)}{' '}
                                                    {customFieldGroupsFieldsCountNoMain >= 6 ? customFieldGroupsFieldsCountNoMain - 6 : 0})
                                                </span>
                                            </>
                                        ) : (
                                            <>{localize(LocalizationEnum.PAGE__PRODUCTS__CARD__SHOW_FEATURES)}</>
                                        )}
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    )
                }
            </>
        );
    };

    const renderDescriptionGroup = (entity: ProductInfoRead | KitInfoRead) => {
        return (
            <Col className={'rr-product-page__description-block'}>
                <div className={'rr-product-page__description-block-item'}>
                    <div>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}</div>
                    <div className={'rr-text'}>
                        <FormValue
                            value={entity.description && <RichTextView value={entity.description} />}
                            emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                        />
                    </div>
                </div>
            </Col>
        );
    };

    const renderAttachmentsBlock = (entity: ProductInfoRead, props: PageDescriptionTabPaneProps) => {
        return (
            <Col style={{ borderTop: '1px solid #eaeaea' }}>
                <AttachmentsBlock
                    attachments={entity?.attachments?.records || []}
                    updateParentEntity={props.updateParentEntity}
                    parentEntityId={entity.id}
                    parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.PRODUCT}
                />
            </Col>
        );
    };

    const renderMainBlockRow = (
        entity,
        responsive: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
        entityType: 'kit' | 'product',
        selectedVariantId: number | undefined,
        selectedInstanceId: number | undefined
    ) => {
        return (
            <Row>
                {renderMainData1(entity, true, selectedVariantId)}
                {responsive !== 'lg' && entityType === 'product' ? (
                    <div style={{ padding: '0px 32px' }}>{renderMainIndicators(entity, true, selectedInstanceId)}</div>
                ) : null}
                {responsive !== 'lg' ? <div style={{ padding: '0px 32px', paddingTop: 20 }}>{renderPriceBlock(entity, true)}</div> : null}
                {renderImages(entity)}
                {renderMainData2(entity, true)}
                {entityType === 'product' ? renderAttachmentsBlock(entity, props) : null}
                {renderDescriptionGroup(entity)}
                {renderCurrentRentIndicators(entity, entityType)}
            </Row>
        );
    };

    const renderMainIndicators = (entity: ProductInfoRead, phoneMode: boolean, selectedInstanceId: number | undefined) => {
        const {
            warehousePresenceCounters: { stockInstanceCount, underMaintenanceInstanceCount },
        } = entity;
        const rentedInstanceCount = entity.warehousePresenceCounters?.rentedInstanceCount;
        const subrentedInstanceCount = entity.warehousePresenceCounters?.subrentedInstanceCount;
        const availableInstanceCount = entity.timetableStackCounters?.availableInstanceCount;
        const warehouseInstanceCount = entity.warehousePresenceCounters.warehouseInstanceCount;
        let inventoryIcon;
        let inventoryLink;

        if (entity.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.BULK) {
            inventoryLink = `${entity.id}/edit-count`;
            inventoryIcon = IconBoxSolid;
        } else if (entity.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK) {
            inventoryIcon = IconBoxSolid;
        } else {
            inventoryLink = `/${businessAccountId}/inventory/products/${entity.id}?tab=instances`;
            inventoryIcon = IconTagSolid;
        }

        return (
            <InventoryCurrentStateBlock
                stockInstanceCount={stockInstanceCount}
                underMaintenanceInstanceCount={underMaintenanceInstanceCount}
                rentedInstanceCount={rentedInstanceCount}
                subrentedInstanceCount={subrentedInstanceCount}
                availableInstanceCount={availableInstanceCount}
                warehouseInstanceCount={warehouseInstanceCount}
                phoneMode={phoneMode}
                inventoryIcon={inventoryIcon}
                inventoryLink={inventoryLink}
                productId={entity.id}
                forProduct
                instanceTrackingTypeCode={entity.instanceTrackingTypeCode}
                timetableStackCounters={entity.timetableStackCounters}
                selectedInstanceId={selectedInstanceId}
            />
        );
    };

    const renderPriceBlock = (entity: ProductInfoRead | KitInfoRead, phoneMode: boolean) => {
        const { typeIcon } = PricingSchemeUtils.getPricingSchemeData(entity.pricingScheme?.typeCode);

        const price = 'pricePerShift' in entity ? entity.pricePerShift : entity.finalTotalPrice;
        const pricingSchemeMode = 'pricingSchemeMode' in entity ? entity.pricingSchemeMode : undefined;

        const styles: CSSProperties = {
            display: 'flex',
            alignItems: 'center',
            columnGap: 5,
            color: '#525993',
            fontWeight: 600,
        };

        return (
            props.showFinancialData && (
                <Row>
                    <Col span={!phoneMode ? 24 : 8} className={'rr-product-page__price-block-item'}>
                        <div>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__PRICE_PER_SHIFT)}</div>
                        <div>{formatMoney(price)}</div>
                    </Col>

                    <Col span={!phoneMode ? 24 : 8} className={'rr-product-page__pricing-scheme-block'}>
                        <div>Ценовая схема</div>
                        {pricingSchemeMode === PricingSchemeModeCodeEnum.DISABLED && (
                            <div style={styles}>
                                <Icon component={IconClose} style={{ fontSize: 21, color: '#F12143' }} /> Без ценовых схем
                            </div>
                        )}
                        {pricingSchemeMode === PricingSchemeModeCodeEnum.WITHMEMBERPRICINGSCHEMES && (
                            <div style={styles}>
                                <Icon component={IconTags} style={{ fontSize: 21, color: '#aaaaaa' }} /> По продуктам
                            </div>
                        )}

                        {entity.pricingScheme ? (
                            <PricingSchemePricePopover
                                data={{
                                    id: entity.pricingScheme.id,
                                    typeCode: entity.pricingScheme.typeCode,
                                    label: entity.pricingScheme.name,
                                    price: price,
                                    steps: entity.pricingScheme.steps,
                                }}
                            >
                                <div>
                                    <div>
                                        <Icon component={typeIcon} />
                                    </div>
                                    <div>{entity.pricingScheme.name}</div>
                                </div>
                            </PricingSchemePricePopover>
                        ) : !pricingSchemeMode ? (
                            <span className="rr-empty-value">Нет</span>
                        ) : null}
                    </Col>
                </Row>
            )
        );
    };

    const renderAbstractEntityInfoCard = (data: AbstractEntityInfo) => {
        return (
            <AbstractEntityInfoCard
                always2columns={true}
                leftBlockSpan={24}
                leftBlockSpanXL={24}
                leftBlockSpanXXL={24}
                leftBlockSpanLG={24}
                data={data}
            />
        );
    };

    const dispatch = useAppDispatch();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const selectedVariantId =
        params.get('tab') === 'description' && isDefined(params.get('variantId')) && isNumber(params.get('variantId'))
            ? Number(params.get('variantId'))
            : undefined;
    const selectedInstanceId =
        params.get('tab') === 'description' && isDefined(params.get('instanceId')) && isNumber(params.get('instanceId'))
            ? Number(params.get('instanceId'))
            : undefined;

    return (
        <Spin spinning={false}>
            {entity ? (
                <>
                    {props.deviceType !== 'phone' ? (
                        <Row gutter={32}>
                            {/* Левый основной блок */}
                            <Col span={18} lg={18} xl={19} xxl={20}>
                                <Row>
                                    <Col>
                                        <Card bordered={false} className={'rr-card-without-padding'}>
                                            {props.responsive !== 'lg' ? (
                                                <Row type={'flex'}>
                                                    <Col
                                                        className={'rr-product-page__main-left-col'}
                                                        xl={12}
                                                        span={24}
                                                        style={{ backgroundColor: '#f0f2f5' }}
                                                    >
                                                        <Row style={{ backgroundColor: '#fff' }}>
                                                            {/* Картинки */}
                                                            {renderImages(entity)}
                                                            {/* Вычисляемые показатели */}
                                                            {renderCurrentRentIndicators(entity, props.typeDescription)}
                                                        </Row>
                                                    </Col>
                                                    <Col xl={12} span={24}>
                                                        <Row>
                                                            {/* Инфа основная */}
                                                            {renderMainData1(entity, false, selectedVariantId)}
                                                            {renderMainData2(entity, false)}
                                                            {props.typeDescription === 'product'
                                                                ? renderAttachmentsBlock(entity as ProductInfoRead, props)
                                                                : null}
                                                            {/* Описание */}
                                                            {renderDescriptionGroup(entity)}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                renderMainBlockRow(
                                                    entity,
                                                    props.responsive,
                                                    props.typeDescription,
                                                    selectedVariantId,
                                                    selectedInstanceId
                                                )
                                            )}
                                        </Card>
                                    </Col>
                                    <Col style={{ marginTop: 30 }}>
                                        <AbstractEntityInfoCard
                                            leftBlockSpan={10}
                                            leftBlockSpanXL={10}
                                            leftBlockSpanXXL={10}
                                            data={entity}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            {/* Правый основной блок */}
                            <Col span={6} lg={6} xl={5} xxl={4}>
                                <Row>
                                    {props.typeDescription === 'product' ? (
                                        <>
                                            <Col>
                                                <Card className={'rr-product-page__main-indicators-block'}>
                                                    {renderMainIndicators(entity as ProductInfoRead, false, selectedInstanceId)}
                                                </Card>
                                            </Col>
                                            {props.showFinancialData && (
                                                <Col>
                                                    <Card className={'rr-product-page__price-block'}>
                                                        {renderPriceBlock(entity as ProductInfoRead, false)}
                                                    </Card>
                                                </Col>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Col>
                                                <Card className={'rr-product-page__main-indicators-block'}>
                                                    <div style={{ marginTop: -5 }}>
                                                        <div style={{ fontSize: 18 }}>Текущее состояние</div>
                                                        <div
                                                            style={{
                                                                color: '#aaaaaa',
                                                                marginTop: 4,
                                                                marginBottom: 15,
                                                            }}
                                                        >
                                                            Число наборов, которые можно собрать
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <Icon
                                                                component={IconBoxSolid}
                                                                style={{
                                                                    color: (entity as KitInfoRead).membersCount ? '#525993' : '#abacb0',
                                                                    fontSize: '30px',
                                                                }}
                                                            />
                                                            {(entity as KitInfoRead).membersCount ? (
                                                                <ProductCalendarPopover
                                                                    kitId={entity.id}
                                                                    variantId={undefined}
                                                                    productId={undefined}
                                                                    typeCode={TimetableTypeCodeEnum.STOCK}
                                                                >
                                                                    <span
                                                                        className="product-page-description-link-span"
                                                                        style={{ cursor: 'pointer', marginLeft: '6px' }}
                                                                    >
                                                                        <span className="product-page-description-link-span">
                                                                            {(entity as KitInfoRead).stockKitCount}
                                                                        </span>
                                                                    </span>
                                                                </ProductCalendarPopover>
                                                            ) : (
                                                                <span
                                                                    className="product-page-description-link-span"
                                                                    style={{ marginLeft: 6, color: '#abacb0' }}
                                                                >
                                                                    ?
                                                                </span>
                                                            )}
                                                        </div>
                                                        <InventoryCurrentStateBlockItem
                                                            title={'На складе'}
                                                            isEmpty={(entity as KitInfoRead).membersCount === 0}
                                                            kitId={(entity as KitInfoRead).id}
                                                            phoneMode={false}
                                                            countElement={<>{(entity as KitInfoRead).warehouseKitCount}</>}
                                                            scheduleCountElement={
                                                                (entity as KitInfoRead).warehouseKitCount ===
                                                                (entity as KitInfoRead).availableKitsCount ? undefined : (
                                                                    <>{(entity as KitInfoRead).availableKitsCount}</>
                                                                )
                                                            }
                                                            typeCode={TimetableTypeCodeEnum.AVAILABLE}
                                                            icon={IconWarehouseSolid}
                                                            iconClass={'rr-product-page__main-indicators-block-item1-icon-4'}
                                                            style={{ marginTop: 26, marginBottom: 0 }}
                                                            isLast
                                                        />
                                                    </div>
                                                </Card>
                                            </Col>
                                            {props.showFinancialData && (
                                                <Col>
                                                    <Card className={'rr-product-page__price-block'}>
                                                        {renderPriceBlock(entity as ProductInfoRead, false)}
                                                    </Card>
                                                </Col>
                                            )}
                                        </>
                                    )}

                                    <Col>
                                        {props.typeDescription === 'product' ? (
                                            <>
                                                <Card className={'rr-product-page__shiftLengthInMinutes-block'}>
                                                    <Row>
                                                        <Col span={24} className={'rr-product-page__shiftLengthInMinutes-block-item'}>
                                                            <div>Длительность смены</div>
                                                            <div>
                                                                <Icon
                                                                    component={IconHourglassSolid}
                                                                    style={{
                                                                        fontSize: 20,
                                                                        marginRight: 6,
                                                                        marginTop: 2,
                                                                        marginLeft: -4,
                                                                        verticalAlign: 'top',
                                                                        color: (entity as ProductInfoRead).hasOwnShiftLength
                                                                            ? '#00afee'
                                                                            : '#aaaaaa',
                                                                    }}
                                                                />
                                                                <span
                                                                    style={
                                                                        (entity as ProductInfoRead).hasOwnShiftLength
                                                                            ? {}
                                                                            : { fontWeight: 400, color: '#AAAAAA' }
                                                                    }
                                                                >
                                                                    {convertMinutesToHoursMinString(
                                                                        (entity as ProductInfoRead)?.hasOwnShiftLength
                                                                            ? (entity as ProductInfoRead)?.shiftLengthInMinutes
                                                                            : defaultShiftLengthInMinutes
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {!(entity as ProductInfoRead)?.hasOwnShiftLength && <div>(По компании)</div>}
                                                        </Col>
                                                    </Row>
                                                </Card>

                                                <Card
                                                    className={
                                                        'rr-product-page__requiredTimeIndentBetweenElementsInMinutes-block ant-card-bordered'
                                                    }
                                                >
                                                    <Row>
                                                        <Col
                                                            span={24}
                                                            className={
                                                                'rr-product-page__requiredTimeIndentBetweenElementsInMinutes-block-item'
                                                            }
                                                        >
                                                            <div>Резерв времени между обязательствами</div>
                                                            <div>
                                                                <Icon
                                                                    component={IconArrowsAltHSolid}
                                                                    style={{
                                                                        fontSize: 22,
                                                                        marginRight: 10,
                                                                        marginTop: 1,
                                                                        verticalAlign: 'top',
                                                                        color: (entity as ProductInfoRead)
                                                                            .hasOwnRequiredTimeIndentBetweenElements
                                                                            ? '#8d5bae'
                                                                            : '#aaaaaa',
                                                                    }}
                                                                />
                                                                <span
                                                                    style={
                                                                        (entity as ProductInfoRead).hasOwnRequiredTimeIndentBetweenElements
                                                                            ? {}
                                                                            : { fontWeight: 400, color: '#AAAAAA' }
                                                                    }
                                                                >
                                                                    {convertMinutesToHoursMinString(
                                                                        (entity as ProductInfoRead)?.hasOwnRequiredTimeIndentBetweenElements
                                                                            ? (entity as ProductInfoRead)
                                                                                  ?.requiredTimeIndentBetweenElementsInMinutes
                                                                            : defaultRequiredTimeIndentBetweenElementsInMinutes
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {!(entity as ProductInfoRead)?.hasOwnRequiredTimeIndentBetweenElements && (
                                                                <div>(По компании)</div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </>
                                        ) : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : (
                        <Row className={'rr-product-page-phone-mode'}>
                            <Col>
                                <Card bordered={false} className={'rr-card-without-padding'}>
                                    {renderMainBlockRow(
                                        entity,
                                        props.responsive,
                                        props.typeDescription,
                                        selectedVariantId,
                                        selectedInstanceId
                                    )}
                                </Card>
                            </Col>
                            <Col style={{ marginTop: 30 }}>{renderAbstractEntityInfoCard(entity)}</Col>
                        </Row>
                    )}
                </>
            ) : null}
        </Spin>
    );
};
