import React from 'react';
import { Button, Col, Icon, Row } from 'antd';
import moment from 'moment';
import {
    addElement,
    editElement,
    InstancePropertiesEntityRecordCustom,
    OperationElement,
    updateAnyTimetables,
} from '../../reducers/operationForm.reducer';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { IRootState } from '../../../../../shared/reducers';
import { connect } from 'react-redux';
import { IconPlus } from '../../../../../components/icons';
import {
    InstanceInfoRead,
    InstanceTrackingTypeCodeEnum,
    NomenclatureEntityTypeCodeEnum,
    OperationTypeCodeEnum,
    RentStateCodeEnum,
} from '../../../../../server';
import { logStackMap, ProductUtils } from '../../../../../shared/util/productUtils';
import { isNumber } from '../../../../../shared/util/isNumber';
import { LocalizationEnum, localize } from '../../../../../localization';
import { SelectValue } from 'antd/lib/select';
import { InlineMoneyInput } from '../../../../../components/inlineInputs/InlineMoneyInput/InlineMoneyInput';
import {
    canAddInstancesToExistedElement, canAddNewProductsToOperation2,
    canBeErrorWhenShiftCountMoreThanPeriod,
    canBeErrorWhenShortage,
    canCreateOperationWithAnonymousInstances,
    canEditOperationElementParams,
    findTimeTable,
    getLeftoverInstances,
    is_OperationTimeError,
    isOrderOperation,
    OperationErrorCodeEnum,
    operationWithShortage,
} from '../../utils/utils';
import { PopoverContent } from '../../../../../components/popover/PopoverContent';
import { getShiftCountFromDates } from '../../../../../shared/util/utils';
import { ProductInstancesSelect } from '../product-instances-select/ProductInstancesSelect';
import { RecalculateShiftsCountButton } from '../../RecalculateShiftsCountButton';
import { InstanceQuickCreateModal } from './instance-create-modal';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { OperationParamResetButton } from '../../components/operationParamResetButton/OperationParamResetButton';
import { getStore, getStoreState } from '../../../../../../index';
import { PricingSchemePricePopover } from '../../../settings/pricingSchemes/pricingSchemePricePopover';
import { canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import classNames from 'classnames';
import { GridRowActions1 } from '../../../../../components/v2/gridActions/gridActions';
import { ActionTypeEnum } from '../../../../../core/types/ActionTypeEnum';
import { NumberInput, NumberInputDiscount, NumberInputShiftCount } from '../../../../../components/numberInputs';
import { PricingSchemeMaps, PricingSchemeUtils } from '../../../../../core/utils/pricingSchemeUtils';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { getIsCorrectCancelOperation , getIsElementReducesAvailability} from '../../utils/operationUtils';
import {
    getDateIntervalsForCalendar,
    loadNomenclaturesOnIntervalsIfNeed,
    NomenclatureBaseObj,
    NomenclatureOnInterval,
    rentElementToNomenclatureBaseObj,
} from '../../utils/nomenclaturesUtils';
import { showNotification } from '../../../../../components/notification/showNotification';
import {RangePicker} from "../../../../../components/v2/calendar/rangePicker/rangePicker";

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    instanceCount: number;
    availableInstanceCount: number;
    productRecord?: InstancePropertiesEntityRecordCustom;
    elementRecord?: OperationElement;
    disabled?: boolean;
    onCloseCB: () => void;
    onCalendarVisibleChangeCB?: (visible: boolean) => void;
    mode: 'create' | 'edit' | 'copy';
}

interface IState {
    instanceCount?: number;
    discount: number;
    shiftCount: number;
    startDate?: Date;
    endDate?: Date;

    pricePerShift: number;
    discountChanged: boolean;
    rentPeriodChanged: boolean;
    shiftCountChanged: boolean;

    selectedInstances?: number[];
    anonymousInstanceCount?: number;

    productId: number;
    variantId?: number;
    instanceAddModalVisible: boolean;
    priceChanged: boolean;
    saving: boolean;
    isLoading: boolean;
    visible: boolean;
}

class Component extends React.PureComponent<IProps, IState> {
    private select0;

    shouldComponentUpdate(nextProps: IProps, nextState) {
        if (!nextState.visible) return false;
        return true;
    }

    constructor(props) {
        super(props);

        let shiftCount = this.props.elementRecord ? this.props.elementRecord.shiftCount : this.props.defaultShiftCount;

        let startDate = this.props.elementRecord ? this.props.elementRecord.rentPeriodStartDate : this.props.defaultRentPeriodStartDate;
        let endDate = this.props.elementRecord ? this.props.elementRecord.rentPeriodEndDate : this.props.defaultRentPeriodEndDate;

        let shiftCountChanged = this.props.elementRecord ? this.props.elementRecord.shiftCountChanged : false;
        let discountChanged = this.props.elementRecord ? this.props.elementRecord.discountChanged : false;
        let rentPeriodChanged = this.props.elementRecord ? this.props.elementRecord.rentPeriodChanged : false;

        if ((this.props.autoRecalculateShiftsCountMode || this.props.defaultShiftCount === undefined) && this.props.productRecord) {
            let shiftLengthInMin = this.props.productRecord.shiftLengthInMinutes;
            shiftCount = getShiftCountFromDates(
                this.props.productRecord.pricingScheme,
                moment(startDate),
                moment(endDate),
                shiftLengthInMin,
                getStoreState().operationForm.shiftCountRoundingType
            );
        }

        if (this.props.productRecord && this.props.startDateFromList && this.props.endDateFromList) {
            let rentPeriodStartDateMoment = moment(this.props.startDateFromList);
            let rentPeriodEndDateMoment = moment(this.props.endDateFromList);

            if (
                this.props.defaultRentPeriodStartDate &&
                rentPeriodStartDateMoment.valueOf() !== this.props.defaultRentPeriodStartDate.getTime() &&
                this.props.defaultRentPeriodEndDate &&
                rentPeriodEndDateMoment.valueOf() !== this.props.defaultRentPeriodEndDate.getTime()
            ) {
                rentPeriodChanged = true;
            }
            if (this.props.defaultShiftCount !== shiftCount) shiftCountChanged = true;

            startDate = rentPeriodStartDateMoment.toDate();
            endDate = rentPeriodEndDateMoment.toDate();
        }

        this.state = {
            selectedInstances: this.props.elementRecord?.instanceIds,

            pricePerShift: this.props.productRecord
                ? this.props.productRecord.pricePerShift
                : this.props.elementRecord
                ? this.props.elementRecord.effectivePrice
                : 0,

            discount: this.props.elementRecord ? this.props.elementRecord.discount : this.props.defaultDiscount,
            shiftCount: shiftCount || 0,

            startDate: startDate,
            endDate: endDate,

            shiftCountChanged: shiftCountChanged,
            discountChanged: discountChanged,
            rentPeriodChanged: rentPeriodChanged,

            productId: this.props.productRecord
                ? this.props.productRecord.productId
                : this.props.elementRecord
                ? this.props.elementRecord.productId
                : 0,
            variantId: this.props.productRecord
                ? this.props.productRecord.variantId
                : this.props.elementRecord
                ? this.props.elementRecord.variantId
                : undefined,
            instanceAddModalVisible: false,
            anonymousInstanceCount: this.props.elementRecord ? this.props.elementRecord.anonymousInstanceCount : undefined,
            priceChanged: false,
            saving: false,
            isLoading: true,
            visible: true,
        };
    }

    close = () => {
        this.props.onCloseCB();
        this.setState({ visible: false });
    };

    componentDidMount = async () => {
        this.setState({ isLoading: true });
        try {
            await this.updateNomenclatures();
        } catch (error) {
            console.error(error);
        }
        this.setState({ isLoading: false });
    };

    componentDidUpdate = async (prevProps: IProps, prevState: IState) => {
        if (
            this.state.startDate !== prevState.startDate ||
            this.state.endDate !== prevState.endDate// ||
            //this.state.selectedInstances !== prevState.selectedInstances
        ) {
            await this.updateNomenclatures();
        }
    };

    updateNomenclatures = async () => {
        const rentPeriodStartDate = this.state.startDate;
        const rentPeriodEndDate = this.state.endDate;
        // Скачать и сохранить нужные карты
        let nomenclatureData: NomenclatureBaseObj | undefined;
        if (this.props.productRecord) nomenclatureData = rentElementToNomenclatureBaseObj(this.props.productRecord);
        else if (this.props.elementRecord) nomenclatureData = rentElementToNomenclatureBaseObj(this.props.elementRecord);
        const [from, until] = getDateIntervalsForCalendar(rentPeriodStartDate, rentPeriodEndDate);

        const nomenclatures: NomenclatureOnInterval[] = [];
        if (!nomenclatureData) throw new Error('Error');
        nomenclatures.push({ ...nomenclatureData, from, until });
        if (this.state.selectedInstances) {
            this.state.selectedInstances.forEach((id) => {
                nomenclatures.push({ id, type: NomenclatureEntityTypeCodeEnum.INSTANCE, from, until });
            });
        }
        const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
        getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));
    };

    onInstanceCountChangedAnonymous = (value: number | undefined) => {
        let instanceCount = value && isNumber(value) ? +value : 0;
        let anonymousInstanceCount = instanceCount - (this.state.selectedInstances?.length || 0);
        this.setState({
            instanceCount: value && isNumber(value) ? +value : 0,
            anonymousInstanceCount: anonymousInstanceCount,
        });
    };

    onInstanceCountChanged = (value: number | undefined) => {
        this.setState({
            instanceCount: value && isNumber(value) ? +value : 0,
        });
    };

    getPricingScheme = () => {
        let pricingScheme = this.props.productRecord?.pricingScheme || this.props.elementRecord?.pricingSchemeId || undefined;
        if (this.state.priceChanged) pricingScheme = undefined;
        return pricingScheme;
    };

    onRentPeriodChanged = (dates: RangePickerValue) => {
        let startDate = dates[0] ? dates[0].toDate() : undefined;
        let endDate = dates[1] ? dates[1].toDate() : undefined;

        let shiftLengthInMin = this.props.productRecord
            ? this.props.productRecord.shiftLengthInMinutes
            : this.props.elementRecord
            ? this.props.elementRecord.shiftLengthInMinutes
            : undefined;

        const pricingScheme = this.getPricingScheme();

        let diff =
            startDate && endDate
                ? getShiftCountFromDates(pricingScheme, moment(startDate), moment(endDate), shiftLengthInMin, this.props.shiftCountRoundingType)
                : 0;
        let rentPeriodChanged =
            startDate?.valueOf() !== this.props.defaultRentPeriodStartDate?.valueOf() ||
            endDate?.valueOf() !== this.props.defaultRentPeriodEndDate?.valueOf();
        this.setState({
            rentPeriodChanged,
            startDate: startDate,
            endDate: endDate,
            shiftCount: this.props.recalculateShiftCount ? diff : this.state.shiftCount,
            shiftCountChanged: this.props.recalculateShiftCount ? true : this.state.shiftCountChanged,
        });
    };

    onShiftCountChanged = (value: number | undefined) => {
        let shiftCountChanged = this.props.defaultShiftCount !== value;
        if (this.props.autoRecalculateShiftsCountMode) {
            if (this.props.defaultShiftCount === value) shiftCountChanged = true;
        }

        this.setState({ shiftCount: value || 0, shiftCountChanged });
    };

    onDiscountChanged = (value: number | undefined) => {
        if (value !== undefined && value > 100) value = 100;
        this.setState({ discount: value || 0, discountChanged: this.props.defaultDiscount !== value });
    };

    onCancelClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.props.onCloseCB();
    };

    onClose = () => {
        this.props.onCloseCB();
    };

    onAddButtonClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        // Собрать инфу со всех полей
        this.setState({ saving: true });
        try {
            let instanceCount = this.state.instanceCount === undefined ? this.props.instanceCount : this.state.instanceCount,
                anonymousInstanceCount =
                    this.state.anonymousInstanceCount === undefined
                        ? this.props.elementRecord?.anonymousInstanceCount
                        : this.state.anonymousInstanceCount,
                rentPeriodEndDate = this.state.endDate,
                rentPeriodStartDate = this.state.startDate,
                discount = this.state.discount,
                shiftCount = this.state.shiftCount,
                pricePerShift = this.state.pricePerShift,
                shiftCountChanged = this.state.shiftCountChanged,
                discountChanged = this.state.discountChanged,
                rentPeriodChanged = this.state.rentPeriodChanged;

            let instanceTracked =
                this.props.productRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                this.props.productRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                this.props.elementRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
                this.props.elementRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED;

            if (instanceTracked && this.state.selectedInstances) {
                instanceCount = this.state.selectedInstances.length + (anonymousInstanceCount || 0);
            }

            if (this.props.defaultShiftCount === undefined) {
                let shiftLengthInMin = this.props.productRecord
                    ? this.props.productRecord.shiftLengthInMinutes
                    : this.props.elementRecord
                    ? this.props.elementRecord.shiftLengthInMinutes
                    : undefined;
                const pricingScheme = this.getPricingScheme();
                let newShiftCount =
                    this.props.defaultRentPeriodStartDate && this.props.defaultRentPeriodEndDate
                        ? getShiftCountFromDates(
                              pricingScheme,
                              moment(this.props.defaultRentPeriodStartDate),
                              moment(this.props.defaultRentPeriodEndDate),
                              shiftLengthInMin,
                              this.props.shiftCountRoundingType
                          )
                        : 0;
                if (newShiftCount === shiftCount) shiftCountChanged = false;
            }

            // Скачать и сохранить нужные карты
            await this.updateNomenclatures();

            if (this.props.mode === 'create' && this.props.productRecord && rentPeriodStartDate && rentPeriodEndDate) {
                let el: OperationElement = {
                    id: 0,
                    businessVersion: 0,
                    productId: this.props.productRecord.productId,

                    productShortName: this.props.productRecord.productShortName,
                    productMainImage: this.props.productRecord.mainImage ? { ...this.props.productRecord.mainImage } : undefined,
                    mainImage: this.props.productRecord.mainImage ? { ...this.props.productRecord.mainImage } : undefined,
                    stateCode: undefined,
                    rentPeriodStartDate: rentPeriodStartDate,
                    rentPeriodEndDate: rentPeriodEndDate,
                    shiftCount: shiftCount,
                    calendarShiftCount: shiftCount,
                    discount: discount,
                    // Доступно 100/1000
                    instanceCount: instanceCount,
                    instanceCountOriginal: instanceCount,
                    finalTotalPrice: 0,

                    effectivePrice: pricePerShift,

                    //stockInstanceCountX: this.props.productRecord.stockInstanceCount,
                    availableInstanceCount: this.props.productRecord.availableInstanceCount,

                    discountChanged: discountChanged,
                    rentPeriodChanged: rentPeriodChanged,
                    shiftCountChanged: shiftCountChanged,
                    disabled: false,
                    instanceIds: this.state.selectedInstances,
                    variantId: this.props.productRecord.variantId,
                    variantName: this.props.productRecord.variantName,
                    instanceTrackingTypeCode: this.props.productRecord.instanceTrackingTypeCode,

                    anonymousInstanceCount:
                        this.props.productRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                        this.props.productRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                            ? anonymousInstanceCount || 0
                            : instanceCount,
                    anonymousInstanceCountOriginal:
                        this.props.productRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
                        this.props.productRecord.instanceTrackingTypeCode == InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
                            ? anonymousInstanceCount || 0
                            : instanceCount,

                    leftoverInstanceCount: 0,
                    leftoverAnonymousInstanceCount: 0,
                    keepLeftover: undefined,

                    warnings: [],
                    problems: [],

                    variantIdOriginal: this.props.productRecord.variantId,
                    shiftLengthInMinutes: this.props.productRecord.shiftLengthInMinutes,
                    hasOwnShiftLength: this.props.productRecord.productHasOwnShiftLength,

                    requiredTimeIndentBetweenElementsInMinutes: this.props.productRecord.requiredTimeIndentBetweenElementsInMinutes,
                    productHasOwnRequiredTimeIndentBetweenElements: this.props.productRecord.productHasOwnRequiredTimeIndentBetweenElements,
                    externalCode: this.props.productRecord.externalCode,

                    pricingSchemeId: !this.state.priceChanged ? this.props.productRecord.pricingScheme : undefined, //
                };
                await this.setState({ visible: false });
                this.props.addElement(el);
                this.props.onCloseCB();
            } else if (this.props.mode === 'edit' && this.props.elementRecord && rentPeriodStartDate && rentPeriodEndDate) {
                await this.setState({ visible: false });
                this.props.editElement(
                    this.props.elementRecord.id,
                    rentPeriodStartDate,
                    rentPeriodEndDate,
                    discount,
                    shiftCount,
                    instanceCount,
                    discountChanged,
                    shiftCountChanged,
                    rentPeriodChanged,
                    pricePerShift,
                    this.state.selectedInstances,
                    anonymousInstanceCount,
                    !this.state.priceChanged
                        ? this.props.productRecord?.pricingScheme || this.props.elementRecord?.pricingSchemeId || undefined
                        : undefined
                );
                this.props.onCloseCB();
            } else if (
                this.props.mode === 'copy' &&
                rentPeriodStartDate &&
                rentPeriodEndDate &&
                this.props.elementRecord &&
                this.props.elementRecord.id
            ) {
                let el: OperationElement = {
                    id: -1,
                    businessVersion: 0,
                    productId: this.props.elementRecord.productId,

                    productShortName: this.props.elementRecord.productShortName,
                    productMainImage: this.props.elementRecord.productMainImage
                        ? { ...this.props.elementRecord.productMainImage }
                        : undefined,
                    mainImage: this.props.elementRecord.mainImage ? { ...this.props.elementRecord.mainImage } : undefined,
                    stateCode: undefined,
                    rentPeriodStartDate: rentPeriodStartDate,
                    rentPeriodEndDate: rentPeriodEndDate,
                    shiftCount: shiftCount,
                    calendarShiftCount: shiftCount,
                    discount: discount,
                    // Доступно 100/1000
                    instanceCount: instanceCount,
                    instanceCountOriginal: instanceCount,
                    finalTotalPrice: 0,

                    effectivePrice: pricePerShift,

                    //stockInstanceCountX: this.props.elementRecord.stockInstanceCountX,
                    availableInstanceCount: this.props.elementRecord.availableInstanceCount,

                    discountChanged: discountChanged,
                    rentPeriodChanged: rentPeriodChanged,
                    shiftCountChanged: shiftCountChanged,
                    disabled: false,
                    instanceIds: this.state.selectedInstances ? [...this.state.selectedInstances] : this.state.selectedInstances,
                    variantId: this.props.elementRecord.variantId,
                    variantName: this.props.elementRecord.variantName,
                    instanceTrackingTypeCode: this.props.elementRecord.instanceTrackingTypeCode,

                    anonymousInstanceCount: anonymousInstanceCount || 0,
                    anonymousInstanceCountOriginal: anonymousInstanceCount || 0,

                    leftoverInstanceCount: 0,
                    leftoverAnonymousInstanceCount: 0,
                    keepLeftover: undefined,

                    warnings: [],
                    problems: [],

                    variantIdOriginal: this.props.elementRecord.variantId,
                    shiftLengthInMinutes: this.props.elementRecord.shiftLengthInMinutes,
                    hasOwnShiftLength: this.props.elementRecord.hasOwnShiftLength,

                    requiredTimeIndentBetweenElementsInMinutes: this.props.elementRecord.requiredTimeIndentBetweenElementsInMinutes,
                    productHasOwnRequiredTimeIndentBetweenElements: this.props.elementRecord.productHasOwnRequiredTimeIndentBetweenElements,

                    externalCode: this.props.elementRecord.externalCode,

                    pricingSchemeId: !this.state.priceChanged ? this.props.elementRecord.pricingSchemeId : undefined,
                };
                await this.setState({ visible: false });
                this.props.addElement(el);
                this.props.onCloseCB();
            }
        } catch (error) {
            showNotification('error', 'Ошибка');
            console.error(error);
        }
        this.setState({ saving: false });
    };

    resetPeriod = (e) => {
        this.setState({
            rentPeriodChanged: false,
            startDate: this.props.defaultRentPeriodStartDate,
            endDate: this.props.defaultRentPeriodEndDate,
            shiftCount: this.props.defaultShiftCount || 0,
            shiftCountChanged: false,
        });
    };

    onSelectChange = (value: SelectValue, option: React.ReactElement<any> | React.ReactElement<any>[], metaKey: boolean) => {
        if (metaKey) {
            this.setState({
                selectedInstances: value as number[],
                instanceCount: ((value as number[]).length || 0) + (this.state.anonymousInstanceCount || 0),
            });
        } else {
            let anonymousInstanceCount = 0;
            let instanceCount = this.state.instanceCount === undefined ? this.props.instanceCount : this.state.instanceCount;
            const anonymous =
                this.state.anonymousInstanceCount !== undefined
                    ? this.state.anonymousInstanceCount
                    : this.props.elementRecord?.anonymousInstanceCount || 0;
            const diff = (value as number[]).length - (this.state.selectedInstances?.length || 0);
            if (diff > 0) {
                //добавили
                if (anonymous > 0) {
                    anonymousInstanceCount = anonymous - diff;
                    if (anonymousInstanceCount < 0) {
                        anonymousInstanceCount = 0;
                    }
                } else {
                    anonymousInstanceCount = 0;
                    instanceCount = instanceCount + diff;
                }
            } else if (diff < 0) {
                //убрали
                anonymousInstanceCount = anonymous + 1;
            }

            this.setState({
                selectedInstances: value as number[],
                anonymousInstanceCount: anonymousInstanceCount,
                instanceCount: instanceCount,
            });
        }
    };

    onAddInstanceButtonClick = () => {
        this.setState({
            instanceAddModalVisible: true,
        });
    };

    render() {
        let { canViewFinancialData, operationTypeCode, productRecord, elementRecord, projectTemplate, isSimpleOrder } = this.props;

        let pricingScheme = this.getPricingScheme();

        let hasWarning = false;
        let hasProblem = false;
        let applyButtonStyles = '';

        const instanceTracked =
            productRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
            productRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED ||
            elementRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED ||
            elementRecord?.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.INSTANCETRACKED;

        let rentPeriodStartDate = this.state.startDate,
            rentPeriodEndDate = this.state.endDate;

        let { discount, shiftCount, shiftCountChanged, discountChanged, rentPeriodChanged } = this.state;

        const pricePerShiftOriginal = productRecord
            ? productRecord.pricePerShift
            : elementRecord
            ? elementRecord.effectivePrice
            : 0;
        const pricePerShift = pricingScheme
            ? PricingSchemeUtils.calculatePriceByPricingScheme({
                  shiftCount,
                  basePrice: pricePerShiftOriginal,
                  pricingScheme,
              })
            : this.state.pricePerShift;

        let shiftLengthInMin = productRecord
            ? productRecord.shiftLengthInMinutes
            : elementRecord
            ? elementRecord.shiftLengthInMinutes
            : undefined;

        let requiredTimeIndentBetweenElementsInMinutes = productRecord
            ? productRecord.requiredTimeIndentBetweenElementsInMinutes
            : elementRecord
            ? elementRecord.requiredTimeIndentBetweenElementsInMinutes
            : undefined;
        let productHasOwnRequiredTimeIndentBetweenElements =
            elementRecord?.productHasOwnRequiredTimeIndentBetweenElements ||
            productRecord?.productHasOwnRequiredTimeIndentBetweenElements;

        let intervals: [number, number][] = [];

        if (
            productRecord &&
            this.props.startDateFromList &&
            this.props.endDateFromList &&
            this.state.startDate === undefined &&
            this.state.endDate === undefined
        ) {
            let rentPeriodStartDateMoment = moment(this.props.startDateFromList);
            let rentPeriodEndDateMoment = moment(this.props.endDateFromList);
            if (
                this.props.defaultRentPeriodStartDate &&
                rentPeriodStartDateMoment.valueOf() !== this.props.defaultRentPeriodStartDate.getTime() &&
                this.props.defaultRentPeriodEndDate &&
                rentPeriodEndDateMoment.valueOf() !== this.props.defaultRentPeriodEndDate.getTime()
            )
                rentPeriodChanged = true;
            if (this.props.defaultShiftCount !== shiftCount) shiftCountChanged = true;

            rentPeriodStartDate = rentPeriodStartDateMoment.toDate();
            rentPeriodEndDate = rentPeriodEndDateMoment.toDate();
        }

        let includeInstanceIds =
            elementRecord?.instanceIdsOriginal &&
            elementRecord?.id >= 0 &&
            !canAddInstancesToExistedElement(this.props.operationTypeCorrectionCode || operationTypeCode)
                ? elementRecord.instanceIdsOriginal
                : undefined;

        //
        let shiftCountWarning = false;
        if (
            this.props.operationShiftCountWarningsEnabled &&
            canBeErrorWhenShiftCountMoreThanPeriod(operationTypeCode, this.props.operationTypeCorrectionCode) &&
            shiftCount >
                getShiftCountFromDates(
                    pricingScheme,
                    moment(rentPeriodStartDate),
                    moment(rentPeriodEndDate),
                    shiftLengthInMin,
                    this.props.shiftCountRoundingType
                )
        )
            shiftCountWarning = true;

        // Можем ли создать/Изменить обязательство с нехваткой
        // Допустима_нехватка = тип_операции IN (корректировка, заявка, возвратЫ)
        const canSaveWithShortage = operationWithShortage(operationTypeCode);

        // Кол-во Анонимных
        const anonymousInstanceCount =
            this.state.anonymousInstanceCount === undefined
                ? elementRecord?.anonymousInstanceCount
                : this.state.anonymousInstanceCount;
        // Кол-во экземпляров
        let instanceCount = this.state.instanceCount === undefined ? this.props.instanceCount : this.state.instanceCount;
        if (instanceTracked) instanceCount = (this.state.selectedInstances?.length || 0) + (anonymousInstanceCount || 0);

        let availableInstanceCount = 0;
        let availableInstanceCountX: number | undefined;

        let timeTable = findTimeTable(this.props.timeTables, {
            productId: this.state.productId,
            variantId: this.state.variantId,
        });

        let availabilityFactor = this.props.isSubrentOperation ? -1 : 1;

        if (timeTable && rentPeriodStartDate && rentPeriodEndDate) {
            let map = isOrderOperation(operationTypeCode) ? timeTable.orderAvailable : timeTable.available1;

            logStackMap(
                {
                    productId: timeTable.productId,
                    kitId: timeTable.kitId,
                    variantId: timeTable.variantId,
                    map: map,
                    comment: 'Карта в попапе, начальное состояние',
                },
                true
            );

            intervals = map;

            const requiredIndent = (requiredTimeIndentBetweenElementsInMinutes || 0) * 60 * 1000;

            let rentPeriodStartDate1 = rentPeriodStartDate;
            let rentPeriodEndDate1 = rentPeriodEndDate;

            if (!elementRecord || (elementRecord && this.props.mode === 'copy')) {
                // Создание в продуктах или копирование в Инвентаре

                let availableIntervals = ProductUtils.getNewIntervals(intervals, []);

                availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                    availableIntervals /*intervals*/,
                    rentPeriodStartDate1.getTime() - requiredIndent,
                    rentPeriodEndDate1.getTime() + requiredIndent
                );

                // Вычитаем столько сколько хотим добавить
                intervals = ProductUtils.getNewIntervals(intervals, [
                    [availabilityFactor * instanceCount, rentPeriodStartDate1.getTime(), rentPeriodEndDate1.getTime()],
                ]);
            } else if (elementRecord && elementRecord.id < 0) {
                // Для НОВОГО нужно компенсировать что вычли из карты
                intervals = ProductUtils.getNewIntervals(intervals, [
                    // Компенсируем что было в начале
                    [
                        availabilityFactor * -elementRecord.instanceCount,
                        elementRecord.rentPeriodStartDate.getTime(),
                        elementRecord.rentPeriodEndDate.getTime(),
                    ],
                ]);

                let availableIntervals = ProductUtils.getNewIntervals(intervals, []);

                // Редактирование нового
                availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                    availableIntervals,
                    rentPeriodStartDate1.getTime() - requiredIndent,
                    rentPeriodEndDate1.getTime() + requiredIndent
                );

                // Вычитаем столько сколько хотим добавить
                intervals = ProductUtils.getNewIntervals(intervals, [
                    [availabilityFactor * instanceCount, rentPeriodStartDate1.getTime(), rentPeriodEndDate1.getTime()],
                ]);
            } else if (
                elementRecord &&
                elementRecord.rentPeriodStartDateOriginal &&
                elementRecord.rentPeriodEndDateOriginal
            ) {
                // Редактирование существующего
                // Для заявки и черновика нужно компенсировать что пришло с сервера

                let elementIntervals: [number, number, number, string][] = [];

                let isElementReducesAvailability = getIsElementReducesAvailability(elementRecord);
                let isCorrectCancelOperation = getIsCorrectCancelOperation(
                    operationTypeCode,
                    this.props.operationTypeCorrectionCode
                );
                let leftoverInstances = getLeftoverInstances(
                    elementRecord.instanceIdsOriginal,
                    elementRecord.instanceIds
                );
                elementIntervals.push([
                    availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * -elementRecord.instanceCount,
                    elementRecord.rentPeriodStartDate.getTime(),
                    elementRecord.rentPeriodEndDate.getTime(),
                    'Компенсируем текущее значение',
                ]);
                let leftover = Math.max(
                    0,
                    elementRecord.instanceCountOriginal - elementRecord.instanceCount,
                    leftoverInstances.length
                );
                if (elementRecord.keepLeftover)
                    elementIntervals.push([
                        availabilityFactor *
                            ((isElementReducesAvailability || elementRecord.stateCode === RentStateCodeEnum.ORDERED) &&
                            elementRecord.variantId === elementRecord.variantIdOriginal
                                ? 1
                                : 0) *
                            -leftover,
                        elementRecord.rentPeriodStartDateOriginal.getTime(),
                        elementRecord.rentPeriodEndDateOriginal.getTime(),
                        'Компенсируем остаток' +
                            [availabilityFactor, isElementReducesAvailability, elementRecord.variantId === elementRecord.variantIdOriginal ? 1 : 0],
                    ]);

                elementIntervals.forEach((item) => {
                    if (elementRecord) {
                        logStackMap({
                            productId: elementRecord.productId,
                            kitId: elementRecord.kitId,
                            variantId: elementRecord.variantId,
                            intervals: [[item[0], item[1], item[2]]],
                            comment: item[3],
                        });
                    }
                });

                intervals = ProductUtils.getNewIntervals(intervals, elementIntervals);

                availableInstanceCount = ProductUtils.getMinimumValueInInterval(
                    intervals,
                    rentPeriodStartDate1.getTime() - requiredIndent,
                    rentPeriodEndDate1.getTime() + requiredIndent
                );

                elementIntervals = [];
                elementIntervals.push([
                    availabilityFactor * (isCorrectCancelOperation ? 0 : 1) * instanceCount,
                    rentPeriodStartDate1.getTime(),
                    rentPeriodEndDate1.getTime(),
                    'Вычитаем текущее значение',
                ]);

                leftoverInstances = getLeftoverInstances(elementRecord.instanceIdsOriginal, this.state.selectedInstances);
                leftover = Math.max(0, elementRecord.instanceCountOriginal - instanceCount, leftoverInstances.length);
                if (elementRecord.keepLeftover)
                    elementIntervals.push([
                        availabilityFactor *
                            (isElementReducesAvailability /* || elementRecord.stateCode === RentStateCodeEnum.ORDERED*/ &&
                            elementRecord.variantId === elementRecord.variantIdOriginal
                                ? 1
                                : 0) *
                            leftover,
                        elementRecord.rentPeriodStartDateOriginal.getTime(),
                        elementRecord.rentPeriodEndDateOriginal.getTime(),
                        'Вычитаем остаток' +
                            [
                                elementRecord.instanceCountOriginal - instanceCount,
                                leftoverInstances.length,
                                availabilityFactor,
                                isElementReducesAvailability,
                                elementRecord.stateCode,
                            ],
                    ]);

                elementIntervals.forEach((item) => {
                    if (elementRecord) {
                        logStackMap({
                            productId: elementRecord.productId,
                            kitId: elementRecord.kitId,
                            variantId: elementRecord.variantId,
                            intervals: [[item[0], item[1], item[2]]],
                            comment: item[3],
                        });
                    }
                });

                intervals = ProductUtils.getNewIntervals(intervals, elementIntervals);

                availableInstanceCountX = ProductUtils.getMinimumValueInInterval(
                    intervals,
                    rentPeriodStartDate1.getTime() - requiredIndent,
                    rentPeriodEndDate1.getTime() + requiredIndent
                );
            }

            // logStackMap(
            //     {
            //         productId: timeTable.productId,
            //         kitId: timeTable.kitId,
            //         variantId: timeTable.variantId,
            //         map: intervals,
            //         comment: 'Карта в попапе, конечное состояние',
            //     },
            //     true
            // );
        }

        //
        let isCreatedOperation = elementRecord && elementRecord.id >= 0;

        // Если кол-во доступных < 0 и НЕ Допустима_нехватка, то кол-во ставим в 0
        //if (availableInstanceCount < 0 && !canSaveWithShortage && !instanceTracked) instanceCount = 0;

        //
        let instanceCountMin = instanceTracked ? this.state.selectedInstances?.length || 0 : 1;

        //
        let instanceCountMax =
            operationTypeCode === OperationTypeCodeEnum.DRAFT ||
            operationTypeCode === OperationTypeCodeEnum.ORDER ||
            operationTypeCode === OperationTypeCodeEnum.BOOK ||
            operationTypeCode === OperationTypeCodeEnum.RENT ||
            operationTypeCode === OperationTypeCodeEnum.CORRECT ||
            anonymousInstanceCount !== undefined
                ? undefined
                : availableInstanceCount <= 0
                ? 0
                : availableInstanceCount;
        if (
            operationTypeCode === OperationTypeCodeEnum.SUBRENTDRAFT ||
            operationTypeCode === OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT ||
            operationTypeCode === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT
        ) {
            instanceCountMax = undefined;
        }

        if (isCreatedOperation) {
            if (
                !canAddInstancesToExistedElement(this.props.operationTypeCorrectionCode || operationTypeCode) ||
                operationTypeCode === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER ||
                operationTypeCode === OperationTypeCodeEnum.SUBRENTCANCEL ||
                operationTypeCode === OperationTypeCodeEnum.SUBRENTPROLONG
            ) {
                instanceCountMax = elementRecord?.instanceCountOriginal;
            }
        }

        if(operationTypeCode === OperationTypeCodeEnum.EDIT && canAddNewProductsToOperation2(this.props.operationForm, elementRecord)){
            instanceCountMax = anonymousInstanceCount !== undefined ? undefined : availableInstanceCount <= 0 ? 0 : availableInstanceCount;
        }

        // Есть нехватка
        let isShortage = !this.props.isSubrentOperation && instanceCount > availableInstanceCount;
        // Можем ли создать/Изменить обязательство
        // ЛОГИКА РАБОТЫ: сохранить можно == кол-во != 0 && (допустима нехватка || кол-во <= кол-во_доступных)
        let canSave = instanceCount > 0; // && (canSaveWithShortage || !isShortage);

        // Это #1025 и #1010 (3, 4)
        if (isCreatedOperation && instanceTracked && availableInstanceCountX !== undefined) {
            if (isOrderOperation(operationTypeCode)) {
                //isShortage = (instanceCount > availableInstanceCountX);
                isShortage = availableInstanceCountX < 0;
            } else {
                isShortage = availableInstanceCountX < 0;
            }
            canSave = instanceCount > 0; // && (canSaveWithShortage || !isShortage);
        }

        // Название класса, для подсветки поля ввода кол-ва экземпляров
        let headerClassName;
        const err = canBeErrorWhenShortage(operationTypeCode, this.props.operationTypeCorrectionCode, elementRecord?.stateCodeNew); // TODO EDIT !!!
        if (err && err === OperationErrorCodeEnum.WARNING && isShortage) {
            headerClassName = 'has-warning'; // Когда Допустима_нехватка и есть нехватка
            hasWarning = true;
        } else if (err && err === OperationErrorCodeEnum.PROBLEM && (instanceCount <= 0 || isShortage)) {
            headerClassName = 'has-error'; // Когда кол-во меньше или 0 или Когда НЕ Допустима_нехватка и есть нехватка
            hasProblem = true;
        }

        let anonymousProblem = false;

        if (
            instanceTracked &&
            !canCreateOperationWithAnonymousInstances((operationTypeCode === OperationTypeCodeEnum.EDIT && elementRecord?.stateCodeNew) || this.props.operationTypeCorrectionCode || operationTypeCode)
        ) {
            if (anonymousInstanceCount !== undefined && anonymousInstanceCount > 0) {
                anonymousProblem = true;
            }
        }

        // Цена без скидки, цена смены, цена со скидкой
        let priceWithoutDiscount = MoneyUtils.calculateFinalTotalPrice(pricePerShift, instanceCount, shiftCount),
            shiftPrice = MoneyUtils.calculateFinalInstancePrice(pricePerShift, discount),
            finalTotalPrice = MoneyUtils.calculateFinalTotalPrice(shiftPrice, instanceCount, shiftCount);

        let dateIntervalsProblem = false;
        if (rentPeriodEndDate && rentPeriodStartDate) {
            dateIntervalsProblem = is_OperationTimeError(rentPeriodStartDate.getTime(), rentPeriodEndDate.getTime());
            if (dateIntervalsProblem) {
                canSave = false;
            }
        }

        let disableAllElements = canEditOperationElementParams(operationTypeCode);

        if (operationTypeCode === OperationTypeCodeEnum.CORRECT) {
            instanceCountMax = undefined;
        }

        let isCancelOperation =
            operationTypeCode === OperationTypeCodeEnum.CANCEL ||
            this.props.operationTypeCorrectionCode === RentStateCodeEnum.CANCELED;

        let newShiftCount =
            this.state.startDate && this.state.endDate
                ? getShiftCountFromDates(
                    pricingScheme,
                      moment(this.state.startDate),
                      moment(this.state.endDate),
                      shiftLengthInMin,
                      this.props.shiftCountRoundingType
                  )
                : 0;

        let hasOwnShiftLength = productRecord
            ? productRecord.productHasOwnShiftLength
            : elementRecord?.hasOwnShiftLength;

        const productId = productRecord?.productId || elementRecord?.productId || 0;
        const variantId = productRecord?.variantId || elementRecord?.variantId;
        const productBusinessVersion = productRecord?.businessVersion || elementRecord?.businessVersion || 0;

        if (shiftCountWarning && !projectTemplate) hasWarning = true;
        if (instanceTracked && anonymousInstanceCount !== undefined && anonymousInstanceCount > 0) {
            if (!canCreateOperationWithAnonymousInstances((operationTypeCode === OperationTypeCodeEnum.EDIT && elementRecord?.stateCodeNew) || this.props.operationTypeCorrectionCode || operationTypeCode)) {
                hasProblem = true;
            } else {
                //hasWarning = true;
            }
        }

        if (anonymousProblem && !hasProblem) hasWarning = true;

        if(!timeTable){
            hasWarning = false;
            hasProblem = false;
            canSave = false;
            headerClassName = undefined;
        }

        if (hasProblem) applyButtonStyles = 'rr-operationForm-element-create-popover_apply-button_with-problem';
        else if (hasWarning) applyButtonStyles = 'rr-operationForm-element-create-popover_apply-button_with-warning';
        if (!canSave) applyButtonStyles = '';

        if (this.props.defaultShiftCount === undefined || this.props.autoRecalculateShiftsCountMode) {
            if (newShiftCount === shiftCount) shiftCountChanged = false;
        }

        return (
            <PopoverContent
                spinning={this.state.isLoading}
                onClose={this.onClose}
                headerClassName={headerClassName}
                header={
                    <>
                        <span
                            style={{
                                marginRight: 17 + 18,
                                fontSize: 16,
                                fontWeight: 700,
                            }}
                        >
                            {this.props.mode !== 'copy' && elementRecord && elementRecord.id > -1
                                ? `Обязательство #${elementRecord?.numberInActivityFrame}`
                                : `Новое обязательство`}
                        </span>
                        {
                            <>
                                <NumberInput
                                    onChange={instanceTracked ? this.onInstanceCountChangedAnonymous : this.onInstanceCountChanged}
                                    parser={(v) => ('' + v).replace(/[^\d]/g, '')}
                                    min={instanceCountMin}
                                    max={instanceCountMax}
                                    value={instanceCount}
                                    className={'rr-input-number'}
                                    style={{ width: 92 }}
                                />
                                {!isCancelOperation && !this.props.isSubrentOperation && (
                                    <>
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                marginLeft: 2,
                                                fontSize: 16,
                                            }}
                                        >
                                            {localize(LocalizationEnum.ASPECT__GLOBAL__SUBSET_TERM_OF)}
                                        </span>
                                        <span>&nbsp;</span>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: 17,
                                            }}
                                        >
                                            {dateIntervalsProblem ? '?' : availableInstanceCount}
                                        </span>
                                        <span>&nbsp;</span>
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                fontSize: 16,
                                            }}
                                        >
                                            {!projectTemplate ? `доступных` : `в инвентаре`}
                                        </span>
                                    </>
                                )}
                                {isCreatedOperation && !isCancelOperation && !this.props.isSubrentOperation ? (
                                    <span
                                        style={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                        }}
                                    >
                                        &nbsp;{`(${elementRecord?.instanceCountOriginal} в обяз.)`}
                                    </span>
                                ) : null}
                                {(isCancelOperation || (this.props.isSubrentOperation && isCreatedOperation)) && (
                                    <>
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                marginLeft: 2,
                                                fontSize: 16,
                                            }}
                                        >
                                            {localize(LocalizationEnum.ASPECT__GLOBAL__SUBSET_TERM_OF)}
                                        </span>
                                        <span>&nbsp;</span>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: 17,
                                            }}
                                        >
                                            {elementRecord?.instanceCountOriginal}
                                        </span>
                                        <span>&nbsp;</span>
                                        <span style={{ fontWeight: 400, fontSize: 16 }}>{`в обязательстве`}</span>
                                    </>
                                )}
                            </>
                        }
                    </>
                }
                footer={
                    <>
                        <Button className={'rr-btn-default'} onClick={this.onCancelClick}>
                            {localize(LocalizationEnum.ASPECT__GLOBAL__CANCEL)}
                        </Button>
                        <Button
                            loading={this.state.saving}
                            disabled={!canSave}
                            type={'primary'}
                            onClick={this.onAddButtonClick}
                            className={applyButtonStyles}
                        >
                            {'Применить'}
                        </Button>
                    </>
                }
                content={
                    <>
                        <Row style={{ padding: 32 }}>
                            {instanceTracked ? (
                                <Col className="rr-form-item">
                                    <div className="rr-label" style={{ marginBottom: 10, height: 20 }}>
                                        <span style={{ display: 'inline-block', marginTop: 2 }}>{'Экземпляры'}</span>
                                        <GridRowActions1
                                            actions={[ActionTypeEnum.REPLACE_ANONYMOUS_INSTANCES_WITH_AVAILABLE__IN_RENT_ELEMENT]}
                                            onAction={(type) => {
                                                if (type === ActionTypeEnum.REPLACE_ANONYMOUS_INSTANCES_WITH_AVAILABLE__IN_RENT_ELEMENT) {
                                                    this.select0.replaceAnonymous(this.state.anonymousInstanceCount);
                                                } else {
                                                }
                                            }}
                                        >
                                            {anonymousInstanceCount !== undefined && anonymousInstanceCount > 0 ? (
                                                <span
                                                    style={{
                                                        color:
                                                            headerClassName === 'has-error' || anonymousProblem
                                                                ? '#f8516c'
                                                                : headerClassName === 'has-warning'
                                                                ? '#ff8d3a'
                                                                : '#525891',
                                                        marginLeft: 120,
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 600,
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {anonymousInstanceCount}?
                                                    </span>{' '}
                                                    {localize(LocalizationEnum.ASPECT__PLURAL__ANONYMOUS, undefined, {
                                                        value: anonymousInstanceCount,
                                                    })}
                                                </span>
                                            ) : null}
                                        </GridRowActions1>
                                    </div>
                                    <div className="rr-value">
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flexGrow: 1 }}>
                                                <ProductInstancesSelect
                                                    ref={(ref) => {
                                                        if (!this.select0) this.select0 = ref;
                                                    }}
                                                    elementRecord={elementRecord}
                                                    variantId={productRecord?.variantId || elementRecord?.variantId}
                                                    productId={productId}
                                                    value={this.state.selectedInstances}
                                                    onChange={this.onSelectChange}
                                                    startDate={rentPeriodStartDate ? rentPeriodStartDate.getTime() : 0}
                                                    endDate={rentPeriodEndDate ? rentPeriodEndDate.getTime() : 0}
                                                    canUpdate={!this.state.saving}
                                                    includeIds={includeInstanceIds}
                                                    copyMode={this.props.mode === 'copy'}
                                                    requiredTimeIndentBetweenElementsInMinutes={requiredTimeIndentBetweenElementsInMinutes}
                                                />
                                            </div>
                                            <div>
                                                {this.props.isSubrentOperation &&
                                                    canAddNewProductsToOperation2(this.props.operationForm, this.props.elementRecord) && (
                                                        <Button
                                                            style={{
                                                                backgroundColor: '#21bfa4',
                                                                borderRadius: 4,
                                                                marginLeft: 10,
                                                                padding: '0 16px',
                                                            }}
                                                            onClick={this.onAddInstanceButtonClick}
                                                        >
                                                            <Icon style={{ color: '#ffffff' }} component={IconPlus} />
                                                        </Button>
                                                    )}
                                            </div>
                                        </div>
                                        {this.state.instanceAddModalVisible ? (
                                            <InstanceQuickCreateModal
                                                productBusinessVersion={productBusinessVersion}
                                                productId={productId}
                                                variantId={variantId}
                                                supplierId={this.props.renterId}
                                                onClose={() => {
                                                    this.setState({ instanceAddModalVisible: false });
                                                }}
                                                onInstanceCreated={async (instance: InstanceInfoRead) => {
                                                    await this.select0.loadData();
                                                    this.setState({
                                                        selectedInstances: [...(this.state.selectedInstances || []), instance.id],
                                                        anonymousInstanceCount:
                                                            this.state.anonymousInstanceCount && this.state.anonymousInstanceCount > 0
                                                                ? this.state.anonymousInstanceCount - 1
                                                                : this.state.anonymousInstanceCount,
                                                    });
                                                }}
                                                title={'Быстрое создание экземпляра'}
                                                intl={this.props.intl}
                                            />
                                        ) : null}
                                    </div>
                                </Col>
                            ) : null}

                            {!projectTemplate && (
                                <Col className="rr-form-item">
                                    <div className="rr-label" style={{ marginBottom: 10 }}>
                                        {localize(LocalizationEnum.ASPECT__RENT_BUSINESS__RENT_PERIOD)}
                                    </div>
                                    {/*<div className="rr-value" style={{ display: 'flex', alignItems: 'center' }}>*/}
                                    {/*    <CustomRangePicker*/}
                                    {/*        allowClear={false}*/}
                                    {/*        intervals={intervals}*/}
                                    {/*        showTime*/}
                                    {/*        disabled={disableAllElements}*/}
                                    {/*        className={classNames(*/}
                                    {/*            rentPeriodChanged*/}
                                    {/*                ? 'rr-operation-element-create-popover-param-changed'*/}
                                    {/*                : 'rr-operation-element-create-popover-param-not-changed',*/}
                                    {/*            dateIntervalsProblem ? 'rr-operation-element-create-popover-param-error' : undefined*/}
                                    {/*        )}*/}
                                    {/*        value={[moment(rentPeriodStartDate), moment(rentPeriodEndDate)]}*/}
                                    {/*        calendarValue={calendarValue}*/}
                                    {/*        onChange={this.onRentPeriodChanged}*/}
                                    {/*        onOpenChange={this.props.onCalendarVisibleChangeCB}*/}
                                    {/*        shiftLengthInMin={shiftLengthInMin}*/}
                                    {/*        hasOwnShiftLength={hasOwnShiftLength}*/}
                                    {/*        requiredTimeIndentBetweenElementsInMinutes={requiredTimeIndentBetweenElementsInMinutes}*/}
                                    {/*        productHasOwnRequiredTimeIndentBetweenElements={productHasOwnRequiredTimeIndentBetweenElements}*/}
                                    {/*        shiftCount={this.state.shiftCount}*/}
                                    {/*        align={{ points: ['bl', 't'], offset: [0, -6] }}*/}
                                    {/*    />*/}
                                    {/*    <OperationParamResetButton*/}
                                    {/*        changed={rentPeriodChanged}*/}
                                    {/*        onChange={() => {*/}
                                    {/*            this.resetPeriod(null);*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="rr-value" style={{ display: 'flex', alignItems: 'center' }}>
                                        <RangePicker
                                            style={{width: 408}}
                                            allowClear={false}
                                            intervals={intervals}
                                            showTime
                                            disabled={disableAllElements}
                                            selectionDisabled={this.props.isSimpleOrder}
                                            className={classNames(
                                                rentPeriodChanged
                                                    ? 'rr-operation-element-create-popover-param-changed'
                                                    : 'rr-operation-element-create-popover-param-not-changed',
                                                dateIntervalsProblem ? 'rr-operation-element-create-popover-param-error' : undefined
                                            )}
                                            value={[moment(rentPeriodStartDate), moment(rentPeriodEndDate)]}
                                            //calendarValue={calendarValue}
                                            onChange={this.onRentPeriodChanged as any}
                                            //onOpenChange={this.props.onCalendarVisibleChangeCB}
                                            //shiftLengthInMin={shiftLengthInMin}
                                            //hasOwnShiftLength={hasOwnShiftLength}
                                            //requiredTimeIndentBetweenElementsInMinutes={requiredTimeIndentBetweenElementsInMinutes}
                                            //productHasOwnRequiredTimeIndentBetweenElements={productHasOwnRequiredTimeIndentBetweenElements}
                                            shiftCount={this.state.shiftCount}
                                            //align={{ points: ['bl', 't'], offset: [0, -6] }}
                                            requiredTimeIndentProps={{
                                                minutes: requiredTimeIndentBetweenElementsInMinutes,
                                                hasOwnIndent: productHasOwnRequiredTimeIndentBetweenElements
                                            }}
                                            recalculateShiftCountBlockProps={{
                                                shiftLengthInMin: shiftLengthInMin,
                                                hasOwnShiftLength: hasOwnShiftLength,
                                                shiftLengthHint: undefined,
                                                fromShiftCount: this.state.shiftCount
                                            }}
                                            getTodayDate={()=>{
                                                return moment(this.props.operationStartTime)
                                            }}
                                            pricingScheme={pricingScheme}
                                        />
                                        <OperationParamResetButton
                                            changed={rentPeriodChanged && !this.props.isSimpleOrder}
                                            onChange={() => {
                                                this.resetPeriod(null);
                                            }}
                                        />
                                    </div>
                                </Col>
                            )}

                            {
                                <Col span={12} className="rr-form-item" style={{ marginBottom: 0 }}>
                                    <div className="rr-label" style={{ marginBottom: 10 }}>
                                        {localize(LocalizationEnum.PAGE__OPERATIONS__CARD__SHIFT_COUNT)}
                                    </div>
                                    <div className={'rr-value'} style={{ display: 'flex', alignItems: 'center' }}>
                                        <NumberInputShiftCount
                                            className={
                                                (shiftCountChanged
                                                    ? ' rr-operation-element-create-popover-param-changed'
                                                    : ' rr-operation-element-create-popover-param-not-changed') +
                                                (shiftCountWarning && !projectTemplate
                                                    ? ' rr-operation-element-create-popover-param-warning'
                                                    : '')
                                            }
                                            value={shiftCount}
                                            onChange={this.onShiftCountChanged}
                                            disabled={disableAllElements}
                                        />
                                        {!disableAllElements && (
                                            <RecalculateShiftsCountButton
                                                shiftLengthInMinutes={shiftLengthInMin}
                                                hasOwnShiftLength={hasOwnShiftLength}
                                                style={{ marginLeft: 10 }}
                                                from={shiftCount}
                                                to={newShiftCount}
                                                onClick={() => {
                                                    this.setState({ shiftCount: newShiftCount, shiftCountChanged: true });
                                                }}
                                            />
                                        )}
                                        <OperationParamResetButton
                                            changed={shiftCountChanged}
                                            onChange={() => {
                                                let shiftCount = this.props.defaultShiftCount || 0;
                                                if (
                                                    this.props.defaultShiftCount === undefined ||
                                                    this.props.autoRecalculateShiftsCountMode
                                                ) {
                                                    shiftCount = newShiftCount;
                                                }

                                                this.setState({
                                                    shiftCountChanged: false,
                                                    shiftCount: shiftCount,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                            }

                            {canViewFinancialData && (
                                <Col span={12} className="rr-form-item" style={{ marginBottom: 0 }}>
                                    <div className="rr-label" style={{ marginBottom: 10 }}>
                                        {localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DISCOUNT)}
                                    </div>
                                    <div className={'rr-value'} style={{ display: 'flex', alignItems: 'center' }}>
                                        <NumberInputDiscount
                                            className={
                                                discountChanged
                                                    ? ' rr-operation-element-create-popover-param-changed'
                                                    : ' rr-operation-element-create-popover-param-not-changed'
                                            }
                                            value={discount}
                                            onChange={this.onDiscountChanged}
                                            disabled={disableAllElements}
                                        />
                                        <OperationParamResetButton
                                            changed={discountChanged}
                                            onChange={() => {
                                                this.setState({
                                                    discountChanged: false,
                                                    discount: this.props.defaultDiscount,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                            )}
                        </Row>

                        {canViewFinancialData && (
                            <Row type={'flex'} style={{ borderTop: '1px solid #e4e5ec', backgroundColor: '#525A95' }}>
                                <Col
                                    span={8}
                                    className="rr-form-item"
                                    style={{
                                        borderRight: 'none',
                                        padding: '12px 32px',
                                        marginBottom: 0,
                                        backgroundColor: '#FFF',
                                    }}
                                >
                                    <div className="rr-label">Цена экз./см.</div>
                                    <div className="rr-value">
                                        {pricingScheme ? (
                                            <PricingSchemePricePopover
                                                data={{
                                                    id: pricingScheme.id,
                                                    typeCode: pricingScheme?.typeCode,
                                                    label: pricingScheme.name || '',
                                                    price: pricePerShiftOriginal,
                                                    steps: pricingScheme?.steps || [],
                                                    shiftCount,
                                                }}
                                            >
                                                <Icon
                                                    style={{
                                                        fontSize: 22,
                                                        color: '#3d64d4',
                                                        marginRight: 4,
                                                        marginTop: -5,
                                                        verticalAlign: 'middle',
                                                    }}
                                                    component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                                                />
                                            </PricingSchemePricePopover>
                                        ) : null}
                                        <InlineMoneyInput
                                            min={0} //parser={inputNumberParser}
                                            canEdit={!disableAllElements}
                                            value={pricePerShift}
                                            onChange={(v) => {
                                                if (pricePerShift !== v)
                                                    this.setState({
                                                        pricePerShift: v,
                                                        priceChanged: true,
                                                    });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col
                                    span={8}
                                    className="rr-form-item"
                                    style={{
                                        borderLeft: '1px solid #e4e5ec',
                                        padding: '12px 32px',
                                        marginBottom: 0,
                                        marginLeft: -16 - 1,
                                        backgroundColor: '#FFF',
                                    }}
                                >
                                    <div className="rr-label">Стоимость экз./см.</div>
                                    <div className={'rr-value'}>
                                        <InlineMoneyInput
                                            min={0} //parser={inputNumberParser}
                                            canEdit={!disableAllElements && pricePerShift !== 0}
                                            value={shiftPrice}
                                            onChange={(v) => {
                                                let discount = 100 - (v * 100) / pricePerShift;
                                                if (shiftPrice !== v)
                                                    this.setState({
                                                        discount: discount,
                                                        discountChanged: true,
                                                    });
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col
                                    span={8}
                                    className="rr-form-item"
                                    style={{
                                        borderLeft: '1px solid #525A95',
                                        padding: '12px 0px 12px 32px',
                                        backgroundColor: '#525A95',
                                        marginBottom: 0,
                                        marginLeft: -16 - 1,
                                    }}
                                >
                                    <div className="rr-label" style={{ color: '#FFFFFF' }}>
                                        {localize(LocalizationEnum.ASPECT__GRID__COLUMN__FINAL_TOTAL_PRICE)}
                                    </div>
                                    <div className={'rr-value'} style={{ color: '#FFFFFF' }}>
                                        <InlineMoneyInput
                                            min={0} //parser={inputNumberParser}
                                            canEdit={!disableAllElements && pricePerShift !== 0 && instanceCount > 0 && shiftCount > 0}
                                            darkBg={true}
                                            value={finalTotalPrice}
                                            onChange={(v) => {
                                                let discount =
                                                    v !== 0 && priceWithoutDiscount !== 0
                                                        ? ((priceWithoutDiscount - v) * 100) / priceWithoutDiscount
                                                        : 0;
                                                if (finalTotalPrice !== v)
                                                    this.setState({
                                                        discount: discount,
                                                        discountChanged: true,
                                                    });
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </>
                }
            />
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId,
    defaultShiftCount: storeState.operationForm.shiftCount,
    defaultDiscount: storeState.operationForm.discount,
    defaultRentPeriodEndDate: storeState.operationForm.rentPeriodEndDate,
    defaultRentPeriodStartDate: storeState.operationForm.rentPeriodStartDate,
    startDateFromList: storeState.operationForm.products.params.startDate,
    endDateFromList: storeState.operationForm.products.params.endDate,
    operationTypeCode: storeState.operationForm.typeCode,
    operationTypeCorrectionCode: storeState.operationForm.targetStateCode,
    elements: storeState.operationForm.elements.entities,
    projectTemplate: storeState.operationForm.projectTemplate,
    timeTables: storeState.operationForm.timeTables,
    recalculateShiftCount: storeState.userSettings.operationFormRecalculateShiftCount,
    shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
    requiredTimeIndentBetweenElementsInMinutes:
        storeState.businessAccountPreferences.preferences?.requiredTimeIndentBetweenElementsInMinutes || 0,
    isSubrentOperation: storeState.operationForm.isSubrent,//isSubrentOperation(storeState.operationForm.typeCode, storeState.operationForm.targetStateCode),
    renterId: storeState.operationForm.renterId,
    operationStartTime: storeState.operationForm.operationStartTime,
    autoRecalculateShiftsCountMode: storeState.operationForm.autoRecalculateShiftsCountMode,
    canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    isSimpleOrder: storeState.operationForm.isSimpleOrder,
    operationForm: storeState.operationForm,
    operationShiftCountWarningsEnabled: storeState.operationForm.operationShiftCountWarningsEnabled
});

const mapDispatchToProps = { addElement, editElement };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationElementCreatePopoverContent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));
