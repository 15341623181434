import React from 'react';
import { reset as resetElements, updatePriceFromCatalog } from '../../elements/reducers/elements.reducer';
import { reset as resetElement } from '../../elements/reducers/element.reducer';
import {
    addElement,
    fullResetOperation,
    removeConcurrentOperation,
    resetOperation,
    setElementsListParams,
    startNewOperation,
} from '../reducers/operationForm.reducer';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { push, replace } from 'connected-react-router';
import { Spin } from '../../../../components';
import { getStateFromPath } from '../../../../shared/util/utils';
import { DocumentTypeCodeEnum, FileTypeCodeEnum, ProjectInfoRead, RentStateCodeEnum, serverApi } from '../../../../server';
import { columns } from '../../elements/elements-list-columns';
import { Location } from 'history';
import { ElementsElementPopover } from '../../elements/elements-element-popover';
import { showNotification } from '../../../../components/notification/showNotification';
import { localize } from '../../../../localization/localizationUtils';
import { LocalizationEnum } from '../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Grid } from '../../../../components/grid/Grid';
import { ExportEstimatePopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportEstimatePopover/ExportEstimatePopover';
import { ExportActPopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportActPopover/ExportActPopover';
import { ExportToExcellPopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportToExcellPopover/ExportToExcellPopover';
import {
    ActDocumentOnDownload,
    ExportActPopoverOptions,
} from '../../../../components/exportPopovers/downloadDocument/instances/ExportActPopover/utils/data';
import {
    EstimateDocumentOnDownload,
    ExportEstimatePopoverOptions,
} from '../../../../components/exportPopovers/downloadDocument/instances/ExportEstimatePopover/utils/data';
import {
    ExportToExcellOnDownload,
    ExportToExcellPopoverOptions,
} from '../../../../components/exportPopovers/downloadDocument/instances/ExportToExcellPopover/utils/data';
import { resetStatusWasChangedFlag } from '../../projects/production/reducers/project.reducer';
import { AxiosResponse } from 'axios';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { loadCategories } from '../../../../shared/reducers/entities.reducer';
import { subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { GridName } from '../../../../components/grid/utils/types';
import { IDownloadDocumentParamsItem } from '../../../../components/exportPopovers/downloadDocument/DownloadDocumentPopover';
import { DownloadDocumentUtils } from '../../../../core/utils/downloadDocumentUtils/downloadDocumentUtils';
import { ElementsAggregationsPopover } from '../../elements/components/AggregationsPopover/ElementsAggregationsPopover';
import {
    exportExcelSimpleOrderList,
    simpleOrderLoadEntities,
    setSimpleOrderListParams,
} from '../../elements/reducers/simpleOrderElements.reducer';
import { getGridStorageData } from '../../../../components/grid/utils';
import { DEFAULT_NUMBER_OF_ENTITIES_ON_PAGE, DEFAULT_ON_PAGE_SELECT_VALUES } from '../../../../config/constants';

interface IState {
    loading: boolean;
    page: number;
    pageSize: number;
}

export interface ElementsListProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    parentId: number;
    parentType: 'project' | 'operation' | 'projectTemplate' | 'shipping';
    operationEntityType?: 'project' | 'projectTemplate' | 'shipping';
    gridName: GridName;
    parentProjectEntity?: ProjectInfoRead;
    exportActOptions?: IDownloadDocumentParamsItem[];
    exportEstimateOptions?: IDownloadDocumentParamsItem[];
}

class Component extends React.Component<ElementsListProps, IState> {
    private grid;

    constructor(props: ElementsListProps) {
        super(props);

        let gridData = getGridStorageData(this.props.gridName);

        this.state = {
            loading: false,
            page: 1,
            pageSize: gridData.params?.limit || DEFAULT_NUMBER_OF_ENTITIES_ON_PAGE,
        };
    }

    componentDidMount = async () => {
        let gridData = getGridStorageData(this.props.gridName);
        let params = gridData.params;
        this.props.setSimpleOrderListParams({ sortBy: params?.sortBy, sortOrder: params?.sortOrder });
        this.props.simpleOrderLoadEntities(this.props.intl, this.props.businessAccountId);
    };

    componentDidUpdate = (prevProps: ElementsListProps) => {
        // if (this.props.projectStatusWasChangedFlag && this.props.projectStatusWasChangedFlag !== prevProps.projectStatusWasChangedFlag) {
        //     setTimeout(() => {
        //         this.loadDataAndSetFilterValues();
        //     }, 1500);
        //     this.props.resetStatusWasChangedFlag();
        //     return;
        // }
        //
        // if (
        //     (this.props.parentType === 'project' && prevProps.pageParams.projectId !== this.props.pageParams.projectId) ||
        //     (this.props.parentType === 'project' && String(this.props.pageParams.projectId) === String(this.props.parentId)) ||
        //     this.props.parentType !== 'project'
        // ) {
        //     PageUtils.update(
        //         prevProps.pageParams,
        //         this.props.pageParams,
        //         this.updatingAfterSetFromStorage,
        //         this.fromFilters,
        //         this.setSavedParams,
        //         this.loadDataAndSetFilterValues,
        //         [],//this.filters,
        //         () => {
        //             this.updatingAfterSetFromStorage = false;
        //         }
        //     );
        // }
    };

    onSortedChange = (id: string, desc: boolean) => {
        // this.props.push(
        //     getPathFromState(this.props.location.pathname, this.props.location.search, {
        //         sortBy: id,
        //         sortOrder: desc ? 'DESC' : 'ASC',
        //     })
        // );

        this.props.setSimpleOrderListParams({ sortBy: id, sortOrder: desc ? 'DESC' : 'ASC' });
        this.props.simpleOrderLoadEntities(this.props.intl, this.props.businessAccountId);
    };

    getExportSettings: ExportToExcellOnDownload = async ({ options }) => {
        let mnemoProject = '';

        await serverApi.getProjectById(this.props.businessAccountId, this.props.parentId).then((res) => {
            mnemoProject = res.data.shortName;
        });

        let columns = ['All'];
        if (!options.includes(ExportToExcellPopoverOptions.ALL_COLUMNS)) {
            columns = this.grid.getVisibleColumns();
        }

        await this.props.exportExcelSimpleOrderList(
            this.props.intl,
            this.props.businessAccountId,
            columns,
            options.includes(ExportToExcellPopoverOptions.EXPAND_KITS),
            mnemoProject
        );
    };

    generateEstimateCb: EstimateDocumentOnDownload = async ({ fileType, options, templateId, isCommonTemplate, period, legalDetailId }) => {
        await this.generateDocument(
            fileType,
            options,
            templateId || 0,
            isCommonTemplate,
            DocumentTypeCodeEnum.ESTIMATE,
            period,
            legalDetailId
        );
    };

    generateActCb: ActDocumentOnDownload = async ({ fileType, options, templateId, isCommonTemplate, period, legalDetailId }) => {
        await this.generateDocument(fileType, options, templateId || 0, isCommonTemplate, DocumentTypeCodeEnum.ACT, period, legalDetailId);
    };

    getFilters = (freeItems: boolean) => {
        let filters: string[] = [];

        if (!freeItems) {
            filters.push('finalTotalPrice;GT;0');
        }

        filters.push(`hideStateCode;IN;${RentStateCodeEnum.CANCELED}`);

        return filters;
    };

    generateDocument = async (
        fileType: FileTypeCodeEnum,
        values: (ExportEstimatePopoverOptions | ExportActPopoverOptions)[],
        templateId: number,
        isCommonTemplate: boolean | undefined,
        documentType: DocumentTypeCodeEnum,
        period?: [string, string],
        legalDetailId?: number
    ) => {
        if (!this.props.parentId) return;

        let freeItems = values && values.includes(ExportEstimatePopoverOptions.FREE_ITEMS);
        let projectName: string | undefined = '';

        let projectData: AxiosResponse<ProjectInfoRead> | undefined;
        try {
            projectData = await serverApi.getProjectById(this.props.businessAccountId, this.props.parentId);
        } catch (e) {
            console.log(e);
        }
        if (projectData?.data) projectName = projectData.data.shortName || projectData.data.fullName;

        const filters = this.getFilters(freeItems);

        try {
            if (documentType === DocumentTypeCodeEnum.ACT) {
                const fileName = `Акт${projectName ? ' ' + projectName : ''}`;
                await DownloadDocumentUtils.actGenerateAndDownload(
                    {
                        businessAccountId: this.props.businessAccountId,
                        parentId: this.props.parentId,
                        common: Boolean(isCommonTemplate),
                        documentTemplateId: templateId,
                        expandKits: values && values.includes(ExportEstimatePopoverOptions.EXPAND_KITS),
                        joinSimilarRows: values && values.includes(ExportEstimatePopoverOptions.JOIN_SIMILAR_ROWS),
                        groupByCategories: values && values.includes(ExportActPopoverOptions.GROUP_BY_CATEGORIES),
                        search: undefined,
                        filters: filters,
                        includeWorkPlannings: values && values.includes(ExportActPopoverOptions.WORK_PLANNINGS),
                        includeExpenses: values && values.includes(ExportActPopoverOptions.EXPENSES),
                        period,
                        legalDetailId,
                    },
                    this.props.parentType,
                    fileName,
                    fileType as FileTypeCodeEnum.WORD | FileTypeCodeEnum.PDFTYPE
                ).catch((e) => {
                    showNotification('error', 'Ошибка генерации файла');
                });
            } else {
                const fileName = `Смета${projectName ? ' ' + projectName : ''}`;
                await DownloadDocumentUtils.estimateGenerateAndDownload(
                    {
                        businessAccountId: this.props.businessAccountId,
                        parentId: this.props.parentId,
                        common: Boolean(isCommonTemplate),
                        documentTemplateId: templateId,
                        expandKits: values && values.includes(ExportEstimatePopoverOptions.EXPAND_KITS),
                        joinSimilarRows: values && values.includes(ExportEstimatePopoverOptions.JOIN_SIMILAR_ROWS),
                        search: undefined,
                        filters: filters,
                        includeWorkPlannings: values && values.includes(ExportEstimatePopoverOptions.WORK_PLANNINGS),
                        includeExpenses: values && values.includes(ExportEstimatePopoverOptions.EXPENSES),
                        period,
                        legalDetailId,
                    },
                    this.props.parentType,
                    fileName,
                    fileType as FileTypeCodeEnum.EXCEL | FileTypeCodeEnum.PDFTYPE
                ).catch((e) => {
                    showNotification('error', 'Ошибка генерации файла');
                });
            }
        } catch (err) {}
    };

    render() {
        let { entities, elementsLoading, filteredCount, canViewFinancialData } = this.props;

        let canGenerateEstimate = true,
            canGenerateAct = true;
        const excludeColumns: string[] = [
            'numberInActivityFrame',
            'subrentedInstanceCount',
            'stateCode',
            'resolutionCodes',
            'tagsString',
            'rentTerms.rentPeriodStartDate',
            'rentTerms.rentPeriodEndDate',
            'calendarShifts.shiftCount',
            'lastActivityDate',
            'subrentWorkedShifts.shiftCount',
        ];

        if (!canViewFinancialData) {
            excludeColumns.push('effectivePrice', 'rentTerms.discount', 'finalInstancePrice', 'finalTotalPrice');
        }

        const _columns = columns.slice(0, -1);

        const from = (this.state.page - 1) * this.state.pageSize;
        const filteredData = entities?.slice(from, from + this.state.pageSize);

        return (
            <Spin spinning={elementsLoading || this.state.loading} delay={this.state.loading ? 0 : undefined}>
                <Grid
                    ref={(ref) => {
                        if (!this.grid) this.grid = ref;
                    }}
                    excludeColumns={excludeColumns}
                    onSortedChange={this.onSortedChange}
                    filtered={filteredCount}
                    columns={_columns}
                    data={/*entities*/ filteredData}
                    indexFieldName={'id'}
                    entityType={'element'}
                    rowPopoverComponent={ElementsElementPopover}
                    defaultSorted={this.props.pageParams.sortBy}
                    defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                    gridName={this.props.gridName}
                    selectable={false}
                    exportBlock={
                        <>
                            <ElementsAggregationsPopover entityType={this.props.parentType} />
                            {canGenerateAct ? (
                                <ExportActPopover
                                    getFilters={this.getFilters}
                                    onDownload={this.generateActCb}
                                    options={this.props.exportActOptions}
                                />
                            ) : null}
                            {canGenerateEstimate ? (
                                <ExportEstimatePopover
                                    getFilters={this.getFilters}
                                    onDownload={this.generateEstimateCb}
                                    options={this.props.exportEstimateOptions}
                                />
                            ) : null}
                            {
                                <ExportToExcellPopover
                                    getFilters={this.getFilters}
                                    storageKey={'elementsExcell'}
                                    onDownload={this.getExportSettings}
                                    options={[
                                        {
                                            key: ExportToExcellPopoverOptions.EXPAND_KITS,
                                            label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__INCLUDE_COMPONENTS),
                                        },
                                        {
                                            key: ExportToExcellPopoverOptions.ALL_COLUMNS,
                                            label: localize(LocalizationEnum.ASPECT__EXCEL_EXPORT__SELECT_OPTION__ALL_COLUMNS),
                                        },
                                    ]}
                                />
                            }
                        </>
                    }
                    currentPage={this.state.page}
                    pageSize={this.state.pageSize}
                    onPageSelect={DEFAULT_ON_PAGE_SELECT_VALUES}
                    onPageChanged={(page) => {
                        this.setState({ page });
                    }}
                    onPageSizeChanged={(pageSize) => {
                        this.setState({ pageSize, page: 1 });
                    }}
                />
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    let pageParams = getStateFromPath(storeState.router.location.search),
        categoryIds = pageParams['categoryIds'] || undefined;

    return {
        entities: storeState.simpleOrderElements.entities,
        elementsLoading: storeState.simpleOrderElements.loading,
        operation: storeState.operation.entity,
        locationSearchParams: storeState.router.location.search,
        locationPathname: storeState.router.location.pathname,
        categoryIds: categoryIds,
        pageParams: storeState.simpleOrderElements.params,
        filteredCount: storeState.simpleOrderElements.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
        operationFormMnemoKey: storeState.operationForm.mnemoKey,
        operationTypeCode: storeState.operationForm.typeCode,
        userPermissions: storeState.permissions.permissions,
        projectStatusWasChangedFlag: storeState.project.statusWasChangedFlag,
        operationCorrectionTypeCode: storeState.operationForm.targetStateCode,
        operationRentPeriodStartDate: storeState.operationForm.rentPeriodStartDate,
        operationRentPeriodEndDate: storeState.operationForm.rentPeriodEndDate,
        projectStateCode:
            storeState.operation.entity?.projectStateCode ||
            storeState.operation.entity?.subrentStateCode ||
            storeState.project.entity?.stateCode ||
            storeState.subrent.entity?.stateCode,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        canUpdateFinancialData: canUpdateFinancialData(storeState.permissions.permissions),
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = {
    simpleOrderLoadEntities,
    exportExcelSimpleOrderList,
    resetElements,
    resetElement,
    push,
    replace,
    startNewOperation,
    resetStatusWasChangedFlag,
    addElement,
    fullResetOperation,
    removeConcurrentOperation,
    loadCategories,
    updatePriceFromCatalog,
    resetOperation,
    setElementsListParams,
    setSimpleOrderListParams,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OrderElementsList = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    injectIntl(Component, { forwardRef: true })
);
