import React, {CSSProperties} from 'react';
import {Link} from 'react-router-dom';
import {Icon} from 'antd';
import {ProductCompositionColumnsObj} from './productCompositionColumns.types';
import {LocalizationEnum, localize} from '../../../../../../../localization';
import {ColumnTypes, TableColumn} from '../../../../../../../components/grid/Table';
import {IconStarSolid} from '../../../../../../../components/icons';
import {GRID_IMAGE_COLUMN_WIDTH} from '../../../../../../../config/constants';
import {getBusinessAccountId, getStoreState} from '../../../../../../../../index';
import {
    KitMemberObjRead,
    PricingSchemeExternalRepresentationObj,
    PricingSchemeModeCodeEnum
} from '../../../../../../../server';
import {ProductVariantPopover} from '../../../../variants/components/productVariantPopover/productVariantPopover';
import {PricingSchemePricePopover} from "../../../../../settings/pricingSchemes/pricingSchemePricePopover";
import {PricingSchemeMaps} from "../../../../../../../core/utils/pricingSchemeUtils";
import {tableCellMoneyRenderer} from "../../../../../../../components/grid/renderers/tableCellMoneyRenderer";
import {GridColumnCreator} from "../../../../../../../components/grid/gridColumnCreator";

export const productCompositionColumns: TableColumn<ProductCompositionColumnsObj>[] = [
    {
        title: localize(LocalizationEnum.PAGE__KITS__ORDER),
        dataIndex: 'memberOrder',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        sortable: false,
        render: (value: Number, rowData: any) => {
            return (
                <div title={rowData.shortName}>
                    {rowData.main ? (
                        <Icon style={{ marginBottom: '1px', marginRight: '4px', fontSize: '18px' }} component={IconStarSolid} />
                    ) : null}
                    #{+value + 1}
                </div>
            );
        },
        dontHide: true,
    },

    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__IMAGE),
        dataIndex: 'image',
        type: ColumnTypes.Image,
        width: GRID_IMAGE_COLUMN_WIDTH,
        maxWidth: GRID_IMAGE_COLUMN_WIDTH,
        sortable: false,
        resizable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__QUANTITY),
        dataIndex: 'instanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        sortable: false,
        render: (value: String) => {
            return (
                <div className={'rr-grid-shortName-cell'} style={{ display: 'flex', justifyContent: 'center' }}>
                    {value}
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__ENTITY_TYPE__PRODUCT),
        dataIndex: 'productShortName',
        type: ColumnTypes.CustomRender,
        ...GridColumnCreator.getColumnSizesForEntity('product'),
        resizable: true,
        sortable: false,
        render: (value: String, rowData: any) => {
            return (
                <div className={'rr-grid-shortName-cell'} title={rowData.shortName}>
                    <Link to={`/${getBusinessAccountId()}/inventory/products/${rowData.productId}`} style={{ color: 'unset' }}>
                        {value}
                    </Link>
                </div>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__VARIANT),
        dataIndex: 'variantName',
        type: ColumnTypes.CustomRender,
        ...GridColumnCreator.getColumnSizesForEntity('variant'),
        resizable: true,
        sortable: false,
        render: (value: String, rowData: KitMemberObjRead) => {
            return rowData.variantId ? (
                <div className={'rr-grid-shortName-cell'} onClick={(e) => e.stopPropagation()}>
                    <ProductVariantPopover productId={rowData.productId} variantId={rowData.variantId}>
                        <span className="popover-header-name grid-column-variant">{rowData.variantName}</span>
                    </ProductVariantPopover>
                </div>
            ) : null;
        },
    },
    {
        title: 'Инвентарь',
        dataIndex: 'stockKitMemberCount',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        sortable: false,
        render: (value: number|undefined, rowData: KitMemberObjRead) => {
            const stockKitMemberCount = rowData.stockKitMemberCount || 0;
            const showWarning = false;//rowData.instanceCount > stockKitMemberCount;
            return <span style={showWarning ? {color: '#fc8c2f', fontWeight: 600} : { color: stockKitMemberCount === 0 ? '#cccccc' : undefined }}>{stockKitMemberCount}</span>;
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__PRODUCT_STATE),
        dataIndex: 'productStateCode',
        type: ColumnTypes.Status,
        minWidth: 130,
        resizable: false,
        sortable: false,
        defaultHidden: true,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_INSTANCE_PER_SHIFT),
        dataIndex: 'pricePerShift',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 150,
        resizable: false,
        sortable: false,
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__DISCOUNT),
        dataIndex: 'discount',
        type: ColumnTypes.Percents,
        minWidth: 100,
        resizable: false,
        sortable: false,
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__PRICE_WHEN_IN_KIT),
        dataIndex: 'finalInstancePrice',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 150,
        resizable: false,
        sortable: false,
        render: (value: any, rowData: KitMemberObjRead) => {
            return (
                <>
                    {renderPricingScheme(rowData.finalInstancePrice, rowData.pricingScheme, undefined)}
                    {tableCellMoneyRenderer(rowData.finalInstancePrice)}
                </>
            )
        },
    },
    {
        title: localize(LocalizationEnum.PAGE__KITS__TOTAL_PRICE_WHEN_IN_KIT),
        dataIndex: 'finalTotalPrice',
        type: ColumnTypes.Money,
        className: 'money',
        minWidth: 170,
        resizable: false,
        sortable: false,
    },
];

export const renderPricingScheme = (price: number, productPricingScheme: PricingSchemeExternalRepresentationObj|undefined, iconStyle:CSSProperties|undefined) => {
    const kitPricingScheme = getStoreState().kit.entity?.pricingScheme;
    const kitPricingSchemeMode = getStoreState().kit.entity?.pricingSchemeMode;
    let pricingScheme:PricingSchemeExternalRepresentationObj|undefined;
    if(kitPricingSchemeMode === PricingSchemeModeCodeEnum.KITPRICINGSCHEME){
        pricingScheme = kitPricingScheme;
    }else if(kitPricingSchemeMode === PricingSchemeModeCodeEnum.WITHMEMBERPRICINGSCHEMES){
        pricingScheme = productPricingScheme;
    }else if(kitPricingSchemeMode === PricingSchemeModeCodeEnum.DISABLED){
        pricingScheme = undefined;
    }

    return pricingScheme ? (
        <PricingSchemePricePopover
            data={{
                id: pricingScheme.id,
                typeCode: pricingScheme.typeCode,
                label: pricingScheme.name || '',
                price,
                steps: pricingScheme.steps || [],
            }}
        >
            <Icon
                component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                className={'rr-grid-pricingScheme-icon'} style={iconStyle}
            />
        </PricingSchemePricePopover>
    ) : null;
}