import React from 'react';
import { Affix, Card, Col, Icon, Row } from 'antd';
import { IRootState } from '../../../shared/reducers';
import { ICategoriesState, loadCategory, searchCategories, selectCategory } from './reducers/categories.reducer';
import { connect } from 'react-redux';
import { SearchInput, Spin } from '../../../components';
import './categories-block.less';
import { CategoryBlock } from './category-block';
import CategoryDeleteModal from './category-delete-modal';
import { LocalizationEnum, localize, localizeIntl } from '../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IconSitemap } from '../../../components/icons';
import { ItemsNotFoundBlock } from '../../../components/itemsNotFoundBlock/ItemsNotFoundBlock';
import cloneDeep from 'lodash/cloneDeep';
import { Avatar } from '../../../components/avatar/avatar';
import { CategoriesBlockSort, CategoriesBlockSortProps } from './categories-block-sort';
import { loadCategories, ProductCategory, RenterCategory } from '../../../shared/reducers/entities.reducer';
import { CategoryInfoCreate, CategoryInfoUpdate, CategorySortOrderEnum, CategoryTypeCodeEnum } from '../../../server';
import { TreeByName } from './tree/TreeByName';
import { TreeBySiblingOrderComponent } from './tree/TreeBySiblingOrderComponent';
import { TreeBySiblingOrderModal } from './tree/TreeBySiblingOrderModal';
import { IBasePageProps } from '../../../components/page/BasePage';
import { withRouter } from 'react-router';
import { updateCategoriesExpandedKeys } from '../../../shared/reducers/userSettings/userSettings.reducer';
import { getCategoryById, getParentCategory } from './utils';

interface IState {
    expandedKeys: string[];
    mainBlockHeight: number;
}

export type Category = ProductCategory | RenterCategory;

export const getCategoriesUrlPath = (businessAccountId: number, section: 'inventory' | 'crm'): string =>
    `/${businessAccountId}/${section}/categories`;
export const getCategoriesSetOrderUrlPath = (businessAccountId: number, section: 'inventory' | 'crm'): string =>
    `${getCategoriesUrlPath(businessAccountId, section)}/setOrder`;

export const categorySortTitles = {
    [CategorySortOrderEnum.NAME]: 'Наименованию',
    [CategorySortOrderEnum.SIBLINGORDER]: 'Заданному порядку',
};

export interface CategoriesBlockProps extends StateProps, DispatchProps, WrappedComponentProps, IBasePageProps {
    typeCode: CategoryTypeCodeEnum;
    entities?: ProductCategory[] | RenterCategory[] | null;
    height?: number;
    searchString?: string;
    data: ICategoriesState;
    sortData: CategoriesBlockSortProps;
}

export interface CategoryData {
    data: CategoriesBlockProps['entities'];
    loading?: boolean;
    selectedCategoryId?: ICategoriesState['selectedCategoryId'];
    loadingError?: Error | null;
}

class CategoriesBlock extends React.PureComponent<CategoriesBlockProps, IState> {
    constructor(props: CategoriesBlockProps) {
        super(props);

        this.state = {
            expandedKeys: [],
            mainBlockHeight: 0,
        };
    }

    componentDidMount() {
        this.props.loadCategories(this.props.businessAccountId, this.props.typeCode, this.props.sortData.value);

        this.checkExpandedKeysFromStore();
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate(prevProps: Readonly<CategoriesBlockProps>) {
        if (prevProps.typeCode === this.props.typeCode && prevProps.sortData.value === this.props.sortData.value) return;

        this.props.loadCategories(this.props.businessAccountId, this.props.typeCode, this.props.sortData.value);

        this.checkExpandedKeysFromStore();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        const element = document.getElementsByClassName('rr-categories-main-block')[0];
        console.log('resize', element);

        if (element) {
            let bodyRect = document.body.getBoundingClientRect();
            let elRect = element.getBoundingClientRect();
            let offset = elRect.top - bodyRect.top;
            this.setState({
                mainBlockHeight: window.innerHeight - offset - 110,
            });
        }
    };

    onSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await this.props.searchCategories(e.target.value, this.props.typeCode);

        if (this.props.searchString) {
            this.setExpandedKeys(this.getExpandedKeysArrMain(this.getCategoriesBySearch({ data: this.props.entities || [] })));
        } else {
            this.setExpandedKeys([]);
        }
    };

    getCategoryNestingLevel = (
        id: ICategoriesState['selectedCategoryId'],
        entities: CategoryData['data'],
        level = 0,
        res?: { level: number }
    ) => {
        if (!res) res = { level: 0 };
        level++;
        entities?.forEach((item) => {
            if (String(item.key) === id) {
                res!.level = level;
            } else {
                if (item.children && item.children.length > 0) {
                    this.getCategoryNestingLevel(id, item.children, level, res);
                }
            }
        });
        return res.level;
    };

    search = (str: CategoriesBlockProps['searchString'], item: Category): boolean => {
        let found = false;

        if (item.title.toLowerCase().includes((str ?? '').toLowerCase())) {
            found = true;
        }
        for (let i = 0; i < item.children.length; i++) {
            if (this.search(str, item.children[i])) {
                found = true;
            }
        }
        return found;
    };

    getExpandedKeysArrMain = (categories: Category[]): string[] => {
        const expandedKeysArr: string[] = [];

        const getExpandedKeysArr = (categories: Category[], expandedKeysArr: string[]) => {
            for (let item of categories) {
                expandedKeysArr.push(item.key.toString());
                getExpandedKeysArr(item.children, expandedKeysArr);
            }
        };
        getExpandedKeysArr(categories, expandedKeysArr);
        return expandedKeysArr;
    };

    onExpand = (expandedKeys: string[]) => {
        this.setExpandedKeys(expandedKeys);
    };

    setExpandedKeys = (expandedKeysArr: IState['expandedKeys']) => {
        const filteredKeys = expandedKeysArr.filter((key) => key != '-1');
        this.props.updateCategoriesExpandedKeys({
            [this.props.typeCode]: filteredKeys,
        });
        this.setState({
            expandedKeys: filteredKeys,
        });
    };

    checkExpandedKeysFromStore = () => {
        const storageExpandedKeysRecord = this.props.categoriesExpandedKeys?.[this.props.typeCode];
        if (storageExpandedKeysRecord) {
            this.setExpandedKeys(storageExpandedKeysRecord);
        }
    };

    getCategoriesBySearch = (category: CategoryData): Category[] => {
        let categories: Category[] = [];

        category.data?.forEach((categoryData) => {
            const getNewArr = (item: Category): Category => {
                let newChildren: (typeof item)[] = [];

                for (let i = 0; i < item.children.length; i++) {
                    getNewArr(item.children[i]);

                    if (this.search(this.props.searchString, item.children[i])) {
                        newChildren = [...newChildren, item.children[i]];
                    }
                }
                item.children = [...newChildren];

                return item;
            };
            if (this.search(this.props.searchString, categoryData)) {
                categories.push(getNewArr(cloneDeep(categoryData)));
            }
        });

        return categories;
    };

    expandAllParentsCategoriesCallback = (categoryInfo: CategoryInfoUpdate | CategoryInfoCreate): void => {
        const categories = this.props.entities ?? [];
        if (categoryInfo.parentCategoryId == null) return;
        let parentCategory = getCategoryById(Number(categoryInfo.parentCategoryId), categories);
        const expandedKeys: string[] = [];

        while (parentCategory != null) {
            expandedKeys.push(String(parentCategory.key));
            parentCategory = getParentCategory(parentCategory, categories);
        }

        if (expandedKeys.length > 0) {
            this.setExpandedKeys(expandedKeys.concat(this.state.expandedKeys));
        }
    };

    render() {
        //console.log('CategoriesBlock render()', this.props.data.selectedCategoryId);

        let categoryData: CategoryData = {
            data: this.props.entities || [],
            loading: false,
            selectedCategoryId: this.props.data.selectedCategoryId,
            loadingError: null,
        };

        let availableCategories: Category[] = [];

        if (categoryData.data) {
            // Получить категории, удовлетворяющие поиску
            availableCategories = this.getCategoriesBySearch(categoryData);
        }

        // Получаем уровень вложенности выбранной категории
        let categoryLevel = this.getCategoryNestingLevel(categoryData.selectedCategoryId, categoryData.data);

        //console.log('======', categoryData.selectedCategoryId, categoryLevel);

        return (
            <>
                <Row gutter={32} type={'flex'} className={'rr-categories-main-block'}>
                    <Col xxl={18} xl={16} lg={14} span={12} style={{} /*{height: '100%'}*/}>
                        <Card bordered={false} style={{ minHeight: this.props.height } /*{height: '100%', overflowY: 'auto'}*/}>
                            <Spin spinning={categoryData.loading}>
                                <div className={'rr-categories-header'}>
                                    <h5 style={{ marginBottom: 0 }}>
                                        {localize(LocalizationEnum.PAGE__CATEGORIES__BLOCK_LIST__CATEGORY_TREE_SEARCH)}
                                    </h5>
                                    <CategoriesBlockSort {...this.props.sortData} />
                                </div>
                                <div style={{ marginBottom: 30 }}>
                                    <SearchInput
                                        autoFocus={true}
                                        autoComplete={'off'}
                                        value={this.props.searchString}
                                        placeholder={
                                            localizeIntl(
                                                this.props.intl,
                                                LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER
                                            ) as string
                                        }
                                        onChange={this.onSearchChange}
                                        className={'rr-input-gray'}
                                        allowClear={true}
                                    />
                                </div>
                                {this.props.sortData.value === CategorySortOrderEnum.SIBLINGORDER ? (
                                    availableCategories?.length > 0 ? (
                                        <TreeBySiblingOrderComponent
                                            availableCategories={availableCategories}
                                            typeCode={this.props.typeCode}
                                            intl={this.props.intl}
                                            onExpand={this.onExpand}
                                            expandedKeys={this.state.expandedKeys}
                                            searchString={this.props.searchString}
                                            selectedCategoryId={this.props.data.selectedCategoryId}
                                            sortData={this.props.sortData}
                                            categoryInfo={this.props.data.categoryInfo}
                                        />
                                    ) : null
                                ) : null}
                                {this.props.sortData.value === CategorySortOrderEnum.NAME ? (
                                    <TreeByName
                                        availableCategories={availableCategories}
                                        typeCode={this.props.typeCode}
                                        editMode={this.props.data.editMode}
                                        intl={this.props.intl}
                                        onExpand={this.onExpand}
                                        expandedKeys={this.state.expandedKeys}
                                        searchString={this.props.searchString}
                                        categoryData={categoryData}
                                    />
                                ) : null}
                                {categoryData.data && categoryData.data.length === 0 ? (
                                    <div style={{ marginTop: 56, marginBottom: 20 }}>
                                        <div className={'rr-grid-notfound-block'}>
                                            <Avatar className={'rr-avatar-big'}>
                                                <Icon component={IconSitemap} />
                                            </Avatar>
                                            <div className={'rr-grid-notfound-block-title'}>
                                                {this.props.typeCode === CategoryTypeCodeEnum.PRODUCT
                                                    ? localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NO_PRODUCT_CATEGORIES_EXIST)
                                                    : localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NO_RENTER_CATEGORIES_EXIST)}
                                            </div>
                                            <div className={'rr-grid-notfound-block-message'}>
                                                {localize(
                                                    LocalizationEnum.PAGE__CATEGORIES__BLOCK_LIST__PLACEHOLDER__CREATE_NEW_ROOT_CATEGORIES
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}

                                {categoryData.data &&
                                categoryData.data.length > 0 &&
                                availableCategories.length === 0 &&
                                !categoryData.loading ? (
                                    <ItemsNotFoundBlock entityType={'product'} style={{ marginTop: 56, marginBottom: 20 }} />
                                ) : null}
                                {categoryData.loadingError ? (
                                    <div>{localize(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING_ERROR)}</div>
                                ) : null}
                            </Spin>
                        </Card>
                    </Col>
                    <Col xxl={6} xl={8} lg={10} span={12}>
                        <Affix>
                            <CategoryBlock
                                level={categoryLevel}
                                {...this.props.data}
                                typeCode={this.props.typeCode}
                                height={this.props.height}
                                entities={this.props.entities}
                                sortValue={this.props.sortData.value}
                                expandAllParentsCategoriesCallback={this.expandAllParentsCategoriesCallback}
                            />
                        </Affix>
                    </Col>

                    <CategoryDeleteModal {...this.props.data} typeCode={this.props.typeCode} sortValue={this.props.sortData.value} />
                </Row>
                {this.props.location.pathname === getCategoriesSetOrderUrlPath(this.props.businessAccountId, 'inventory') ||
                this.props.location.pathname === getCategoriesSetOrderUrlPath(this.props.businessAccountId, 'crm') ? (
                    this.props.sortData.value === CategorySortOrderEnum.SIBLINGORDER ? (
                        <TreeBySiblingOrderModal
                            key={JSON.stringify(availableCategories)}
                            visible={true}
                            availableCategories={availableCategories}
                            expandedKeys={[...this.state.expandedKeys]}
                            onExpand={this.onExpand}
                            title={'Порядок категорий'}
                            backPathUrl={getCategoriesUrlPath(
                                this.props.businessAccountId,
                                this.props.typeCode === CategoryTypeCodeEnum.PRODUCT ? 'inventory' : 'crm'
                            )}
                            typeCode={this.props.typeCode}
                            sortData={this.props.sortData}
                        />
                    ) : null
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    businessAccountId: storeState.system.businessAccountId,
    categoriesExpandedKeys: storeState.userSettings.categoriesExpandedKeys,
});

const mapDispatchToProps = {
    loadCategory,
    loadCategories,
    searchCategories,
    selectCategory,
    updateCategoriesExpandedKeys,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategoriesBlock))) as any;
