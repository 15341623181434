import {Checkbox} from "antd";
import CheckboxGroup from "antd/lib/checkbox/Group";
import {TypeOfPricingCodeEnum} from "../../../../../server";
import React from "react";

interface PricingTypeSelectProps {
    value?: TypeOfPricingCodeEnum;
    onChange?: (value?:TypeOfPricingCodeEnum)=>void
}

export const PricingTypeSelectFC = (props: PricingTypeSelectProps) => {
    let v:TypeOfPricingCodeEnum[] = [];
    if(props.value === TypeOfPricingCodeEnum.STRAIGHT) v = [TypeOfPricingCodeEnum.STRAIGHT];
    else if(props.value === TypeOfPricingCodeEnum.FROMOFFERS) v = [TypeOfPricingCodeEnum.FROMOFFERS];
    else if(props.value === TypeOfPricingCodeEnum.STRAIGHTANDOFFERS) v = [TypeOfPricingCodeEnum.STRAIGHT, TypeOfPricingCodeEnum.FROMOFFERS];

    return (
        <div style={{lineHeight: 1}}>
            <div style={{fontSize: 14, fontWeight: 600}}>Тип ценообразования</div>
            <div style={{color: '#AAAAAA', marginTop: 10, marginBottom: 20}}>Что входит в стоимость к оплате</div>
            <CheckboxGroup value={v} onChange={(value)=>{
                let v:TypeOfPricingCodeEnum|undefined;
                if(value.length === 1) v = value[0] as TypeOfPricingCodeEnum;
                else if(value.length == 2) v = TypeOfPricingCodeEnum.STRAIGHTANDOFFERS;
                props.onChange?.(v);
            }}>
                <Checkbox style={{display: 'inline-block', marginRight: 22}} value={TypeOfPricingCodeEnum.STRAIGHT}>Основной проект</Checkbox>
                <Checkbox style={{display: 'inline-block'}} value={TypeOfPricingCodeEnum.FROMOFFERS}>Сметы проекта <span style={{color: '#AAAAAA'}}>(включенные в стоимость)</span></Checkbox>
            </CheckboxGroup>
        </div>
    );
}

// Без этого не работает прокрутка при валидации в форме
export class PricingTypeSelect extends React.Component<PricingTypeSelectProps> {
    render() {
        return <PricingTypeSelectFC {...this.props} />;
    }
}
