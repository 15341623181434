import { getStringServerProblem } from '../../../../../shared/util/utils';
import { MoneyUtils } from '../../../../../core/utils/moneyUtils';
import { subrentsInitialParamsState } from '../reducers/subrents.reducer';
import {ProjectsHideState} from "../../../projects/production/reducers/projects.reducer";
import {getAllStatusesExceptSelected} from "../../../../../shared/util/utils4";
import {ProjectStateCodeEnum, SubrentStateCodeEnum} from "../../../../../server";

export const getSubrentsFilters = (params: typeof subrentsInitialParamsState) => {
    const filters: string[] = [];

    if (params.status) filters.push('stateCode;IN;' + params.status.split(',').join(';'));

    if (params.hide != null) {
        switch (params.hide) {
            case ProjectsHideState.Archive: {
                filters.push('archive;EQ;false');
                break;
            }
            case ProjectsHideState.Cancelled: {
                filters.push(`stateCode;IN;${getAllStatusesExceptSelected(SubrentStateCodeEnum, SubrentStateCodeEnum.CANCELED).join(';')}`);
                break;
            }
            case ProjectsHideState.ArchiveCancelled: {
                filters.push('archive;EQ;false');
                filters.push(`stateCode;IN;${getAllStatusesExceptSelected(SubrentStateCodeEnum, SubrentStateCodeEnum.CANCELED).join(';')}`);
                break;
            }
        }
    }

    if (params.problem) filters.push(getStringServerProblem(params.problem));

    if (params.supplierId !== undefined) filters.push('supplierId;EQ;' + params.supplierId);
    if (params.startDate !== undefined && params.startDate[0] !== undefined) filters.push('startDate;GE;' + params.startDate[0]);
    if (params.startDate !== undefined && params.startDate[1] !== undefined) filters.push('startDate;LE;' + params.startDate[1]);

    if (params.assigneeId !== undefined) filters.push('assigneeId;EQ;' + params.assigneeId);
    if (params.debtToSupplierSum) {
        if (params.debtToSupplierSum[0] != null) filters.push('debtToSupplierSum;GE;' + MoneyUtils.toCents(params.debtToSupplierSum[0]));
        if (params.debtToSupplierSum[1] != null) filters.push('debtToSupplierSum;LE;' + MoneyUtils.toCents(params.debtToSupplierSum[1]));
    }

    return filters;
};
