import { CounterpartyActivityTypeCodeEnum, SubrentStateCodeEnum } from '../../../../server/api';
import { FormItemType } from '../../../../components/dynamicForm/DynamicForm';
import { EntityRemoteSelect } from '../../../../components/select/EntityRemoteSelect';
import { FiltersDateRangePicker } from '../../../../components/datePicker/FiltersDateRangePicker';
import { LocalizationEnum } from '../../../../localization';
import { ProblemsFilter, ProblemsFilterProps } from '../../../../components/select/problemsFilter';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { StatusesFilter, StatusesFilterProps } from '../../../../components/select/StatusesFilters/v2/StatusesFilter';
import { UserSelect } from '../../../../components/v2/select/userSelect/userSelect';
import { SliderRangeWithManualInput } from '../../../../components/slider/SliderRangeWithManualInput/SliderRangeWithManualInput';
import { canViewFinancialDataSelector } from '../../../../shared/reducers/permissions.reducer';
import { getStoreState } from '../../../../../index';
import {ProjectsHideState} from "../../projects/production/reducers/projects.reducer";

export const subrentFilters = [
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'status',
                type: FormItemType.Component,
                component: StatusesFilter,
                componentProps: (form: WrappedFormUtils): StatusesFilterProps => {
                    return {
                        selectItems: [
                            { name: 'Черновик', value: SubrentStateCodeEnum.DRAFT },
                            { name: LocalizationEnum.ASPECT__STATE_CODE__SHIPPING__INPROGRESS, value: SubrentStateCodeEnum.INPROGRESS },
                            { name: LocalizationEnum.ASPECT__STATE_CODE__SHIPPING__FINISHED, value: SubrentStateCodeEnum.FINISHED },
                            { name: 'Отменена', value: SubrentStateCodeEnum.CANCELED },
                        ],
                    };
                },
            },
            {
                label: 'Поставщик',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'supplierId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                className: 'rr-select-gray',
                component: EntityRemoteSelect,
                componentProps: {
                    operationName: 'listRenters',
                    nameField: 'shortName',
                    sortBy: 'lastActivityDate',
                    sortOrder: 'DESC',
                    getEntityOperationName: 'getRenterById',
                    showSearch: true,
                    filters: [
                        `activityTypeCode;IN;${CounterpartyActivityTypeCodeEnum.SUPPLIERONLY};${CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER}`,
                    ],
                },
                getInitialValue: (value) => {
                    return { key: value, label: '' };
                },
            },
            {
                id: 'hide',
                label: 'Скрыть',
                placeholder: 'Не выбрано',
                type: FormItemType.Select,
                style: { minWidth: 160, maxWidth: 260 },
                className: 'rr-select-gray',
                values: [
                    { name: 'Архив', value: ProjectsHideState.Archive },
                    { name: 'Отмененные', value: ProjectsHideState.Cancelled },
                    { name: 'Архив и отмененные', value: ProjectsHideState.ArchiveCancelled },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FILTERS__PROBLEM,
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__NEUTRAL_GENDER,
                //helpTooltipLocalizationEnum: LocalizationEnum.ASPECT__FILTERS__TOOLTIP__A4,
                id: 'problem',
                type: FormItemType.Component,
                style: { minWidth: 160 },
                component: ProblemsFilter,
                componentProps: (form: WrappedFormUtils): ProblemsFilterProps => ({
                    typeStatuses: 'subrent',
                }),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__START_DATE_FROM,
                placeholder: ['Любая дата', 'Любая дата'],
                id: 'startDate',
                type: FormItemType.Component,
                className: 'rr-select-gray',
                component: FiltersDateRangePicker,
            },
            {
                label: 'Ответственный',
                placeholder: LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER,
                id: 'assigneeId',
                type: FormItemType.Component,
                style: { minWidth: 120 },
                component: UserSelect,
            },
            {
                label: LocalizationEnum.ASPECT__GRID__COLUMN__DEBT,
                id: 'debtToSupplierSum',
                type: FormItemType.Component,
                component: SliderRangeWithManualInput,
                style: { minWidth: 200 },
                componentProps: {
                    formatStyle: 'currency',
                    min: Number.MIN_SAFE_INTEGER,
                    max: Number.MAX_SAFE_INTEGER,
                    step: 1,
                },
                visible: () => canViewFinancialDataSelector(getStoreState()),
            },
        ],
    },
];
