import React from 'react';
import { IRootState } from '../../../../../../shared/reducers';
import { createRenter, resetErrors, updateRenter } from '../../../reducers/renters/renter.reducer';
import { goBack, push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
    CategoryTypeCodeEnum,
    CounterpartyActivityTypeCodeEnum,
    RenterInfoCreate,
    RenterInfoRead,
    RenterInfoUpdate,
    RenterStateCodeEnum,
} from '../../../../../../server';
import { showConfirm } from '../../../../../../components/confirm/showConfirm';
import { ModalFormNEW, ModalFormNEWProps } from '../../../../../../components/modalForm/ModalFormNEW';
import { FormFieldsGroup } from '../../../../../../components/dynamicForm/DynamicForm';
import { formFields } from './CounterpartyCreateModalFields';
import { getImagePath, printUserName } from '../../../../../../shared/util/utils';
import { LabeledValue } from 'antd/lib/select';
import { LocalizationEnum, localizeIntl } from '../../../../../../localization';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { loadCategories } from '../../../../../../shared/reducers/entities.reducer';
import { updateModalFilters } from '../../../../../../shared/reducers/userSettings/userSettings.reducer';
import { showNotification } from '../../../../../../components/notification/showNotification';

export interface CounterpartyCreateModalProps extends ModalFormNEWProps, WrappedComponentProps {
    formFields?: FormFieldsGroup[];
    renter?: LabeledValue;
    copyMode?: boolean;
    validateAfterCreate?: boolean;
    initialValues?: any;
    editMode: boolean;
    counterpartyType?: 'renter' | 'supplier';
}

class _CounterpartyCreateModal extends ModalFormNEW<CounterpartyCreateModalProps & StateProps & DispatchProps> {
    static defaultProps = {
        formFields: formFields,
    };

    constructor(props) {
        super(props);

        this.props.loadCategories(this.props.businessAccountId, CategoryTypeCodeEnum.RENTER);
    }

    onOk = async (data: any) => {
        delete data['_defaultDiscount'];
        delete data['renterDefaultDiscount'];

        if (this.props.initialValues) {
            if (!this.props.copyMode) {
                // Редактирование
                let newData: any = { ...this.props.initialValues, ...data } as RenterInfoUpdate;
                newData = this.processFormData(newData);
                if (newData.fullName === '') newData.fullName = undefined;
                this.props.updateRenter(this.props.intl, this.props.businessAccountId, newData.id || 0, newData);
            } else {
                // Копирование
                let newData: any = { ...this.props.initialValues, ...data } as RenterInfoUpdate;
                newData = this.processFormData(newData);
                if (newData.fullName === '') newData.fullName = undefined;

                try {
                    let request: RenterInfoRead = (await this.props.createRenter(this.props.businessAccountId, newData)) as any;
                    showNotification(
                        'success',
                        localizeIntl(this.props.intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__CREATED)
                    );
                    this.props.onSuccess?.(request);
                } catch (err) {
                    showNotification(
                        'error',
                        localizeIntl(this.props.intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__FAILED_TO_CREATE_RENTER)
                    );
                }
            }
        } else {
            // Создание
            let newData: RenterInfoCreate = {
                defaultDiscount: 0,
                ...this.props.initialValues,
                ...data,
            } as RenterInfoCreate;
            newData = this.processFormData(newData) as RenterInfoCreate;
            if (newData.fullName === '') newData.fullName = undefined;
            try {
                let request: RenterInfoRead = (await this.props.createRenter(this.props.businessAccountId, newData)) as any;
                showNotification('success', localizeIntl(this.props.intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__CREATED));
                this.props.onSuccess?.(request);
            } catch (err) {
                showNotification(
                    'error',
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__RENTERS__POPUP_NOTIFICATIONS__FAILED_TO_CREATE_RENTER)
                );
            }
        }
    };

    processFormData = (data: RenterInfoUpdate | RenterInfoCreate): RenterInfoUpdate | RenterInfoCreate => {
        let imageId: number | undefined;
        if ('imageData' in data) {
            let tmpData = data as any;
            imageId =
                tmpData.imageData && tmpData.imageData[0] && tmpData.imageData[0].response && tmpData.imageData[0].response.id
                    ? tmpData.imageData[0].response.id
                    : undefined;
            delete tmpData.imageData;
        }

        if (data.description === '') delete data.description;
        if (data.comment === '') delete data.comment;

        if(data.contactLinks){
            data.contactLinks = data.contactLinks.filter((contactLink)=> contactLink.contactId);
        }
        data.image = imageId ? { id: imageId } : undefined;
        if (data.assigneeId) data.assigneeId = data.assigneeId['key'];

        if (!data.activityTypeCode) data.activityTypeCode = CounterpartyActivityTypeCodeEnum.RENTERONLY;

        return data;
    };

    getInitialValues = () => {
        let initialValues;

        if (this.props.initialValues) {
            initialValues = { ...this.props.initialValues } as RenterInfoUpdate;

            initialValues.categoryIds = this.props.initialValues.categories
                ? this.props.initialValues.categories.map((item) => item.id)
                : [];
            if (this.props.initialValues.image) {
                initialValues['imageData'] = [
                    {
                        uid: this.props.initialValues.image.id,
                        url: getImagePath(this.props.initialValues.image),
                        response: this.props.initialValues.image,
                    },
                ];
            }

            if (initialValues.assigneeId) {
                initialValues.assigneeId = {
                    key: '' + initialValues.assigneeId,
                    label: <span>{printUserName(initialValues.assigneeFullName)}</span>,
                };
            }
        }

        if (this.props.counterpartyType) {
            if (!initialValues) initialValues = {};
            initialValues.counterpartyType = this.props.counterpartyType;
            if (initialValues.counterpartyType === 'renter') {
                initialValues.activityTypeCode = CounterpartyActivityTypeCodeEnum.RENTERONLY;
            }
            if (initialValues.counterpartyType === 'supplier') {
                initialValues.activityTypeCode = CounterpartyActivityTypeCodeEnum.SUPPLIERONLY;
            }
        } else {
            if (!initialValues) initialValues = {};
            if (!initialValues.id) {
                initialValues.activityTypeCode = CounterpartyActivityTypeCodeEnum.RENTERONLY;
            }
        }

        if (this.props.copyMode) {
            delete initialValues.id;
            if (initialValues.stateCode === RenterStateCodeEnum.BLOCKED) {
                initialValues.stateCode = RenterStateCodeEnum.ACTIVE;
            }
        } else {
            initialValues.renterEdit = true;
        }

        return initialValues;
    };

    onCancel = async (isFieldsTouched: Boolean) => {
        let yes =
            !isFieldsTouched ||
            (await showConfirm(
                this.props.intl,
                this.props.initialValues
                    ? localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_EDIT)
                    : localizeIntl(this.props.intl, LocalizationEnum.ASPECT__MODAL__ABORT_CREATION)
            ));
        if (yes) {
            this.props.onCancel?.();
            this.props.resetErrors();
        }
    };
}

const mapStateToProps = (storeState: IRootState) => ({
    updating: storeState.renter.updating,
    updatingError: storeState.renter.updatingError,
    modalFilters: storeState.userSettings.modalFilters,
});

const mapDispatchToProps = {
    createRenter,
    updateRenter,
    goBack,
    resetErrors,
    push,
    loadCategories,
    updateModalFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const CounterpartyCreateModal = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_CounterpartyCreateModal));
