import sortBy from "lodash/sortBy";
import {OperationElement} from "../../modules/main/operationForm/reducers/operationForm.reducer";

const sortByFieldNameIgnoreCasing = (row, fieldName:string) => {
    return fieldName ? sortBy(row, [row=>typeof row[fieldName] === 'string' ? row[fieldName].toLowerCase() : row[fieldName]]) : row;
}

export const sortOperationElements = (operationElements:OperationElement[], fieldName:string) => {
    const notKitMembers =  operationElements.filter((el)=>el.parentId === undefined);
    const kitMembers =  operationElements.filter((el)=>el.parentId !== undefined);
    return [...sortByFieldNameIgnoreCasing(notKitMembers, fieldName), ...kitMembers];
}