import { withEntityListProviders } from '../../../../components/page/entityGrid/components/decorators/withEntityListProviders';
import { logisticsPageUrlRoute } from '../../logistics/data/tabs';
import { historyPageUrlRoute } from '../../history/HistoryPage';
import { GridProps } from '../../../../components/grid/Grid';
import { ListExpensesQueryParams } from '../api/expenses.api.types';
import { ModalFormNEWProps } from '../../../../components/modalForm/ModalFormNEW';
import React, { FC } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { EntityList } from '../../../../components/page/entityGrid/components/ui/EntityList/EntityList';
import { expensesColumns } from './columns/expensesColumns';
import { expensesFilters } from './filters/expensesFilters';
import { ExpensesPageFiltersDescription } from './filters/expensesFiltersDescription';
import { useEntityGridContext } from '../../../../components/page/entityGrid/components/context/GridRefContext';
import { useEntitySelection } from '../../../../components/page/entityGrid/components/context/EntitiesSelectionContext';
import { expensesLoadingSelector } from '../reducers/expenses/expenses.selectors';
import { expenseEntitySelector } from '../reducers/expense/expense.selectors';
import { useEntityActions, useEntityActionsButtons } from '../hooks/expensesListHooks';
import { useSelectedRecords } from '../../../../components/page/entityGrid/hooks/useSelectedRecords';
import { ExpenseDrawerWrapper } from '../drawer/components/wrappers/expenseDrawerWrapper';
import { CreateModalWrapperProps, ExpenseCreateModalWrapper } from '../modal/create/expenseCreateModalWrapper';
import {EditModalWrapperProps, ExpenseEditModalWrapper} from '../modal/edit/expenseEditModalWrapper';
import { clearExpense, loadExpense } from '../reducers/expense/expense.reducer';
import { EntityGridRootPath } from '../../../../components/page/entityGrid/types/params';
import { expensesOuterParams } from './filters/expensesFilters.data';
import { ExpenseRecord, ProjectInfoRead, ProjectStateCodeEnum } from '../../../../server';
import { usePageURLParamsFromDescription, useQueryParamsWithoutModalParams } from '../../../../components/page/entityGrid/hooks/useParams';
import { useListExpensesQuery, useMoveExpensesToAnotherProjectMutation } from '../api/expenses.api';
import { ProjectPageGridNamesEnum } from '../../projects/production/page/projectPage';
import { OfferPageGridNamesEnum } from '../../offers/page/offerPage';
import { useExportBlock } from '../hooks/useExportBlock';

import { HistoryPageTabsEnum, LogisticsPageTabsEnum, ProjectPageTabsEnum } from '../../../../shared/constants/tabEnums';
import {SimpleOrderGridNamesEnum} from "../../projects/simpleOrders/page/simpleOrderPage";

interface ExpensesListComponentProps {
    gridName:
        | LogisticsPageTabsEnum
        | ProjectPageTabsEnum.EXPENSES
        | HistoryPageTabsEnum.EXPENSES
        | ProjectPageGridNamesEnum.EXPENSES
        | OfferPageGridNamesEnum.EXPENSES
        | SimpleOrderGridNamesEnum.EXPENSES;
    urlPath:
        | `${typeof logisticsPageUrlRoute}/${LogisticsPageTabsEnum}`
        | `projects/production/${number}?tab=expenses`
        | `projects/simpleOrders/${number}?tab=expenses`
        | `projects/offers/${number}?tab=expenses`
        | `${typeof historyPageUrlRoute}/${HistoryPageTabsEnum.EXPENSES}`;
    gridProps?: Partial<GridProps>;
    staticPageParams?: Partial<ListExpensesQueryParams>;
    parentProjectEntity?: ProjectInfoRead;
    projectStateCode?: ProjectStateCodeEnum;
    modalProps?: Partial<ModalFormNEWProps>;
    visible?: boolean;
}

const ExpensesListComponent: FC<ExpensesListComponentProps> = (props) => {
    const { gridName, urlPath, visible, staticPageParams, gridProps, modalProps, parentProjectEntity } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const currentEntity = useAppSelector(expenseEntitySelector);
    const entitiesLoading = useAppSelector(expensesLoadingSelector);
    const gridRef = useEntityGridContext();
    const entitySelection = useEntitySelection();

    const pageParamsObject = usePageURLParamsFromDescription({
        pageParamsDescription: ExpensesPageFiltersDescription,
        gridName,
        skipLocationChange: !visible,
        outerParams: expensesOuterParams,
    });
    const { pageParams, getSearchParams } = pageParamsObject;
    const urlSearchParams = getSearchParams(pageParams);
    const queryParams = useQueryParamsWithoutModalParams(pageParams);
    const queryData = useListExpensesQuery(
        {
            businessAccountId: businessAccountId,
            params: {
                ...queryParams,
                ...staticPageParams,
            },
            projectId: parentProjectEntity?.id,
        },
        {
            refetchOnReconnect: true,
            refetchOnMountOrArgChange: true,
        }
    );

    const [moveExpensesToAnotherProjectMutation, { isLoading }] = useMoveExpensesToAnotherProjectMutation();

    let rootPath = `/${businessAccountId}/${urlPath}` as EntityGridRootPath;
    if (parentProjectEntity?.id) {
        rootPath = rootPath.split('?')[0] as EntityGridRootPath;
    }

    const entityActions = useEntityActions({
        rootPath,
        urlSearchParams,
    });

    const selectedRecords = useSelectedRecords<ExpenseRecord>(entitySelection, queryData.data?.entitiesData.records);
    const entityActionsButtons = useEntityActionsButtons({
        selectedRecords,
        gridRef,
        parentProjectEntity,
        moveExpensesToAnotherProjectMutation,
    });

    const createModalProps: Partial<CreateModalWrapperProps<ProjectInfoRead>> = {
        ...modalProps,
        parentProjectEntity,
    };

    const editModalProps: Partial<EditModalWrapperProps<ProjectInfoRead>> = {
        ...modalProps,
        parentProjectEntity,
    };

    const exportBlock = useExportBlock(queryParams, staticPageParams, parentProjectEntity?.id);

    return (
        <EntityList
            pageParamsObject={pageParamsObject}
            gridProps={{
                static: {
                    columns: expensesColumns,
                    filtersData: expensesFilters,
                    pageParamsDescription: ExpensesPageFiltersDescription,
                },
                metaData: {
                    entityType: 'expense',
                    gridName,
                    rootPath,
                },
                queryData,
                entitiesLoading: entitiesLoading || isLoading,
                entityActions,
                actionButtons: entityActionsButtons,
                exportBlock,
                ...gridProps,
            }}
            modalProps={{
                currentEntity,
                modals: {
                    create: {
                        component: ExpenseCreateModalWrapper,
                        props: createModalProps,
                    },
                    edit: {
                        component: ExpenseEditModalWrapper,
                        props: editModalProps
                    },
                },
                entityLoadActions: {
                    loadEntity: loadExpense,
                    clearEntity: clearExpense,
                },
            }}
            drawerProps={{
                drawer: ExpenseDrawerWrapper,
                entityActions,
            }}
        />
    );
};

export const ExpensesList = withEntityListProviders(ExpensesListComponent);
