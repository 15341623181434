import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { getPathFromState, getStateFromPath, getStateFromPath2 } from '../../../../shared/util/utils';
import '../products/tabs/features/product-page-features-tab.less';
import './product-page-instances.less';
import { Card, Icon } from 'antd';
import { push, replace } from 'connected-react-router';
import columns from './list/product-page-instances-colums';
import { RoundButton, Spin } from '../../../../components';
import { IconBoxesSolid, IconCube, IconCubesSolid, IconPlus, IconTrash } from '../../../../components/icons';
import AddProductInstance from './modal/product-page-instances-modal';
import { deleteEntities, getProductInstancesFilters, initialParamsState, loadEntities, reset } from './reducers/product-instances.reducer';
import { filters } from './list/product-page-instances-filters';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { transitProductInstanceWorkflowBulk } from './reducers/product-instanсe.reducer';
import {
    ExcelExportTemplateTypeCodeEnum,
    InstanceInfoRead,
    InstanceNotFinancialFieldsTypeCode,
    InstanceRecord,
    InstanceStateCodeEnum,
    InstanceTrackingTypeCodeEnum,
    InstanceTransitionCodeEnum,
    NomenclatureNotFinancialFieldsTypeCode,
    VariantRecord,
} from '../../../../server/api';
import every from 'lodash/every';
import { LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import { Grid } from '../../../../components/grid/Grid';
import { getGridStorageData, setGridStorageDataFilters } from '../../../../components/grid/utils';
import { PageUtils } from '../../../../shared/util/pageUtils';
import { showConfirm2 } from '../../../../components/confirm/showConfirm';
import { createProductInstancesModalFilters, editProductInstancesModalFilters } from './modal/product-page-instances-fields-data';
import { EntityActionType } from '../../../../../index';
import { DeepKeys } from '@tanstack/react-table';
import { subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { ProductInstancePopover } from './components/productInstancePopover/productInstancePopover';
import { ExportOffloadingPopover } from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover';
import { OffloadingDocumentOnDownload } from '../../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data';
import { downloadOffloading } from '../../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading';
import { canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { AddProductInstanceBulkModal } from './modal/AddProductInstanceBulkModal';

interface IState {
    selection: number[];
    instance: number;
    spinner: boolean;
}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {}

const PRODUCT_INSTANCES_GRID_NAME = 'product-instances';

const instancesGridColumnsMap: Partial<Record<DeepKeys<InstanceRecord>, InstanceNotFinancialFieldsTypeCode>> = {
    'warehousePresenceCounters.subrentedInstanceCount': InstanceNotFinancialFieldsTypeCode.SubrentedInstanceCount,
    'warehousePresenceCounters.rentedInstanceCount': InstanceNotFinancialFieldsTypeCode.RentedInstanceCount,
    'warehousePresenceCounters.warehouseInstanceCount': InstanceNotFinancialFieldsTypeCode.WarehouseInstanceCount,
};

class ProductPageInstancesTabPane extends React.PureComponent<IProps, IState> {
    private filtersForm;
    private fromFilters;
    private initialValues;
    private grid;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = ['sortBy', 'sortOrder', 'search', 'variantId', 'hideArchive', 'stateCode', 'supplierId'];

    constructor(props: IProps) {
        super(props);
        this.state = {
            selection: [],
            instance: 0,
            spinner: false,
        };
    }

    componentDidMount = () => {
        console.log(`${this.constructor.name} componentDidMount()`);
        this.initialValues = {
            ...this.props.pageParams,
            productId: this.props.entity?.id,
            isVariants:
                this.props.entity!.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
                this.props.entity!.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED,
            stateCode: this.getStateCodeParam(),
        };

        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.location.search),
            this.filters,
            () => {
                this.setSavedParams(this.props.location.search);
            },
            this.loadDataAndSetFilterValues
        );
    };

    componentDidUpdate = (prevProps: IProps) => {
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            this.filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );

        let param = getStateFromPath2(this.props.locationSearchParams);
        if (
            param.tab === 'instances' &&
            this.props.entities === null &&
            this.props.productsInstancesLoading === prevProps.productsInstancesLoading
        ) {
            this.props.loadEntities(this.props.intl, this.props.businessAccountId, this.props.entity!.id);
        }

        if (prevProps.entities && prevProps.entities !== this.props.entities) {
            this.clearSelection();
        }
    };

    componentWillUnmount = () => {
        console.log(`${this.constructor.name} componentWillUnmount()`);
        this.props.reset();
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(PRODUCT_INSTANCES_GRID_NAME);
        this.updatingAfterSetFromStorage = true;

        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    getStateCodeParam = () => {
        let stateCode = this.props.pageParams.stateCode;
        if (
            !this.props.subrentModuleEnabled &&
            (stateCode === InstanceStateCodeEnum.SUPPLIERACTIVE || stateCode === InstanceStateCodeEnum.SUPPLIERDECOMMISSIONED)
        )
            stateCode = undefined;
        return stateCode;
    };

    loadDataAndSetFilterValues = () => {
        this.loadInstancesProduct();
        if (!this.fromFilters) {
            this.filtersForm.setFieldsValue({ ...initialParamsState, ...this.props.pageParams, stateCode: this.getStateCodeParam() });
        }
        this.fromFilters = false;
    };

    onSetCountButtonClick = () => {
        const path = getPathFromState(
            `/${this.props.businessAccountId}/inventory/products/${this.props.entity?.id}/addInstance`,
            window.location.search
        );
        this.props.push(path);
    };

    onAddInstanceBulkButtonClick = () => {
        const path = getPathFromState(
            `/${this.props.businessAccountId}/inventory/products/${this.props.entity?.id}/addInstanceBulk`,
            window.location.search
        );
        this.props.push(path);
    };

    onUpdateCountButtonClick = async () => {
        const path = getPathFromState(
            `/${this.props.businessAccountId}/inventory/products/${this.props.entity?.id}/editInstance`,
            window.location.search,
            { id: this.state.instance }
        );
        this.props.push(path);
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, {
                sortBy: id,
                sortOrder: desc ? 'DESC' : 'ASC',
            })
        );
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
        this.clearSelection();
    };

    onPageSizeChanged = (size) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { limit: size, page: 1 }));
        this.clearSelection();
    };

    onSelectionChanged = (data) => {
        this.setState({ selection: data });
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    onFiltersChange = (data) => {
        this.resetFilters();
        this.updateFilters(data);
    };

    updateFilters = (data) => {
        const newData = data;

        data.productId = this.props.entity?.id;
        data.variantId = data.variantId && data.variantId.key ? data.variantId.key : data.variantId ? data.variantId : undefined;
        data.supplierId = data.supplierId && data.supplierId.key ? data.supplierId.key : data.supplierId ? data.supplierId : undefined;

        delete data.productId;
        delete data.isVariants;
        this.fromFilters = true;

        data.page = 1;
        setGridStorageDataFilters(PRODUCT_INSTANCES_GRID_NAME, data);
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, newData));
    };

    loadInstancesProduct = () => {
        this.props.loadEntities(this.props.intl, this.props.businessAccountId, this.props.entity!.id);
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    onItemAction = async (item: InstanceInfoRead, type: InstanceTransitionCodeEnum | EntityActionType) => {
        this.setState({
            spinner: true,
        });

        const handlers = {
            edit: async () => {
                await this.setState({
                    instance: item.id,
                });
                this.onUpdateCountButtonClick();
            },
            delete: async () => {
                let yes = await showConfirm2({ intl: this.props.intl, title: 'Удалить экземпляр безвозвратно?' });
                if (yes) {
                    this.props.deleteEntities(this.props.intl, this.props.businessAccountId, this.props.entity!.id, [
                        { id: item.id, businessVersion: item.businessVersion },
                    ]);
                    this.clearSelection();
                }
            },
            copy: () => {
                this.setState({
                    instance: item.id,
                });
                this.props.push(
                    `/${this.props.businessAccountId}/inventory/products/${this.props.entity?.id}/addInstanceCopy?tab=instances&id=${item.id}`
                );
            },
        };

        const defaultHandler = () => {
            this.props.transitProductInstanceWorkflowBulk(this.props.intl, this.props.businessAccountId, this.props.entity!.id, [
                { id: item.id, businessVersion: item.businessVersion, transitionCode: type },
            ]);
        };

        await (handlers[type] ?? defaultHandler)();
    };

    onChangeStateButtonClick = (typeAction: InstanceTransitionCodeEnum) => {
        this.setState({
            spinner: true,
        });

        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((el: InstanceRecord) => {
                    return this.state.selection.indexOf(el.id) > -1;
                })
                .map((item) => {
                    return {
                        id: item.id,
                        businessVersion: item.businessVersion,
                        transitionCode: typeAction,
                    };
                });

            this.props.transitProductInstanceWorkflowBulk(
                this.props.intl,
                this.props.businessAccountId,
                this.props.entity!.id,
                selectedItems
            );
            this.clearSelection();
        }
    };

    onDeleteBtnClick = async () => {
        if (this.props.entities) {
            let selectedItems = this.props.entities
                .filter((item) => {
                    return this.state.selection.indexOf(item.id) > -1;
                })
                .map((item) => ({ id: item.id, businessVersion: item.businessVersion }));
            let isMany = selectedItems.length > 1;
            let yes = await showConfirm2({
                intl: this.props.intl,
                title: isMany ? 'Удалить экземпляры безвозвратно?' : 'Удалить экземпляр безвозвратно?',
            });
            if (yes) {
                this.props.deleteEntities(this.props.intl, this.props.businessAccountId, this.props.entity!.id, selectedItems);
                this.clearSelection();
            }
        }
    };

    getActionButtons = () => {
        let selectedItems = this.props.entities
            ? this.props.entities.filter((el) => {
                  return this.state.selection.indexOf(el.id) > -1;
              })
            : [];

        return [
            every(selectedItems, (item) => item.availableTransitionCodes?.includes(InstanceTransitionCodeEnum.ACTIVATE)) ? (
                <RoundButton
                    key={0}
                    colorScheme={'success'}
                    onClick={() => this.onChangeStateButtonClick(InstanceTransitionCodeEnum.ACTIVATE)}
                >
                    {localize(LocalizationEnum.ASPECT__ACTION__ACTIVATE)}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(InstanceTransitionCodeEnum.SENDUNDERMAINTENANCE)) ? (
                <RoundButton
                    key={1}
                    className="actions-UNDER_MAINTENANCE"
                    colorScheme={'UNDER_MAINTENANCE'}
                    onClick={() => this.onChangeStateButtonClick(InstanceTransitionCodeEnum.SENDUNDERMAINTENANCE)}
                >
                    {localize(LocalizationEnum.ASPECT__INSTANCE_TRANSITIONS__SEND_UNDER_MAINTENANCE)}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(InstanceTransitionCodeEnum.DECOMMISSION)) ? (
                <RoundButton
                    key={2}
                    colorScheme={'CANCEL'}
                    onClick={() => this.onChangeStateButtonClick(InstanceTransitionCodeEnum.DECOMMISSION)}
                >
                    {localize(LocalizationEnum.ASPECT__INSTANCE_TRANSITIONS__DECOMISSION)}
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(InstanceTransitionCodeEnum.SUPPLIERACTIVATE)) ? (
                <RoundButton
                    key={3}
                    colorScheme={'success'}
                    onClick={() => this.onChangeStateButtonClick(InstanceTransitionCodeEnum.SUPPLIERACTIVATE)}
                >
                    Активировать чужой
                </RoundButton>
            ) : null,

            every(selectedItems, (item) => item.availableTransitionCodes?.includes(InstanceTransitionCodeEnum.SUPPLIERDECOMMISSION)) ? (
                <RoundButton
                    key={4}
                    colorScheme={'CANCEL'}
                    onClick={() => this.onChangeStateButtonClick(InstanceTransitionCodeEnum.SUPPLIERDECOMMISSION)}
                >
                    Списать чужой
                </RoundButton>
            ) : null,

            every(selectedItems, (item: InstanceRecord) => item.deleteable) ? (
                <RoundButton key={5} title={'Удалить'} onClick={this.onDeleteBtnClick} colorScheme={'DELETE'}>
                    <Icon component={IconTrash} />
                    Удалить
                </RoundButton>
            ) : null,
        ];
    };

    spin = () => {
        this.setState({
            spinner: true,
        });
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            variantId: undefined,
            stateCode: undefined,
            hideArchive: undefined,
            supplierId: undefined,
        };
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(PRODUCT_INSTANCES_GRID_NAME, data);
    };

    onOffloadingDownload: OffloadingDocumentOnDownload = async ({ fileType, options }) => {
        let { pageParams, businessAccountId, canViewFinancialData, subrentModuleEnabled, intl } = this.props;

        const { sortOrder, sortBy } = pageParams;
        const filters = getProductInstancesFilters(pageParams, subrentModuleEnabled); //[];//getProductVariantsFilters(pageParams);//ProductsApiUtils.createEntityRequestFilters(pageParams, subrentModuleEnabled);

        filters.push(`productId;EQ;${this.props.entity?.id}`);

        await downloadOffloading({
            businessAccountId,
            canViewFinancialData,
            exportTemplateType: ExcelExportTemplateTypeCodeEnum.INSTANCETEMPLATE,
            fileName: 'Экспорт экземпляров',
            fileType,
            filters,
            financialFieldsEnum: InstanceNotFinancialFieldsTypeCode,
            grid: this.grid,
            intl,
            notFinancialFieldsEnum: InstanceNotFinancialFieldsTypeCode,
            selection: this.state.selection,
            values: options,
            sortOrder,
            sortBy,
            excludeColumns: undefined,
            search: pageParams.search,
            columnsMap: instancesGridColumnsMap,
        });
    };

    render() {
        const { subrentModuleEnabled } = this.props;
        const idInstance = getStateFromPath2(this.props.location.search).id;

        let excludeColumns: DeepKeys<InstanceRecord>[] = [];
        let filtersExcludeFields: DeepKeys<InstanceRecord>[] = [];

        if (
            this.props.entity!.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTBULK ||
            this.props.entity!.instanceTrackingTypeCode === InstanceTrackingTypeCodeEnum.VARIANTINSTANCETRACKED
        ) {
        } else {
            excludeColumns.push('variantName');
        }

        if (!subrentModuleEnabled) {
            excludeColumns.push('warehousePresenceCounters.subrentedInstanceCount', 'supplierShortName');
            filtersExcludeFields.push('supplierId');
        }

        if (this.props.productsInstancesLoading) {
            this.setState({
                spinner: false,
            });
        }

        return (
            <>
                <Card
                    className={'rr-card product-page-instances-header'}
                    bodyStyle={{ display: 'none' }}
                    title={
                        <span className="card-title">
                            <Icon component={IconCube} className="product-page-instances-header-icon" />
                            {localize(LocalizationEnum.PAGE__INSTANCES__INSTANCE_LIST)}
                        </span>
                    }
                    extra={
                        <>
                            <RoundButton
                                colorScheme={'successSecondary'}
                                onClick={this.onSetCountButtonClick}
                                title={'' + localizeIntl(this.props.intl, LocalizationEnum.PAGE__INSTANCES__CREATE_INSTANCE)}
                                style={{ marginRight: 16 }}
                            >
                                <Icon component={IconPlus} />
                                {localize(LocalizationEnum.PAGE__INSTANCES__CREATE_INSTANCE, 'span')}
                            </RoundButton>
                            <RoundButton
                                colorScheme={'successSecondary'}
                                onClick={this.onAddInstanceBulkButtonClick}
                                title={'Создать массово'}
                                style={{ marginRight: 16 }}
                            >
                                <Icon component={IconCubesSolid} />
                                <span>Создать массово</span>
                            </RoundButton>
                        </>
                    }
                />
                <Spin spinning={this.props.productsInstancesLoading}>
                    <Card className={'rr-card'}>
                        <Grid
                            filtersData={filters}
                            filtersInitialValues={this.initialValues}
                            filtersCurrentValues={{ ...this.props.pageParams, stateCode: this.getStateCodeParam() }}
                            filtersDefaultValues={initialParamsState}
                            filtersGetFiltersFormRef={this.getFiltersForm}
                            filtersOnChange={this.onFiltersChange}
                            filtersResetFiltersCb={this.resetFilters}
                            filtersExcludeFields={filtersExcludeFields}
                            excludeColumns={excludeColumns}
                            ref={(ref) => {
                                if (!this.grid) this.grid = ref;
                            }}
                            onSortedChange={this.onSortedChange}
                            onPageChanged={this.onPageChanged}
                            onPageSizeChanged={this.onPageSizeChanged}
                            onSelectionChanged={this.onSelectionChanged}
                            filtered={this.props.filteredCount}
                            pageSize={this.props.pageParams.limit}
                            currentPage={this.props.pageParams.page}
                            columns={columns}
                            data={this.props.entities}
                            indexFieldName={'id'}
                            entityType={'product-instances'}
                            actionButtons={this.getActionButtons()}
                            defaultSorted={this.props.pageParams.sortBy}
                            defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                            onRowAction={this.onItemAction}
                            rowPopoverComponent={ProductInstancePopover}
                            gridName={PRODUCT_INSTANCES_GRID_NAME}
                            exportBlock={
                                <ExportOffloadingPopover storageKey={'productInstancesOffloading'} onDownload={this.onOffloadingDownload} />
                            }
                        />
                        {this.props.addCountMode ? (
                            <AddProductInstance
                                spin={this.spin}
                                entityId={this.state.instance}
                                editMode={false}
                                title={localize(LocalizationEnum.PAGE__FORM__FORM_TITLE__CREATE)}
                                okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                                filters={createProductInstancesModalFilters}
                                modalName={'create-product_instances'}
                            />
                        ) : null}
                        {this.props.addInstanceBulkMode ? (
                            <AddProductInstanceBulkModal title="Массовое создание экземпляров" width={850} />
                        ) : null}
                        {this.props.addCountModeCopy ? (
                            <AddProductInstance
                                spin={this.spin}
                                entityId={idInstance ? idInstance : this.state.instance}
                                editMode={false}
                                title={localize(LocalizationEnum.PAGE__FORM__FORM_TITLE__CREATE)}
                                okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                                filters={createProductInstancesModalFilters}
                                modalName={'create-product_instances'}
                                validateAfterCreate
                                copyMode
                            />
                        ) : null}
                        {this.props.editCountMode ? (
                            <AddProductInstance
                                spin={this.spin}
                                entityId={idInstance ? idInstance : this.state.instance}
                                editMode={true}
                                title={localize(LocalizationEnum.PAGE__FORM__FORM_TITLE__EDIT)}
                                okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__SAVE)}
                                filters={editProductInstancesModalFilters}
                                modalName={'edit-product_instances'}
                            />
                        ) : null}
                    </Card>
                </Spin>
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    let searchParams = getStateFromPath(storeState.router.location.search);
    return {
        entity: storeState.product.entity,
        entities: storeState.productsInstancesList.entities,
        productsInstancesLoading: storeState.productsInstancesList.loading,
        filteredCount: storeState.productsInstancesList.filteredCount,
        editMode: searchParams && searchParams.edit === true ? true : false,
        location: storeState.router.location,
        locationSearchParams: storeState.router.location.search,
        pageParams: storeState.productsInstancesList.params,
        businessAccountId: storeState.system.businessAccountId,
        addCountMode: !!(
            storeState.product.entity &&
            storeState.router.location.pathname ===
                `/${storeState.system.businessAccountId}/inventory/products/${storeState.product.entity?.id}/addInstance`
        ),
        addInstanceBulkMode: !!(
            storeState.product.entity &&
            storeState.router.location.pathname ===
                `/${storeState.system.businessAccountId}/inventory/products/${storeState.product.entity?.id}/addInstanceBulk`
        ),
        addCountModeCopy: !!(
            storeState.product.entity &&
            storeState.router.location.pathname ===
                `/${storeState.system.businessAccountId}/inventory/products/${storeState.product.entity?.id}/addInstanceCopy`
        ),
        editCountMode: !!(
            storeState.product.entity &&
            storeState.router.location.pathname ===
                `/${storeState.system.businessAccountId}/inventory/products/${storeState.product.entity?.id}/editInstance`
        ),
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
    };
};

const mapDispatchToProps = { push, replace, loadEntities, reset, transitProductInstanceWorkflowBulk, deleteEntities };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductPageInstancesTabPane));
