import React from 'react';
import { Card } from 'antd';
import {getOperationsListFilters, initialParamsState, loadEntities, reset} from './reducers/operations.reducer';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { push, replace } from 'connected-react-router';
import { Spin } from '../../../components';
import {
    convertAvailableFiltersToObj,
    getFilteredParams,
    getPathFromState,
    getStateFromPath2,
    roundMaxFilterValue,
    roundMinFilterValue,
} from '../../../shared/util/utils';
import {
    ExcelExportTemplateTypeCodeEnum,
    OperationFinancialFieldsTypeCode, OperationNotFinancialFieldsTypeCode,
    OperationRecord,
    serverApi
} from '../../../server';
import { columns } from './operationsListColumns';
import { Location } from 'history';
import moment from 'moment';
import { filters, templateFilters } from './operationsListFilters';
import debounce from 'lodash/debounce';
import { IFormField } from '../../../components/dynamicForm/DynamicForm';
import { logEvent } from '../../../analytics/analytics';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Grid } from '../../../components/grid/Grid';
import { getGridStorageData, setGridStorageDataFilters } from '../../../components/grid/utils';
import { PageUtils } from '../../../shared/util/pageUtils';
import { canViewFinancialData } from '../../../shared/util/permissionUtils';
import { subrentModuleEnabledSelector } from '../../../shared/reducers/businessAccountPreferences.reducer';
import { DeepKeys } from '@tanstack/react-table';
import { ProblemEnum } from '../../../types';
import { ServerUtils } from '../../../core/utils/serverUtils';
import { GridName } from '../../../components/grid/utils/types';
import {ExportOffloadingPopover} from "../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/ExportOffloadingPopover";
import {OffloadingDocumentOnDownload} from "../../../components/exportPopovers/downloadDocument/instances/ExportOffloadingPopover/utils/data";
import {downloadOffloading} from "../../../components/exportPopovers/downloadDocument/utils/offloading/downloadOffloading";

interface IState {
    selection: number[];
}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {
    location: Location;
    projectId?: number;
    renterId?: number;
    gridName: GridName;
    parentEntityType?: 'project' | 'projectTemplate' | 'shipping' | 'counterparty';
}

class Component extends React.Component<IProps, IState> {
    private filtersForm;
    private fromFilters;
    private grid;
    private initialValues;
    private availableFilters;
    private updatingAfterSetFromStorage: boolean = false;
    private filters: string[] = [
        'sortBy',
        'sortOrder',
        'search',
        'typeCode',
        'renterId',
        'projectId',
        'subrentId',
        'hideAuto',
        'delay',
        'shortage',
        'finalTotalPrice',
        'creationAuthorId',
        'startDate',
        'problem',
        'authorId',
        'screenLeft',
        'screenRight',
    ];
    private templateFilters: string[] = [
        'sortBy',
        'sortOrder',
        'search',
        'typeCode',
        'templateId',
        'renterId',
        'subrentId',
        'hideAuto',
        'delay',
        'shortage',
        'finalTotalPrice',
        'creationAuthorId',
        'startDate',
    ];
    private isComponentUnmount = false;

    constructor(props: IProps) {
        super(props);

        this.state = {
            selection: [],
        };

        this.updateFilters = debounce(this.updateFilters, 300);
    }

    componentDidMount = async () => {
        //console.log('OperationsList componentDidMount()');

        let parentEntityType: any = undefined;

        if (this.props.parentEntityType === 'project') {
            parentEntityType = 'project';
        } else if (this.props.parentEntityType === 'shipping') {
            parentEntityType = 'shipping';
        } else if (this.props.parentEntityType === 'projectTemplate') {
            parentEntityType = 'projectTemplate';
        }

        this.initialValues = { ...this.props.pageParams, parentEntityType, ...this.getSubrentParams() };

        try {
            let availableFiltersValues = await serverApi.listOperationsAvailableFiltersValues(
                this.props.businessAccountId,
                ServerUtils.createRequestFilters<OperationRecord>([
                    this.props.parentEntityType === 'project' && this.props.projectId && ['projectId', 'EQ', this.props.projectId],
                    this.props.parentEntityType === 'shipping' && this.props.projectId && ['subrentId', 'EQ', this.props.projectId],
                    this.props.parentEntityType === 'projectTemplate' && this.props.renterId && ['templateId', 'EQ', this.props.renterId],
                    this.props.parentEntityType === 'counterparty' && this.props.renterId && ['counterpartyId', 'EQ', this.props.renterId],
                ])
            );

            this.availableFilters = convertAvailableFiltersToObj(availableFiltersValues.data.filters);
            let filter = this.availableFilters['operationIndicators.finalTotalPrice'];
            if (filter) {
                let field = (filters[1].fields as IFormField[]).find((item) => item.id === 'finalTotalPrice');
                if (field) {
                    const props = typeof field.componentProps !== 'function' ? field.componentProps : {};
                    field.componentProps = {
                        ...props,
                        formatStyle: 'currency',
                        min: roundMinFilterValue(filter[0] / 100),
                        max: roundMaxFilterValue(filter[1] / 100),
                    };
                    if (!this.props.pageParams.finalTotalPrice) {
                        this.filtersForm.setFieldsValue({
                            finalTotalPrice: [roundMinFilterValue(filter[0] / 100), roundMaxFilterValue(filter[1] / 100)],
                        });
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }

        if (this.isComponentUnmount) return;

        let _filters = this.props.parentEntityType === 'projectTemplate' ? this.templateFilters : this.filters;
        PageUtils.setPageParamsOrLoadData(
            getStateFromPath2(this.props.location.search),
            _filters,
            () => {
                this.setSavedParams(this.props.location.search);
            },
            this.loadDataAndSetFilterValues
        );
    };

    componentDidUpdate = (prevProps: IProps) => {
        //console.log('componentDidUpdate', prevProps.pageParams, this.props.pageParams, this.updatingAfterSetFromStorage);
        let _filters = this.props.parentEntityType === 'projectTemplate' ? this.templateFilters : this.filters;
        PageUtils.update(
            prevProps.pageParams,
            this.props.pageParams,
            this.updatingAfterSetFromStorage,
            this.fromFilters,
            this.setSavedParams,
            this.loadDataAndSetFilterValues,
            _filters,
            () => {
                this.updatingAfterSetFromStorage = false;
            }
        );
    };

    componentWillUnmount = () => {
        //console.log('OperationsList componentWillUnmount()');
        this.props.reset();
        this.isComponentUnmount = true;
    };

    getSubrentParams = () => {
        let problem = this.props.pageParams.problem;
        if (!this.props.subrentModuleEnabled) {
            if (problem) {
                if (problem === ProblemEnum.SUBRENT_SHIPMENT_DELAY || problem === ProblemEnum.SUBRENT_RETURN_TO_SHIPPER_DELAY)
                    problem = undefined;
            }
        }
        return { problem };
    };

    setSavedParams = (search) => {
        let gridData = getGridStorageData(this.props.gridName);
        this.updatingAfterSetFromStorage = true;
        this.props.replace(
            getPathFromState(this.props.location.pathname, search, {
                ...gridData.filters,
                ...gridData.params,
            })
        );
    };

    loadDataAndSetFilterValues = () => {
        this.loadOperations();
        if (!this.fromFilters && this.filtersForm) {
            let values = { ...initialParamsState, ...this.props.pageParams };
            if (!values.finalTotalPrice) {
                if (this.availableFilters && this.availableFilters['operationIndicators.finalTotalPrice']) {
                    values.finalTotalPrice = [
                        roundMinFilterValue(this.availableFilters['operationIndicators.finalTotalPrice'][0] / 100),
                        roundMaxFilterValue(this.availableFilters['operationIndicators.finalTotalPrice'][1] / 100),
                    ];
                }
            }
            this.filtersForm.setFieldsValue({ ...values, ...this.getSubrentParams() });
        }
        this.fromFilters = false;
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    onItemAction = (item: OperationRecord, action: string) => {
        if (action === 'edit') {
            this.props.push(`/${this.props.businessAccountId}/history/operations/${item.id}`);
            this.props.push(`/${this.props.businessAccountId}/history/operations/${item.id}/edit?tab=description`);
        }
    };

    loadOperations = async () => {
        await this.props.loadEntities(this.props.intl, this.props.businessAccountId);

        let { pageParams, filteredCount } = this.props;
        logEvent({
            type: 'list operations',
            data: {
                'num filtered': filteredCount,
                'num per page': pageParams.limit,
                'num of the page': pageParams.page,
                'filter delay': pageParams.delay,
                'filter shortage': pageParams.shortage,
                'filter search': pageParams.search,
                'filter archive': !pageParams.hideAuto,
                'filter type code': pageParams.typeCode,
                'filter renter id': pageParams.renterId,
                'filter project id': pageParams.projectId,
                'filter creation author id': pageParams.authorId,
                'filter start date from': pageParams.startDate ? pageParams.startDate[0] : undefined,
                'filter start date to': pageParams.startDate ? pageParams.startDate[1] : undefined,
                'filter final total price from': pageParams.finalTotalPrice ? pageParams.finalTotalPrice[0] : undefined,
                'filter final total price to': pageParams.finalTotalPrice ? pageParams.finalTotalPrice[1] : undefined,
            },
        });
    };

    onPageSizeChanged = (size) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { limit: size, page: 1 }));
        this.clearSelection();
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.push(
            getPathFromState(this.props.location.pathname, this.props.location.search, { sortBy: id, sortOrder: desc ? 'DESC' : 'ASC' })
        );
        this.clearSelection();
    };

    onPageChanged = (page: number) => {
        this.props.push(getPathFromState(this.props.location.pathname, this.props.location.search, { page: page }));
        this.clearSelection();
    };

    onSelectionChanged = (data) => {
        this.setState({ selection: data });
    };

    getActionButtons = () => {
        return [];
    };

    onFiltersChange = (data) => {
        this.updateFilters(this.getProcessedParamsForUpdate(data));
    };

    getProcessedParamsForUpdate = (data) => {
        const processedData = { ...data };

        processedData.renterId =
            processedData.renterId && processedData.renterId.key
                ? processedData.renterId.key
                : processedData.renterId
                ? processedData.renterId
                : undefined;
        processedData.projectId =
            processedData.projectId && processedData.projectId.key
                ? processedData.projectId.key
                : processedData.projectId
                ? processedData.projectId
                : undefined;
        processedData.subrentId =
            processedData.subrentId && processedData.subrentId.key
                ? processedData.subrentId.key
                : processedData.subrentId
                ? processedData.subrentId
                : undefined;
        processedData.creationAuthorId =
            processedData.creationAuthorId && processedData.creationAuthorId.key
                ? processedData.creationAuthorId.key
                : processedData.creationAuthorId
                ? processedData.creationAuthorId
                : undefined;

        if (processedData.startDate) {
            const startDate = processedData.startDate.map((item) => (item ? moment(item).valueOf() : ''));
            if (!startDate[0] && !startDate[1]) processedData.startDate = undefined;
            else if (!startDate[1]) processedData.startDate = [startDate[0]];
            else processedData.startDate = startDate;
        }

        if (processedData.finalTotalPrice) {
            if (
                processedData.finalTotalPrice.toString() ===
                [
                    roundMinFilterValue(this.availableFilters['operationIndicators.finalTotalPrice'][0] / 100),
                    roundMaxFilterValue(this.availableFilters['operationIndicators.finalTotalPrice'][1] / 100),
                ].toString()
            ) {
                processedData.finalTotalPrice = undefined;
            }
        }

        if (processedData.hideAuto === false) processedData.hideAuto = undefined;

        processedData.page = 1;

        processedData.parentEntityType = undefined;

        return processedData;
    };

    updateFilters = (data) => {
        this.fromFilters = true;
        setGridStorageDataFilters(this.props.gridName, data);
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        this.clearSelection();
    };

    getFiltersForm = (ref) => {
        return (this.filtersForm = ref && ref.props && ref.props.form ? ref.props.form : null);
    };

    resetFilters = () => {
        let data = {
            search: undefined,
            typeCode: undefined,
            renterId: undefined,
            projectId: undefined,
            subrentId: undefined,
            hideAuto: undefined,
            delay: undefined,
            shortage: undefined,
            startDate: undefined,
            creationAuthorId: undefined,
            finalTotalPrice: undefined,
            problem: undefined,
            authorId: undefined,
            screenLeft: undefined,
            screenRight: undefined,
        };
        this.props.replace(getPathFromState(this.props.location.pathname, this.props.location.search, data));
        setGridStorageDataFilters(this.props.gridName, data);
    };

    fetchEntityErrorCallback = (id: number) => {
        const { filteredParams, deletedCount } = getFilteredParams(this.props.pageParams, id);

        const processedParams = this.getProcessedParamsForUpdate(filteredParams);

        if (deletedCount > 0) {
            setGridStorageDataFilters(this.props.gridName, processedParams);
            this.props.replace(getPathFromState(this.props.location.pathname, '', processedParams));
        }
    };

    onOffloadingDownload: OffloadingDocumentOnDownload = async ({ fileType, options }) => {
        let { canViewFinancialData, businessAccountId, intl, pageParams, subrentModuleEnabled } = this.props;
        const { sortOrder, sortBy, search } = pageParams;
        let filters: string[] = getOperationsListFilters(pageParams, false, subrentModuleEnabled);

        await downloadOffloading({
            businessAccountId,
            canViewFinancialData,
            exportTemplateType: ExcelExportTemplateTypeCodeEnum.OPERATIONTEMPLATE,
            fileName: 'Экспорт операций',
            fileType,
            filters,
            financialFieldsEnum: OperationFinancialFieldsTypeCode,
            grid: this.grid,
            intl,
            notFinancialFieldsEnum: OperationNotFinancialFieldsTypeCode,
            selection: this.state.selection,
            values: options,
            sortOrder,
            sortBy,
            search,
            excludeColumns: this.getExcludedColumns()
        });
    };

    getExcludedColumns = ():DeepKeys<OperationRecord>[] | undefined => {
        let { canViewFinancialData } = this.props;
        let excludeColumns: DeepKeys<OperationRecord>[] | undefined;

        if (this.props.projectId) {
            excludeColumns = ['counterpartyShortName', 'projectShortName', 'subrentShortName'];
        } else if (this.props.renterId) {
            excludeColumns = ['counterpartyShortName'];
        }

        if (this.props.parentEntityType === 'projectTemplate') {
            if (!excludeColumns) excludeColumns = [];
            excludeColumns.push(
                'projectShortName',
                'targetStateCode',
                'operationIndicators.activeElementsCount',
                'operationIndicators.activeInstancesCount',
                'subrentShortName'
            );
        }

        if (!canViewFinancialData) {
            if (!excludeColumns) excludeColumns = [];
            excludeColumns.push('operationIndicators.totalDiscount', 'operationIndicators.finalTotalPrice');
        }

        return excludeColumns;
    }

    render() {
        let { entities, operationsLoading, filteredCount, canViewFinancialData, subrentModuleEnabled } = this.props;
        let excludeColumns: DeepKeys<OperationRecord>[] | undefined;
        let excludeFields: (DeepKeys<OperationRecord> | 'renterId' | 'problem' | 'finalTotalPrice' | 'startDate')[] | undefined;
        let newFilters = filters;

        if (this.props.projectId) {
            //excludeColumns = ['counterpartyShortName', 'projectShortName', 'subrentShortName'];
            excludeFields = ['renterId', 'projectId', 'subrentId'];
        } else if (this.props.renterId) {
            //excludeColumns = ['counterpartyShortName'];
            excludeFields = ['renterId'];
        }

        if (this.props.parentEntityType === 'projectTemplate') {
            if (!excludeFields) excludeFields = [];
            excludeFields.push('projectId', 'subrentId', 'problem');

            // if (!excludeColumns) excludeColumns = [];
            // excludeColumns.push(
            //     'projectShortName',
            //     'targetStateCode',
            //     'operationIndicators.activeElementsCount',
            //     'operationIndicators.activeInstancesCount',
            //     'subrentShortName'
            // );

            newFilters = templateFilters;
        }

        if (!canViewFinancialData) {
            //if (!excludeColumns) excludeColumns = [];
            //excludeColumns.push('operationIndicators.totalDiscount', 'operationIndicators.finalTotalPrice');
            if (!excludeFields) excludeFields = [];
            excludeFields.push('finalTotalPrice');
        }

        if (!subrentModuleEnabled) {
            if (!excludeFields) excludeFields = [];
            excludeFields.push('subrentId');
        }

        if (!this.props.parentEntityType) {
            if (!excludeFields) excludeFields = [];
            excludeFields.push('startDate');
        }

        excludeColumns = this.getExcludedColumns();

        return (
            <Spin spinning={operationsLoading}>
                <Card bordered={false}>
                    <Grid
                        filtersData={newFilters}
                        filtersInitialValues={this.initialValues}
                        filtersCurrentValues={{ ...this.props.pageParams, ...this.getSubrentParams() }}
                        filtersDefaultValues={initialParamsState}
                        filtersGetFiltersFormRef={this.getFiltersForm}
                        filtersOnChange={this.onFiltersChange}
                        filtersResetFiltersCb={this.resetFilters}
                        filtersExcludeFields={excludeFields}
                        filtersCustomParams={this.props.renterId ? { renterId: this.props.renterId } : undefined}
                        excludeColumns={excludeColumns}
                        ref={(ref) => {
                            if (!this.grid) this.grid = ref;
                        }}
                        onRowAction={this.onItemAction}
                        onSortedChange={this.onSortedChange}
                        onPageChanged={this.onPageChanged}
                        onPageSizeChanged={this.onPageSizeChanged}
                        onSelectionChanged={this.onSelectionChanged}
                        filtered={filteredCount}
                        pageSize={this.props.pageParams.limit}
                        currentPage={this.props.pageParams.page}
                        columns={columns}
                        data={entities}
                        indexFieldName={'id'}
                        actionButtons={this.getActionButtons()}
                        entityType={'operation'}
                        defaultSorted={this.props.pageParams.sortBy}
                        defaultSortDesc={this.props.pageParams.sortOrder === 'DESC'}
                        hideArchive={this.props.pageParams.hideAuto}
                        gridName={this.props.gridName}
                        fetchEntityErrorCallback={this.fetchEntityErrorCallback}
                        exportBlock={<ExportOffloadingPopover storageKey={this.props.gridName + 'Offloading'} onDownload={this.onOffloadingDownload} />}
                    />
                </Card>
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        entities: storeState.operations.entities,
        operationsLoading: storeState.operations.loading,
        locationSearchParams: storeState.router.location.search,
        pageParams: storeState.operations.params,
        filteredCount: storeState.operations.filteredCount,
        businessAccountId: storeState.system.businessAccountId,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = { loadEntities, reset, push, replace };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationsList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));
