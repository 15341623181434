import { ColumnTypes, TableColumn } from '../../../../../components/grid/Table';
import { Icon } from 'antd';
import React from 'react';
import { InstanceRecord, NomenclatureEntityTypeCodeEnum } from '../../../../../server';
import { LocalizationEnum, localize } from '../../../../../localization';
import { GRID_ACTIONS_COLUMN_WIDTH } from '../../../../../config/constants';
import DashboardDate from '../../../../../components/dashboardDate/dashboardDate';
import { PricingSchemePricePopover } from '../../../settings/pricingSchemes/pricingSchemePricePopover';
import { tableCellMoneyRenderer } from '../../../../../components/grid/renderers/tableCellMoneyRenderer';
import { SystemIcon, SystemIconType } from '../../../../../components/v2/systemIcon/systemIcon';
import { GridColumnCreator } from '../../../../../components/grid/gridColumnCreator';
import { PricingSchemeMaps } from '../../../../../core/utils/pricingSchemeUtils';
import { NomenclatureRecordCustom } from '../api/products.api.types';

export const productsListColumns: TableColumn<NomenclatureRecordCustom>[] = [
    GridColumnCreator.createProblemSeverityColumn(),
    GridColumnCreator.createImageColumn({
        dataIndex: 'mainImage',
    }),
    GridColumnCreator.createShortNameColumn<NomenclatureRecordCustom>(
        'product',
        (row) => ({
            baId: row.businessAccountId,
            id: row.productId,
            name: row.productShortName,
        }),
        {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
            dataIndex: 'productShortName',
        }
    ),
    GridColumnCreator.createProductVariantColumn<NomenclatureRecordCustom>((row) => ({
        productId: row.productId,
        productName: row.productShortName,
        variantId: row.variantId,
        variantName: row.variantName,
    })),
    GridColumnCreator.createExternalCodeColumn<NomenclatureRecordCustom>(NomenclatureEntityTypeCodeEnum.PRODUCT, (row) => ({
        code: row.externalCode,
        nomenclatureId: row.productId,
        baId: row.businessAccountId,
    })),
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATUS),
        dataIndex: 'stateCode',
        type: ColumnTypes.Status,
        width: 120,
        resizable: false,
    },
    {
        title: 'Инвентарь',
        dataIndex: 'warehousePresenceCounters.stockInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: number) => {
            return <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__MAINTENANCE),
        dataIndex: 'warehousePresenceCounters.underMaintenanceInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 150,
        resizable: false,
        render: (value: number) => {
            return <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>;
        },
    },
    {
        title: 'Субаренда',
        dataIndex: 'warehousePresenceCounters.subrentedInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: number) => {
            return <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>;
        },
    },
    {
        title: 'Аренда',
        dataIndex: 'warehousePresenceCounters.rentedInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 120,
        resizable: false,
        render: (value: number) => {
            return <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>;
        },
    },
    {
        title: 'На складе',
        dataIndex: 'warehousePresenceCounters.warehouseInstanceCount',
        type: ColumnTypes.CustomRender,
        minWidth: 100,
        resizable: false,
        sortable: true,
        defaultHidden: false,
        render: (value: number) => {
            return <span style={{ color: value === 0 ? '#cccccc' : undefined }}>{value}</span>;
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__PRICE_PER_SHIFT),
        dataIndex: 'pricePerShift',
        type: ColumnTypes.CustomRender,
        className: 'money',
        minWidth: 140,
        resizable: false,
        render: (value: any, rowData: NomenclatureRecordCustom) => {
            let { pricingScheme } = rowData;
            return (
                <>
                    {pricingScheme ? (
                        <PricingSchemePricePopover
                            data={{
                                id: pricingScheme.id,
                                typeCode: pricingScheme.typeCode,
                                label: pricingScheme.name || '',
                                price: rowData.pricePerShift,
                                steps: pricingScheme.steps || [],
                            }}
                        >
                            <Icon
                                component={PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[pricingScheme.typeCode]}
                                className={'rr-grid-pricingScheme-icon'}
                            />
                        </PricingSchemePricePopover>
                    ) : null}
                    {tableCellMoneyRenderer(value)}
                </>
            );
        },
    },
    {
        title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__ACTIVITY),
        dataIndex: 'lastActivityDate',
        type: ColumnTypes.CustomRender,
        minWidth: 130,
        resizable: false,
        render: (value: Date, rowData: InstanceRecord) => {
            return value ? <DashboardDate value={value} days={7} /> : null;
        },
    },
    {
        title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
        type: ColumnTypes.Actions,
        minWidth: GRID_ACTIONS_COLUMN_WIDTH,
        width: GRID_ACTIONS_COLUMN_WIDTH,
        maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
        resizable: false,
        sortable: false,
        className: 'actions',
        headerClassName: 'actions',
    },
];
