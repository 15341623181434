import { useCallback, useContext, useMemo, useRef } from 'react';
import { Grid, GridProps } from '../../grid/Grid';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { EntityGridProps } from './types/grid';
import { useGridSignals } from '../../../core/hooks/useGrid';
import Spin from '../../spin/spin';
import { Card } from 'antd';
import { useErrorEffect } from '../../../core/hooks/useError';
import { typedMemo } from '../../../core/hooks/useMemo';
import { useFiltersChangeEffect } from './hooks/useFiltersChanges';
import isEqual from 'lodash/isEqual';
import { useSettings } from './hooks/useSettings';
import { useGridHandlers } from './hooks/useGridHandlers';
import { useFilters } from './hooks/useFilters';
import { useEntityGridContext } from './components/context/GridRefContext';
import { useSetEntitySelection } from './components/context/EntitiesSelectionContext';
import { DrawerUrlContext } from './components/context/DrawerUrlContext';
import { PageURLParamDescriptionObject } from '../../../core/utils/descriptions';
import { ListParams } from './types/params';
import { GridCategoriesTree } from '../../categories/GridCategoriesTree/GridCategoriesTree';
import { useGridColumns } from './hooks/useGridColumns';

export const EntityGridComponent = <
    PageParams extends ListParams,
    PageParamsDescription extends PageURLParamDescriptionObject<PageParams>,
    EntityRecord extends object,
    EntityInfoRead extends object
>(
    props: EntityGridProps<PageParamsDescription, EntityRecord, EntityInfoRead>
) => {
    const {
        static: { filtersData, columns },
        metaData: { entityType, gridName },
        categoriesProps,
        queryData,
        entityActions,
        entitiesLoading,
        customFieldsInsertIndex,
        ...gridOptionalProps
    } = props;

    /* Query data */
    const {
        data: { entitiesData, availableIntervalFiltersData, customFieldMarkers } = {},
        isFetching,
        isLoading,
        isSuccess,
        isError,
        error,
    } = queryData;

    /* Grid state */
    const filtersRef = useRef<any>(null);
    const getFiltersForm = useCallback(() => {
        return filtersRef?.current?.props?.form as WrappedFormUtils | null | undefined;
    }, []);
    const gridRef = useEntityGridContext();
    const [setEntityId] = useContext(DrawerUrlContext);
    const setEntitySelection = useSetEntitySelection();
    const isFormLoadedRef = useRef(false);

    /* Derived props */
    const gridSettings = useSettings<PageParams>();
    const gridFilters = useFilters<PageParams, EntityRecord>({
        filteredCount: entitiesData?.listAttributes.filteredCount,
        filtersData,
        availableIntervalFiltersData,
        filtersRef,
        getFiltersForm,
        gridName,
        isFormLoadedRef,
        customFieldMarkers,
        clearSelection: gridRef.current?.clearSelection,
    });
    const gridHandlers = useGridHandlers<PageParams, EntityInfoRead>({
        setEntityId,
        setEntitySelection,
    });
    const isGridDataLoaded = useGridSignals({
        isFetching,
        isLoading,
        isError,
        isSuccess,
        entitiesLoading,
    });

    const gridCategoriesProps: Partial<GridProps> = useMemo(
        () =>
            categoriesProps
                ? {
                      categoriesComponent: GridCategoriesTree,
                      categoriesFilter: categoriesProps.categoriesFilter,
                      categoriesType: categoriesProps.categoriesType,
                      categoriesFilterOffsetBottom: categoriesProps.categoriesFilterOffsetBottom,
                      categoriesSelectedKeys: categoriesProps.categoriesSelectedKeys,
                      categoriesOnSelect: categoriesProps.categoriesOnSelect,
                  }
                : {},
        [categoriesProps]
    );

    const gridColumns = useGridColumns({
        columns,
        customFieldMarkers,
        insertIndex: customFieldsInsertIndex ?? 6,
    });

    /* Effects */
    useErrorEffect(isError, error);
    useFiltersChangeEffect<PageParams>({
        getFiltersForm,
        filtersCurrentValues: gridFilters.filtersCurrentValues,
    });

    return (
        <Spin spinning={!isGridDataLoaded}>
            <Card bordered={false}>
                {!isLoading && (
                    <Grid
                        {...gridOptionalProps}
                        {...gridSettings}
                        {...gridFilters}
                        {...gridHandlers}
                        {...gridCategoriesProps}
                        indexFieldName={'id'}
                        data={entitiesData?.records}
                        entityType={entityType}
                        ref={gridRef}
                        gridName={gridName}
                        columns={gridColumns}
                        customFieldMarkers={customFieldMarkers}
                        onRowAction={entityActions?.onRowAction}
                    />
                )}
            </Card>
        </Spin>
    );
};

export const EntityGrid = typedMemo(EntityGridComponent, (prevProps, nextProps) => isEqual(prevProps, nextProps));
